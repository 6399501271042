import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { createTypesenseAdapter } from "../../typesenseAdapter";
import { Configure, Hits, InstantSearch, useInstantSearch } from "react-instantsearch";
import { Container, Typography } from '@mui/material';
import { Spinner } from 'react-bootstrap';
import { CommonService } from '../../Service/CommonService';
import Offcanvas from 'react-bootstrap/Offcanvas';
import '../Dynamic/UserList.scss';

interface ProductProps {
    handleViewClose: () => void;
    inventoryId: any;
}

const ViewProductDetail: React.FC<ProductProps> = ({ handleViewClose, inventoryId }) => {
    const [dynamicSearchClient, setDynamicSearchClient] = useState(null);
    const [globalFilter, setGlobalFilter] = useState<string>("");
    const collectionName = process.env.REACT_APP_TYPESENSE_COLLECTIONNAME;

    useEffect(() => {

        let filters = [`id:${inventoryId}`];
    
        // Generate dynamic filter string
        const filterValue = filters.join(",");
        setGlobalFilter(filterValue);
        // Create a new TypesenseInstantsearchAdapter instance with dynamic filter
        const dynamicAdapter = createTypesenseAdapter();
    
        // Set searchClient to state
        setDynamicSearchClient(dynamicAdapter.searchClient);
    }, []);

    if (!dynamicSearchClient) {
        return "";
    }

    return (
        <InstantSearch searchClient={dynamicSearchClient} indexName={collectionName}>
            <Configure filters={globalFilter} />
            <Hits style={{ cursor: 'pointer' }} hitComponent={(props) => (<HitItem {...props} handleViewClose={handleViewClose} />)}  
            classNames={{
                emptyRoot: 'viewdetail-loader' // Custom class applied when no hits are found
            }} />
        </InstantSearch>
    );
};

const HitItem: React.FC<{ hit: any, handleViewClose: () => void; }> = ({ hit, handleViewClose }) => {
    const { status } = useInstantSearch();
    const { years, months } =CommonService.calculateAge(hit?.fill_date_timestamp);

    return (
        <>
            <div className={`${CommonService.isUser() ? "view-detail-for-user" : "view-detail-for-admin"}`}>
                <div className='view-detail-left' onClick={handleViewClose}></div>
                <div className='view-detail-inner' >
                {status === 'loading' ? (
                    // 
                    <div>
                        <div className='header'>
                            <div className='header-inner d-flex justify-content-between align-items-start'>
                                <div>
                                    {/* <p className='main-heading'>{hit.product_name}</p>
                                    <p className='sub-heading'>By: {hit.producer}</p> */}
                                </div>
                                <button onClick={handleViewClose} className='close-btn'><CloseIcon /></button>
                            </div>
                        </div>
                        <div className='view-detail-body'>
                            <div className='row m-0'>
                                <div className='col-xl-6 ps-0 pe-0 pe-lg-2'>
                                    {/* <div className='left-inner h-100'>
                                        <div className='left-heading'>
                                            <p>About {hit.product_name} Sample Product</p>
                                        </div>
                                        <div className='left-content'>
                                            <p>Get ready to immerse yourself in the spirit and tradition of the Kentucky Derby with Woodford Reserve's Kentucky Derby® 150 Limited Edition Bourbon Whiskey! This exceptional bourbon is a celebration of the 150th running of "The Greatest Two Minutes in Sports." Crafted with meticulous care at the historic Woodford Reserve Distillery in Versailles, Kentucky, this special edition bottle honors the rich and prolific partnership between Woodford Reserve and the Kentucky Derby®, a legacy that's been the heart of the event since 1999. Each sip transports you to the heart of Kentucky, with tasting notes that dance across the palate: think rich dried fruits mingling with a whisper of mint, zesty citrus cutting through smooth cocoa, and the indulgent sweetness of caramel and toffee, all rounded off with a touch of baking spice and chocolate. It's a flavor profile as complex and captivating as the Derby itself, bottled at a perfect 90.4 proof for a finish that's as smooth as silk and as long-lasting as the memories of the race day. Whether you're a die-hard bourbon aficionado, a Kentucky Derby® enthusiast, or simply someone who appreciates the finer things in life, the Woodford Reserve Kentucky Derby® 150 Limited Edition Bourbon Whiskey is an absolute must-have.
                                                Grab your commemorative barrel today!
                                                There are not many things more American than bourbon, and although most of it is produced in Kentucky, it can be produced all over the USA.
                                            </p>
                                        </div>
                                        <div className='left-sub-heading mt-2'>
                                            <p>Grab your commemorative barrel today!</p>
                                        </div>
                                        <div className='left-sub-content'>
                                            <p>There are not many things more American than bourbon, and although most of it is produced in Kentucky, it can be produced all over the USA.</p>
                                        </div>
                                    </div> */}
                                </div>
                                <div className='col-xl-6 pe-0 ps-0 ps-lg-2'>
                                    {/* <div className='right-inner h-100'>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className='key'>VOLUME</td>
                                                    <td className='value'> {hit.volume.toFixed(2)} {hit.volume_unit}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>BARREL SIZE</td>
                                                    <td className='value'>{hit.barrel_size}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>MASH BILL</td>
                                                    <td className='value'>{hit.mash_bill}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>PROOF</td>
                                                    <td className='value'>{hit.proof} pf</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>PROOF GALLONS</td>
                                                    <td className='value'>{hit.proof_gallons} pf-gal</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>SPIRIT AGE</td>
                                                    <td className='value'>{hit.spirit_age_year} years {hit.spirit_age_month}{" "}
                                                        months</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>SPIRIT</td>
                                                    <td className='value'>{hit.ttb_type}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>STAGE</td>
                                                    <td className='value'>{hit.stage}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>FACILITY</td>
                                                    <td className='value'>{hit.facility}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>LOT ID</td>
                                                    <td className='value'>{hit.lot_no}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>BATCH NAME</td>
                                                    <td className='value'>{hit.batch_name}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>FILL DATE</td>
                                                    <td className='value'>{hit.fill_date}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>BARREL #</td>
                                                    <td className='value'>{hit.barrel_number}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>BARREL PROFILE</td>
                                                    <td className='value'>{hit.barrel_profile}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>TYPE</td>
                                                    <td className='value'>{hit.ttb_type}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>TASTING NOTE</td>
                                                    <td className='value'>{hit.tasting_note}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div> */}

                                    fetching product details ..............
                                </div>
                            </div>
                        </div>
                    </div> 
                ) : (
                    <div>
                        <div className='header'>
                            <div className='header-inner d-flex justify-content-between align-items-start'>
                                <div>
                                    <p className='main-heading'>{hit.product_name}</p>
                                    <p className='sub-heading'>By: {hit.owner_name}</p>
                                </div>
                                <button onClick={handleViewClose} className='close-btn'><CloseIcon /></button>
                            </div>
                        </div>
                        <div className='view-detail-body'>
                            <div className='row m-0'>
                                <div className='col-xl-6 ps-0 pe-0 pe-lg-2'>
                                    <div className='left-inner h-100'>
                                        <div className='left-heading'>
                                            <p>About {hit.product_name} Sample Product</p>
                                        </div>
                                        <div className='left-content'>
                                            <p>Get ready to immerse yourself in the spirit and tradition of the Kentucky Derby with Woodford Reserve's Kentucky Derby® 150 Limited Edition Bourbon Whiskey! This exceptional bourbon is a celebration of the 150th running of "The Greatest Two Minutes in Sports." Crafted with meticulous care at the historic Woodford Reserve Distillery in Versailles, Kentucky, this special edition bottle honors the rich and prolific partnership between Woodford Reserve and the Kentucky Derby®, a legacy that's been the heart of the event since 1999. Each sip transports you to the heart of Kentucky, with tasting notes that dance across the palate: think rich dried fruits mingling with a whisper of mint, zesty citrus cutting through smooth cocoa, and the indulgent sweetness of caramel and toffee, all rounded off with a touch of baking spice and chocolate. It's a flavor profile as complex and captivating as the Derby itself, bottled at a perfect 90.4 proof for a finish that's as smooth as silk and as long-lasting as the memories of the race day. Whether you're a die-hard bourbon aficionado, a Kentucky Derby® enthusiast, or simply someone who appreciates the finer things in life, the Woodford Reserve Kentucky Derby® 150 Limited Edition Bourbon Whiskey is an absolute must-have.
                                                Grab your commemorative barrel today!
                                                There are not many things more American than bourbon, and although most of it is produced in Kentucky, it can be produced all over the USA.
                                            </p>
                                        </div>
                                        <div className='left-sub-heading mt-2'>
                                            <p>Grab your commemorative barrel today!</p>
                                        </div>
                                        <div className='left-sub-content'>
                                            <p>There are not many things more American than bourbon, and although most of it is produced in Kentucky, it can be produced all over the USA.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-6 pe-0 ps-0 ps-lg-2'>
                                    <div className='right-inner'>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className='key'>VOLUME</td>
                                                    <td className='value'> {hit.volume.toFixed(2)} {hit.volume_unit}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>BARREL SIZE</td>
                                                    <td className='value'>{hit.barrel_size}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>MASH BILL</td>
                                                    <td className='value'>{hit.mash_bill}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>PROOF</td>
                                                    <td className='value'>{hit.proof} pf</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>PROOF GALLONS</td>
                                                    <td className='value'>{hit.proof_gallons} pf-gal</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>SPIRIT AGE</td>
                                                    <td className='value'>{years} years {months} months </td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>SPIRIT</td>
                                                    <td className='value'>{hit.ttb_type}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>STAGE</td>
                                                    <td className='value'>{hit.stage}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>FACILITY</td>
                                                    <td className='value'>{hit.facility}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>LOT ID</td>
                                                    <td className='value'>{hit.lot_no}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>BATCH NAME</td>
                                                    <td className='value'>{hit.batch_name}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>FILL DATE</td>
                                                    <td className='value'>{hit.fill_date}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>BARREL #</td>
                                                    <td className='value'>{hit.barrel_number}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>BARREL PROFILE</td>
                                                    <td className='value'>{hit.barrel_profile}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>TYPE</td>
                                                    <td className='value'>{hit.ttb_type}</td>
                                                </tr>
                                                <tr>
                                                    <td className='key'>TASTING NOTE</td>
                                                    <td className='value'>{hit.tasting_note}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                )}
                </div>
            </div>
        </>
    )
}

export default ViewProductDetail;