import React, { useEffect, useRef, useState } from "react";
import { Service } from "../../Service/ApiService";
import "../Dynamic/Dashboard.scss";
import CompanyIcn from "../../assets/images/icons/CompanyNew.svg";
import InventoryIcn from "../../assets/images/icons/InventoryNew.svg";
import UsersIcn from "../../assets/images/icons/UsersNew.svg";
import BarrelIcn from "../../assets/images/icons/Barrels.svg";
import ReservedBarrelIcn from "../../assets/images/icons/Reserved_BarrelsNew.svg";
import OrderReqIcn from "../../assets/images/icons/Order_Request_New.svg";
import OrderValueIcn from "../../assets/images/icons/Order_Value_New.svg";
import OfferReqIcn from "../../assets/images/icons/Offer_Request_New.svg";
import SampleReqIcn from "../../assets/images/icons/Sample_Request_New.svg";
import SellReqIcn from "../../assets/images/icons/Sell_Request_New.svg";
import InvChangeReqIcn from "../../assets/images/icons/Inventory_Change_Request.svg";
import HandPointerIcon from "../../assets/images/user_hand_pointer.svg";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Cookies from "universal-cookie";
import { Button } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router";
import CurrencyFormatter from "../Static/CurrencyFormatter";
import RecentRequest from "./RecentRequest";
import UnreadMessage from "./UnreadMessage";
import { CommonService } from "../../Service/CommonService";
import { useRoute } from "../Common/route";
import { Skeleton, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import AlterImage from "../../assets/images/alter_image.png";
import StatusDropdown from "../Common/StatusDropdown";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import { Link } from "react-router-dom";
import { OrderApiService } from "../../Service/OrderApiService";
import { DisplayGrid } from "./DisplayGrid";
import { InventoryApiService } from "../../Service/InventoryApiService";

import MailOutlineSharpIcon from "@mui/icons-material/MailOutlineSharp";
import Constant from "../../Constant";
import { SupportApiService } from "../../Service/SupportApiService";


const Dashboard: React.FC = () => {
  const [openRequestCount, setOpenRequestCount] = useState<any>({});
  const route = useRoute();
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;
  const userName = new Cookies().get("userName");
  const [reviewUserCount, setReviewCount] = useState(0);
  const [isTableFetched, setIsTableFetched] = useState<Boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [title, setTitle] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  let columns: GridColDef[] = [];
  const [activeTab, setActiveTab] = useState("");
  const displayGridRef = useRef<HTMLDivElement>(null);
  const { id } = useParams<{ id: string }>();
  const params = id === undefined ? CommonService.getCompanyId() : id;
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [requestType, setRequestType] = useState("");

  const [filter, setFilter] = useState<ServiceRequestSearchFilter>({
    name: null,
    status: null,
    createdDate: null,
    requestNumber: null,
    requestType: null,
    companyName: null
  });


  const [isOpenRequestLoading, setIsOpenRequestLoading] = useState(false);

  // Message Box 
  const [isMessageBoxVisible, setIsMessageBoxVisible] = useState(false);
  // const handleIconClick = () => {
  //   setIsMessageBoxVisible((prev) => !prev); // Toggle visibility
  // };
  const handleValueChange = (isVisible: boolean) => {
    setIsMessageBoxVisible(isVisible); // Update parent state with the value from the child
    console.log("Message box visibility:", isVisible);
  };
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const handleScrollToGrid = () => {
    if (displayGridRef.current) {
      displayGridRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const users = async (page: any, size: any) => {
    try {
      setIsLoading(true);
      const response: any = await Service.getAllApprovedUsers(
        CommonService.isUser()
          ? CommonService.getCompanyId()
          : currentPath.includes("/master/my-warehouse/user-list")
          ? id
          : null,
        page,
        size,{},null
      );

      setTableData(Object.values(response.data.content)); // Convert object to array
      setTotalCount(response.data.totalElements);
      setIsTableFetched(true);
      setTitle("Users");
      setActiveTab("user");
      setIsLoading(false);
      handleScrollToGrid();
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const fetchOpenRequestCount = async () => {
    setIsOpenRequestLoading(true);
    const response: any = await Service.fetchOpenRequestCount();
    console.log("this is your response of data " + response.data);
    setOpenRequestCount(response.data);

    const reviewUserCount: any = await Service.getSubmittedUsersCount(null);
    console.log("this is your review user count" + reviewUserCount.data);
    setReviewCount(reviewUserCount.data);
    setIsOpenRequestLoading(false);
  };

  const openSampleRequests = async (page: any, size: any) => {
    try {
      setIsLoading(true); // Set the loading flag to true before the API call
      const response = await OrderApiService.getActiveSampleRequests(
        page,
        size,
        true,
        {},
        null
      );

      setTableData(Object.values(response.data.content)); // Convert object to array

      //also set the total count
      setTotalCount(response.data.totalElements);

      setIsTableFetched(true);
      setTitle("Sample_requests");
      setActiveTab("sample");
      setIsLoading(false);
      handleScrollToGrid();
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const openSellRequests = async (page: any, size: any) => {

    const updatedFilter = { ...filter, requestType: "Sell Request" }; // Default filter always applied


    try {
      setIsLoading(true);
      const response: any = await SupportApiService.getAllServiceRequests(
        CommonService.isAdmin() ? id : CommonService.getCompanyId(),
        page,
        size,
        updatedFilter
      );

      setTableData(Object.values(response.data.content)); // Convert object to array
      setTotalCount(response.data.totalElements);
      setIsTableFetched(true);
      setTitle("Sell_requests");
      setActiveTab("sell");
      setIsLoading(false);
      handleScrollToGrid();
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const openOrderRequests = async (page: any, size: any, value: any) => {
    try {
      setIsLoading(true);
      const response: any = await OrderApiService.getOrders(
        page, size, false, true, value, {}, null);

      setTableData(Object.values(response.data.content)); // Convert object to array
      setTotalCount(response.data.totalElements);
      setIsTableFetched(true);

      switch (value) {
        case "order":
          setTitle("Order_requests");
          setActiveTab("order");
          break;
        
        case "pending-order":
            setTitle("Pending_orders");
            setActiveTab("pending-order");
            break;

        default:
          // Handle other cases if needed
          break;
      }
      setIsLoading(false);
      handleScrollToGrid();
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const companies = async (page: any, size: any) => {
    try {
      setIsLoading(true);
      const response: any = await Service.fetchCompaniesWithPagination(
        page,
        size,
        {},
        null
      );

      setTableData(Object.values(response.data.content)); // Convert object to array
      setTotalCount(response.data.totalElements);
      setIsTableFetched(true);
      setTitle("Companies");
      setActiveTab("companies");
      setIsLoading(false);
      handleScrollToGrid();
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

    const inventoryListing = async (page: any, size: any) => {
      try {
          setIsLoading(true);
          const response: any = await InventoryApiService.getActiveInventoryListings(null, page, size, {}, null);

          let data = [];

          if (Array.isArray(response.data.content)) {
              data = response.data.content;
          } else if (typeof response.data.content === "object") {
              data = Object.values(response.data.content).filter(item => typeof item === "object");
          }
          
          // Ensure each row has a unique ID
          const processedData = data.map((item, index) => ({
              ...item,
              id: item.id || `${item.title}-${index}`, // Use existing ID or create a unique one
          }));

          console.log("Processed Data After ID Assignment:", processedData);

          setTableData(processedData);
          setTotalCount(response.data.totalElements);
          setIsTableFetched(true);
          setTitle("inventory_listing");
          setActiveTab("inventory-listing");
          setIsLoading(false);
          handleScrollToGrid();
      } catch (error) {
          setIsLoading(false);
          console.error("Error fetching data:", error);
      }
  };
  
  const openOfferRequests = async (page, size) => {
    try {
      setIsLoading(true);
      const response = await OrderApiService.getActiveOfferRequests(
        page,
        size,
        true,
        {},
        null
      );

      setTableData(Object.values(response.data.content)); // Convert object to array
      setTotalCount(response.data.totalElements);
      setIsTableFetched(true);
      setTitle("Offer_requests");
      setActiveTab("offer");
      setIsLoading(false);
      handleScrollToGrid();
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const inventories = async (page: any, size: any, value: any) => {
    try {
      setIsLoading(true);
      const response: any = await InventoryApiService.getMyInventories(null,null,value, page, size, {}, null, 'barrels');
      switch (value) {
        case "inventory":
          setTitle("Barrels");
          setActiveTab("inventories");
          break;
      
        case "available-barrel":
            setTitle("Available_barrels");
            setActiveTab("available-barrel");
            break;
            
        case "reserved-barrel":
          setTitle("Reserved_barrels");
          setActiveTab("reserved-barrel");
          break;

        default:
          // Handle other cases if needed
          break;
      }
      setTableData(Object.values(response.data.content)); // Convert object to array
      setTotalCount(response.data.totalElements);
      setIsTableFetched(true);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const loadData = async (value: any, page: any, size: any) => {
    try {
      columns = [];
      if (value === "user") users(page, size);
      else if (
        value === "order" ||
        value === "pending-order"
      )
        openOrderRequests(page, size, value);
      else if (value === "sell") openSellRequests(page, size);
      else if (value === "sample") openSampleRequests(page, size);
      else if (value === "offer") openOfferRequests(page, size);
      else if (value === "company") companies(page, size);
      else if (value === "inventory-listing") inventoryListing(page, size);
      else if (
        value === "inventory" ||
        value === "available-barrel" || 
        value === "reserved-barrel"
      ) 
        inventories(page, size, value);
      // else if (value === "inventory_change") openInventoryChangeRequests();

      setRequestType(value);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleRoute = (routePath: string) => {

    
    if (CommonService.isAdmin()) {
        route(`/master/${routePath}`);
    } else {
        route(`/user/${routePath}`);
    }
  };

  const handleSellRequestRoute = (routePath: string) => {
    if (CommonService.isAdmin()) {
        history.push(`/master/${routePath}`, {
            data: "Sell Request"  // Your data here
        });
    } else {
        history.push(`/user/${routePath}`, {
            data: "Sell Request"  // Your data here
        });
    }
};


  

  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  useEffect(() => {
    fetchOpenRequestCount();
    localStorage.setItem("dashboardPrefrence", Constant.Profile.OWNER);
  }, []);

  const toTitleCase = (str: string) => {
    return str
      .replace(/_/g, " ") // Replace underscores with spaces
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };

  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };

  // Handle pagination changes
  const handlePageChange = (newPaginationModel: GridPaginationModel) => {
    setPaginationModel(newPaginationModel);
    loadData(requestType, newPaginationModel.page, newPaginationModel.pageSize);
  };

  switch (title) {
    case "Users":
      columns.push(
        {
          field: "user",
          headerName: "User",
          flex: 1,
          minWidth:190,
          valueGetter: (value: any, row: any) =>
            `${row.firstName || ""} ${row.lastName || ""}`,
          headerClassName: "table-header",
          renderCell: (params: any) => (
            <div className="d-flex gap-2 align-items-center">
              {/* <div className="user-img-wrapper">
                  <img src={AlterImage} alt="CompanyIcn" />
                </div> */}
              <span>
                <CellWithTooltip value={params.value} />
              </span>
            </div>
          ),
        },
        {
          field: "email",
          headerName: "Email",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          renderCell: (params) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "phoneNumber",
          headerName: "Phone",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          renderCell: (params) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "ecommCompanyName",
          headerName: "Company Name",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          renderCell: (params) => (
            <CellWithTooltip value={params?.row?.ecommCompanyName} />
          ),
        },
        {
          field: "location",
          headerName: "Location",
          flex: 1,
          minWidth:190,
          valueGetter: (value: any, row: any) => {
            const city = row?.city;
            const state = row?.state;
            const zipcode = row?.zipcode;
            const cityWithComma = city ? city + ", " : "";
            const stateWithSpace = state ? state + " " : "";
            return `${cityWithComma}${stateWithSpace}${zipcode || ""}`;
          },
          renderCell: (params) => <CellWithTooltip value={params.value} />,
          headerClassName: "table-header",
        }
      );
      break;

    case "Order_requests":
    case "Pending_orders":
      columns.push(
        {
          field: "requestNumber",
          headerName: "Request Number",
          flex: 1,
          minWidth:190,
          headerClassName: "table-header",
          renderCell: (params: any) => {
            const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";

            return (
              <a href="#">
                <Link
                  to={`${linkPrefix}/order-detail/${params.row.id}`}
                  className="link"
                >
                  <CellWithTooltip value={params.row.requestNumber} />
                </Link>
              </a>
            );
          },
        },
        {
          field: "createdDate",
          headerName: "Created At",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          valueGetter: (value: any, row: any) => {
            const dateTimeString = row.createdDate ? row.createdDate : null;

            if (dateTimeString) {
              //call the method which formate the time
              return CommonService.formatDateTime(dateTimeString);
            }
          },
        },
        {
          field: "user",
          headerName: "Created By",
          flex: 1,
          minWidth:190,
          valueGetter: (value: any, row: any) =>
            `${row.firstName || ""} ${row.lastName || ""}`,
          headerClassName: "table-header",
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "email",
          headerName: "Email",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          valueGetter: (value: any, row: any) => `${row.email || ""} `,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "quantity",
          headerName: "Total Quantity",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          valueGetter: (value: any, row: any) =>
            `${row.quantity || ""} barrels`,
        },
        CommonService.isAdmin() && {
          field: "finalAmount",
          headerName: "Total Amount",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          renderCell: (params: any) => (
            <CurrencyFormatter
              amount={params.value}
              locale="en-US"
              currency="USD"
            />
          ),
        },

        {
          field: "status",
          headerName: "Status",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          renderCell: (params: any) => (
            <div>
              <StatusDropdown
                currentStatus={toTitleCase(params.row.status)}
                onStatusChange={(newStatus) => {}}
                disableCondition={true}
                allowedLabels={[]}
              />
              {params.row.status === "REJECTED" && (
                <Tooltip
                  title={`Rejection Note: ${params.row.rejectionNote}`}
                  arrow
                >
                  <StickyNote2Icon
                    className="note-icon cursor-pointer"
                    style={{ color: "gray" }}
                  />
                </Tooltip>
              )}
            </div>
          ),
        }
      );
      break;

    case "Sell_requests":
      columns.push(
        {
          field: "requestNumber",
          headerName: "Request Number",
          flex: 1,
          minWidth:190,
          headerClassName: "table-header",
          renderCell: (params: any) => {
            const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";

            return (
              <a href="#">
                <Link
                  to={`${linkPrefix}/view-customer-service-request/${params.row.id}`}
                  className="link"
                >
                  <CellWithTooltip value={params.row.requestNumber} />
                </Link>
              </a>
            );
          },
        },
        {
          field: "name",
          headerName: "Submitted By",
          flex: 1,
          minWidth:190,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          headerClassName: "table-header",
        },
        // {
        //   field: "ecommUser.email",
        //   headerName: "Email",
        //   headerClassName: "table-header",
        //   flex: 1,
        //   minWidth:190,
        //   valueGetter: (value: any, row: any) =>
        //     `${row.ecommUser.email || ""} `,
        //   renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        // },
        {
          field: "companyName",
          headerName: "Company Name",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          
        },
        {
          field: "createdDate",
          headerName: "Submitted On",
          flex: 1,
          minWidth:190,
          valueGetter: (value: any, row: any) => {
            const dateTimeString = row.createdDate ? row.createdDate : null;
            if (dateTimeString) {
              return CommonService.formatDateTime(dateTimeString);
            }
          },
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          headerClassName: "table-header",
        },
        // {
        //   field: "fileName",
        //   headerName: "Inventory File",
        //   headerClassName: "table-header",
        //   flex: 1,
        //   minWidth:190,
        //   renderCell: (params) => {
        //     const csvFileName = params.value;
        //     if (csvFileName) {
        //       const parts = csvFileName.split("_");
        //       const fileNameToShow = parts.slice(1).join("_");
        //       const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";

        //       return <CellWithTooltip value={fileNameToShow} />;
        //     } else {
        //       return "";
        //     }
        //   },
        // },
        // {
        //   field: "sellRequestName",
        //   headerName: "Name of Sell Request",
        //   headerClassName: "table-header",
        //   flex: 1,
        //   minWidth:190,
        //   renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        //   valueGetter: (value: any, row: any) =>
        //     `${row.sellRequestName || "-"} `,
        // },
        {
          field: "status",
          headerName: "Status",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          renderCell: (params: any) => (
            <div>
              <StatusDropdown
                currentStatus={toTitleCase(params.row.status)}
                onStatusChange={(newStatus) => {}}
                disableCondition={true}
                allowedLabels={[]}
              />
              {(params.row.status === "REJECTED" || params.row.status === "CANCELLED") && (
                <Tooltip
                  title={`Reason: ${params.row.cancellationReason}`}
                  arrow
                >
                  <StickyNote2Icon
                    className="note-icon cursor-pointer"
                    style={{ color: "gray" }}
                  />
                </Tooltip>
              )}
            </div>
          ),
        }
      );

      break;

    case "Sample_requests":
      columns.push(
        {
          field: "requestNumber",
          headerName: "Request Number",
          flex: 1,
          minWidth:190,
          headerClassName: "table-header",
          renderCell: (params: any) => {
            const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";

            return (
              <a href="#">
                <Link
                  to={`${linkPrefix}/view-sample-request/${params.row.id}`}
                  className="link"
                >
                  <CellWithTooltip value={params.value} />
                </Link>
              </a>
            );
          },
        },
        {
          field: "requestedBy",
          headerName: "Requested By",
          flex: 1,
          minWidth:190,
          valueGetter: (value: any, row: any) =>
            `${row.firstName || ""} ${row.lastName || ""}`,
          headerClassName: "table-header",
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "requestedOn",
          headerName: "Requested On",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          valueGetter: (value: any, row: any) => {
            const dateTimeString = row.createdDate ? row.createdDate : null;

            if (dateTimeString) {
              //call the method which formate the time
              return CommonService.formatDateTime(dateTimeString);
            }
          },
        },
        {
          field: "companyName",
          headerName: "Company Name",
          flex: 1,
          minWidth:190,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "inventoryName",
          headerName: "Inventory",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          renderCell: (params: any) => {
            if (params.value) {
              const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";

              return <CellWithTooltip value={params.value} />;
            } else {
              return "";
            }
          },
        },
        {
          field: "size",
          headerName: "Sample Size",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "quantity",
          headerName: "Quantity",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "purpose",
          headerName: "Purpose",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "status",
          headerName: "Status",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          renderCell: (params: any) => (
            <div>
              <StatusDropdown
                currentStatus={toTitleCase(params.row.status)}
                onStatusChange={(newStatus) => {}}
                disableCondition={true}
                allowedLabels={[]}
              />
              {params.row.status === "REJECTED" && (
                <Tooltip
                  title={`Rejection Note: ${params.row.rejectionNote}`}
                  arrow
                >
                  <StickyNote2Icon
                    className="note-icon cursor-pointer"
                    style={{ color: "gray" }}
                  />
                </Tooltip>
              )}
            </div>
          ),
        }
      );
      break;

    case "Offer_requests":
      columns.push(
        {
          field: "requestNumber",
          headerName: "Request Number",
          flex: 1,
          minWidth:190,
          headerClassName: "table-header",
          renderCell: (params: any) => {
            const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";

            return (
              <a href="#">
                <Link
                  to={`${linkPrefix}/view-offer-request/${params.row.id}`}
                  className="link"
                >
                  <CellWithTooltip value={params.value} />
                </Link>
              </a>
            );
          },
        },
        {
          field: "createdDate",
          headerName: "Request Date",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          valueGetter: (value: any, row: any) => {
            const dateTimeString = row.createdDate ? row.createdDate : null;

            if (dateTimeString) {
              //call the method which formate the time
              return CommonService.formatDateTime(dateTimeString);
            }
          },
        },
        {
          field: "user",
          headerName: "User",
          flex: 1,
          minWidth:190,
          valueGetter: (value: any, row: any) =>
            `${row.firstName || ""} ${row.lastName || ""}`,
          headerClassName: "table-header",
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "email",
          headerName: "Email",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "inventoryName",
          headerName: "Inventory",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          //renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          renderCell: (params) => {
            if (params.value) {
              const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";

              return <CellWithTooltip value={params.value} />;
            } else {
              return "";
            }
          },
        },
        {
          field: "quantity",
          headerName: "Quantity",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "pricePerBarrel",
          headerName: "Offer Price",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          renderCell: (params: any) => (
            <CurrencyFormatter
              amount={params.value}
              locale="en-US"
              currency="USD"
            />
          ),
        },
        CommonService.isAdmin() && {
          field: "totalAmount",
          headerName: "Total Price",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          renderCell: (params: any) => (
            <CurrencyFormatter
              amount={params.value}
              locale="en-US"
              currency="USD"
            />
          ),
        },
        {
          field: "status",
          headerName: "Status",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          renderCell: (params: any) => (
            <div>
              <StatusDropdown
                currentStatus={toTitleCase(params.row.status)}
                onStatusChange={(newStatus) => {}}
                disableCondition={true}
                allowedLabels={[]}
              />
              {params.row.status === "REJECTED" && (
                <Tooltip
                  title={`Rejection Note: ${params.row.rejectionNote}`}
                  arrow
                >
                  <StickyNote2Icon
                    className="note-icon cursor-pointer"
                    style={{ color: "gray" }}
                  />
                </Tooltip>
              )}
            </div>
          ),
        }
      );
      break;

    case "Open_inventory_change_requests":
      columns.push(
        {
          field: "productName",
          headerName: "Inventory",
          flex: 1,
          minWidth:190,
          renderCell: (params) => {
            const productName = params.value;
            if (productName) {
              const rolePrefix = CommonService.isAdmin() ? "master" : "user";
              // const linkPrefix = currentPath === '/user/inventory-change-requests' || currentPath === '/master/inventory-change-requests'
              //  ? 'compare-inventory' : 'edit-inventory';

              return (
                <a href="#">
                  <Link
                    to={`/${rolePrefix}/compare-inventory/${params.row.id}`}
                    className="link"
                  >
                    {productName}
                  </Link>
                </a>
              );
            } else {
              return "";
            }
          },
          //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
          headerClassName: "table-header",
        },
        {
          field: "facility",
          headerName: "Facility",
          // flex: 1
          //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
          headerClassName: "table-header",
        },
        {
          field: "age",
          headerName: "Age",
          flex: 0.5,
          valueGetter: (value: any, row: any) =>
            `${row.spiritAgeYear || ""}years ${row.spiritAgeMonth || ""}months`,
          headerClassName: "table-header",
        },
        {
          field: "initialQuantity",
          headerName: "Initial Quantity",
          // // flex: 1
          //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
          headerClassName: "table-header",
        },
        {
          field: "quantity",
          headerName: "Quantity for Sales",
          // // flex: 1
          valueGetter: (value: any, row: any) =>
            row.isAvailableForSell ? row.quantity : 0,
          headerClassName: "table-header",
        },
        {
          field: "isNotAvailableForSell",
          headerName: "Quantity not for Sale",
          headerClassName: "table-header",
          cellClassName: "info-dropdown",
        },
        {
          field: "lotNo",
          headerName: "Lot ID",
          headerClassName: "table-header",
        },
        {
          field: "price",
          headerName: "Price",
          headerClassName: "table-header",
          renderCell: (params: any) => (
            <CurrencyFormatter
              amount={params.value}
              locale="en-US"
              currency="USD"
            />
          ),
        },
        {
          field: "platformFee",
          headerName: "Fees",
          headerClassName: "table-header",
          renderCell: (params: any) => (
            <CurrencyFormatter
              amount={params.value}
              locale="en-US"
              currency="USD"
            />
          ),
        },
        {
          field: "total",
          headerName: "Total",
          // flex: 1
          renderCell: (params: any) => (
            <CurrencyFormatter
              amount={params.row.price * params.row.platformFee}
              locale="en-US"
              currency="USD"
            />
          ),
        }
      );

      break;

    case "Companies":
      columns.push(
        {
          field: "companyName",
          headerName: "Company Name",
          flex: 1,
          minWidth:190,
          valueGetter: (value: any, row: any) => `${row.companyName ?? ""}`,
          headerClassName: "table-header",
          renderCell: (params: any) => (
            <CellWithTooltip value={params.row.companyName} />
          ),
        },
        {
          field: "contactPerson",
          headerName: "Contact Person",
          flex: 1,
          minWidth:190,
          valueGetter: (value: any, row: any) => `${row.contactPerson ?? ""}`,
          headerClassName: "table-header",
          renderCell: (params: any) => (
            <CellWithTooltip value={params.value || "-"} />
          ),
        },
        {
          field: "contactNumber",
          headerName: "Contact Number",
          flex: 1,
          minWidth:190,
          valueGetter: (value: any, row: any) => `${row.contactNumber ?? ""}`,
          headerClassName: "table-header",
          renderCell: (params: any) => (
            <CellWithTooltip value={params.value || "-"} />
          ),
        },
        {
          field: "email",
          headerName: "Email",
          flex: 1,
          minWidth:190,
          valueGetter: (value: any, row: any) => `${row.email ?? ""}`,
          headerClassName: "table-header",
          renderCell: (params: any) => (
            <CellWithTooltip value={params.value || "-"} />
          ),
        }
      );
      break;

    case "Barrels":
    case "Available_barrels":
    case "Reserved_barrels":
      columns.push(
        {
          field: "productName",
          headerName: "Inventory",
          flex: 1,
          minWidth:290,
          headerClassName: "table-header",
        },
        {
          field: "ownerName",
          headerName: "Company Name",
          flex: 1,
          minWidth:190,
          headerClassName: "table-header",
        },
        {
          field: "age",
          headerName: "Age",
          flex: 1,
          minWidth:190,
         valueGetter: (value: any, row: any) => {
            const currentDate = new Date();
            const fillDate = new Date(row.fillDate); // Use the fillDate field
        
            // Calculate the difference in years and months
            let years = currentDate.getFullYear() - fillDate.getFullYear();
            let months = currentDate.getMonth() - fillDate.getMonth();
        
            // Adjust for negative months (if current month is earlier than the fill month)
            if (months < 0) {
              years--;
              months += 12;
            }
        
            // Format the result as 'X years Y months'
            return `${years} years ${months} months`;
          },
        },
        {
          field: "quantity",
          headerName: "Quantity",
          flex: 1,
          minWidth:150,
          valueGetter: (value: any, row: any) =>
            row.isAvailableForSell ? row.quantity : 0,
          headerClassName: "table-header",
        },
        {
          field: "lotNo",
          headerName: "Lot ID",
          flex: 1,
          minWidth:150,
          headerClassName: "table-header",
        },
        {
          field: "price",
          headerName: "Price",
          flex: 1,
          minWidth:150,
          headerClassName: "table-header",
          renderCell: (params: any) => (
            <CurrencyFormatter
              amount={params.value}
              locale="en-US"
              currency="USD"
            />
          ),
        },
      );
      break;
    
    case "inventory_listing":
      columns.push(
        {
          field: "productName",
          headerName: "Inventory",
          flex: 1,
          minWidth:190,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "price",
          headerName: "Price/Barrel",
          flex: 1,
          minWidth: 190,
          valueGetter: (value: any, row: any) => row.price ? `$${parseFloat(row.price).toFixed(2)}` : "$0.00",
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          headerClassName: "table-header",
        },        
        {
          field: "fee",
          headerName: "Commission Fee/Barrel",
          flex: 1,
          minWidth:190,
          valueGetter: (value: any, row: any) => row.fee ? `$${parseFloat(row.fee).toFixed(2)}` : "$0.00",
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          headerClassName: "table-header",
        },
        {
          field: "availableBarrels",
          headerName: "Available Barrels",
          flex: 1,
          minWidth: 190,
          valueGetter: (value: any, row: any) => {
            const count = row.availableBarrels ?? 0;
            return `${count} ${count === 1 || count === 0 ? "Barrel" : "Barrels"}`;
          },
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          headerClassName: "table-header",
        },          
        {
          field: "reservedBarrels",
          headerName: "Reserved Barrels",
          flex: 1,
          minWidth:190,
          valueGetter: (value: any, row: any) => {
            const count = row.reservedBarrels ?? 0;
            return `${count} ${count === 1 || count === 0 ? "Barrel" : "Barrels"}`;
          },
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          headerClassName: "table-header",
        },
        {
          field: "soldBarrels",
          headerName: "Sold Barrels",
          flex: 1,
          minWidth:190,
          valueGetter: (value: any, row: any) => {
            const count = row.soldBarrels ?? 0;
            return `${count} ${count === 1 || count === 0 ? "Barrel" : "Barrels"}`;
          },
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          headerClassName: "table-header",
        },
        {
          field: "fillDate",
          headerName: "Age",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "facility",
          headerName: "Facility",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "lotNo",
          headerName: "Lot IDs",
          headerClassName: "table-header",
          flex: 1,
          minWidth:190,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
      );
      break;
  
    default:
      // Default case if no other cases match
      break;
  }

  return (
    <div className="dashboard">
      <div className="container-fluid pt-1 pb-3">
        <div className="dashboard__user">
          <h4 className="dashboard__user--title my-3 px-2">
            Welcome! {userName}
          </h4>
        </div>
        <div className="dashboard__inner custom-dashboard-inner">
          <div className="row">
            <div className="col-12">
              <div className="dashboard__data" style={{display: isOpenRequestLoading ? "unset"
                            : "grid", }}>
                {isOpenRequestLoading ? (
                  <>
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      height={230}
                      style={{width:"100%"}}
                    />
                  </>
                ) : (
                  <>
                    {CommonService.isAdmin() && (
                      <div
                        className={`dashboard__data--card cursor-pointer ${
                          activeTab === "companies" ? "active" : ""
                        }`}
                        onClick={() => loadData("company", 0, 10)}
                      >
                        <img
                          src={CompanyIcn}
                          alt="CompanyIcn"
                          width={65}
                          height={61}
                        />
                        <span
                          className="explore d-inline-flex align-items-center"
                          onClick={() => handleRoute("company-list")}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "14px",
                              fontWeight: 600,
                              marginRight: "-4px",
                              cursor: "pointer",
                            }}
                          >
                            More
                          </Typography>
                          <ChevronRightRoundedIcon />
                        </span>
                        <div className="d-flex flex-column">
                          <span className="count">
                            {openRequestCount["companys"]}
                          </span>
                          <span className="details">Companies</span>
                        </div>
                      </div>
                    )}

                    <div
                        className={`dashboard__data--card cursor-pointer ${
                          activeTab === "inventory-listing" ? "active" : ""
                        }`}
                        onClick={() => loadData("inventory-listing", 0, 10)}
                      >
                      <img
                        src={InventoryIcn}
                        alt="CompanyIcn"
                        width={68}
                        height={60}
                      />
                      <span className="explore d-inline-flex align-items-center"
                        onClick={() => handleRoute("inventory-listing")}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: 600,
                            marginRight: "-4px",
                            cursor: "pointer",
                          }}
                        >
                          More
                        </Typography>
                        <ChevronRightRoundedIcon />
                      </span>
                      <div className="d-flex flex-column">
                        <span className="count">
                          <CurrencyFormatter
                            amount={
                              openRequestCount["inventorieListingRequestCount"]
                                ? openRequestCount["inventorieListingRequestCount"]
                                : 0
                            }
                            locale="en-US"
                            currency="INR"
                          />
                        </span>
                        <span className="details">Total Inventory</span>
                      </div>
                    </div>

                    <div
                        className={`dashboard__data--card cursor-pointer ${
                          activeTab === "inventories" ? "active" : ""
                        }`}
                        onClick={() => loadData("inventory", 0, 10)}
                      >
                      <img
                        src={InventoryIcn}
                        alt="CompanyIcn"
                        width={68}
                        height={60}
                      />
                      <span className="explore d-inline-flex align-items-center"
                        onClick={() => handleRoute("inventory")}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: 600,
                            marginRight: "-4px",
                            cursor: "pointer",
                          }}
                        >
                          More
                        </Typography>
                        <ChevronRightRoundedIcon />
                      </span>
                      <div className="d-flex flex-column">
                        <span className="count">
                          <CurrencyFormatter
                            amount={
                              openRequestCount["inventories"]
                                ? openRequestCount["inventories"]
                                : 0
                            }
                            locale="en-US"
                            currency="INR"
                          />
                        </span>
                        <span className="details">Total Barrels</span>
                      </div>
                    </div>

                    <div
                      className={`dashboard__data--card cursor-pointer ${
                        activeTab === "user" ? "active" : ""
                      }`}
                      onClick={() => loadData("user", 0, 10)}
                    >
                      <img
                        src={UsersIcn}
                        alt="CompanyIcn"
                        width={67}
                        height={65}
                      />
                      <span
                        className="explore d-inline-flex align-items-center"
                        onClick={() => handleRoute("user-list")}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: 600,
                            marginRight: "-4px",
                            cursor: "pointer",
                          }}
                        >
                          More
                        </Typography>
                        <ChevronRightRoundedIcon />
                      </span>
                      <div className="d-flex flex-column">
                        <span className="count">
                          {openRequestCount["users"]}
                        </span>
                        <span className="details">Users</span>
                      </div>
                      <div className="d-flex justify-content-center">
                        <span className="hand-pointer-wrapper">
                          <img src={HandPointerIcon} alt="HandPointer" />
                          <span className="pointer-count">
                            {reviewUserCount}
                          </span>
                        </span>
                      </div>
                    </div>

                    {/* <div
                      className={`dashboard__data--card cursor-pointer ${
                        activeTab === "available-barrel" ? "active" : ""
                      }`}
                      onClick={() => loadData("available-barrel", 0, 5)}
                    >
                      <img
                        src={BarrelIcn}
                        alt="CompanyIcn"
                        width={51}
                        height={52}
                      />
                      <span className="explore d-inline-flex align-items-center"
                        onClick={() => handleRoute("available-barrel")}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: 600,
                            marginRight: "-4px",
                            cursor: "pointer",
                          }}
                        >
                          More
                        </Typography>
                        <ChevronRightRoundedIcon />
                      </span>
                      <div className="d-flex flex-column">
                        <span className="count">
                          {openRequestCount["availableBarrels"]
                            ? CommonService.numberFormatter(
                                openRequestCount["availableBarrels"]
                              )
                            : 0}
                        </span>
                        <span className="details">Total Available Barrels</span>
                      </div>
                    </div>

                    <div
                      className={`dashboard__data--card cursor-pointer ${
                        activeTab === "reserved-barrel" ? "active" : ""
                      }`}
                      onClick={() => loadData("reserved-barrel", 0, 5)}
                    >
                      <img
                        src={ReservedBarrelIcn}
                        alt="CompanyIcn"
                        width={51}
                        height={52}
                      />
                      <span className="explore d-inline-flex align-items-center"
                        onClick={() => handleRoute("reserved-barrel")}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: 600,
                            marginRight: "-4px",
                            cursor: "pointer",
                          }}
                        >
                          More
                        </Typography>
                        <ChevronRightRoundedIcon />
                      </span>
                      <div className="d-flex flex-column">
                        <span className="count">
                          {openRequestCount["reservedBarrels"]
                            ? openRequestCount["reservedBarrels"]
                            : 0}
                        </span>
                        <span className="details">Total Reserved Barrels</span>
                      </div>
                    </div> */}

                    <div
                      className={`dashboard__data--card cursor-pointer ${
                        activeTab === "order" ? "active" : ""
                      }`}
                      onClick={() => loadData("order", 0, 10)}
                    >
                      <img
                        src={OrderReqIcn}
                        alt="CompanyIcn"
                        width={70}
                        height={65}
                      />
                      <span
                        className="explore d-inline-flex align-items-center"
                        onClick={() => handleRoute("active-order")}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: 600,
                            marginRight: "-4px",
                            cursor: "pointer",
                          }}
                        >
                          More
                        </Typography>
                        <ChevronRightRoundedIcon />
                      </span>
                      <div className="d-flex flex-column">
                        <span className="count">
                          {openRequestCount["orderRequestCount"]}
                        </span>
                        <span className="details">Order Requests</span>
                      </div>
                    </div>

                    <div
                      className={`dashboard__data--card cursor-pointer ${
                        activeTab === "pending-order" ? "active" : ""
                      }`}
                      onClick={() => loadData("pending-order", 0, 10)}
                    >
                      <img
                        src={OrderValueIcn}
                        alt="CompanyIcn"
                        width={61}
                        height={60}
                      />
                      <span className="explore d-inline-flex align-items-center"
                        onClick={() => handleRoute("active-order")}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: 600,
                            marginRight: "-4px",
                            cursor: "pointer",
                          }}
                        >
                          More
                        </Typography>
                        <ChevronRightRoundedIcon />
                      </span>
                      <div className="d-flex flex-column">
                        <span className="count">
                          <CurrencyFormatter
                            amount={
                              openRequestCount["pendingOrderValue"]
                                ? openRequestCount["pendingOrderValue"]
                                : 0
                            }
                            locale="en-US"
                            currency="USD"
                          />
                        </span>
                        <span className="details">Pending Order Value</span>
                      </div>
                    </div>

                    <div
                      className={`dashboard__data--card cursor-pointer ${
                        activeTab === "offer" ? "active" : ""
                      }`}
                      onClick={() => loadData("offer", 0, 10)}
                    >
                      <img
                        src={OfferReqIcn}
                        alt="CompanyIcn"
                        width={61}
                        height={60}
                      />
                      <span
                        className="explore d-inline-flex align-items-center"
                        onClick={() => handleRoute("offer-request")}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: 600,
                            marginRight: "-4px",
                            cursor: "pointer",
                          }}
                        >
                          More
                        </Typography>
                        <ChevronRightRoundedIcon />
                      </span>
                      <div className="d-flex flex-column">
                        <span className="count">
                          {openRequestCount["offerRequestCount"]}
                        </span>
                        <span className="details">Offer Requests</span>
                      </div>
                    </div>

                    <div
                      className={`dashboard__data--card cursor-pointer ${
                        activeTab === "sample" ? "active" : ""
                      }`}
                      onClick={() => loadData("sample", 0, 10)}
                    >
                      <img
                        src={SampleReqIcn}
                        alt="CompanyIcn"
                        width={61}
                        height={60}
                      />
                      <span
                        className="explore d-inline-flex align-items-center"
                        onClick={() => handleRoute("sample-request")}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: 600,
                            marginRight: "-4px",
                            cursor: "pointer",
                          }}
                        >
                          More
                        </Typography>
                        <ChevronRightRoundedIcon />
                      </span>
                      <div className="d-flex flex-column">
                        <span className="count">
                          {openRequestCount["sampleRequestCount"]}
                        </span>
                        <span className="details">Sample Requests</span>
                      </div>
                    </div>

                    <div
                      className={`dashboard__data--card cursor-pointer ${
                        activeTab === "sell" ? "active" : ""
                      }`}
                      onClick={() => loadData("sell", 0, 10)}
                    >
                      <img
                        src={SellReqIcn}
                        alt="CompanyIcn"
                        width={65}
                        height={60}
                      />
                      <span
                        className="explore d-inline-flex align-items-center"
                        onClick={() => handleSellRequestRoute("customer-service")}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "14px",
                            fontWeight: 600,
                            marginRight: "-4px",
                            cursor: "pointer",
                          }}
                        >
                          More
                        </Typography>
                        <ChevronRightRoundedIcon />
                      </span>
                      <div className="d-flex flex-column">
                        <span className="count">
                          {openRequestCount["sellRequestCount"]}
                        </span>
                        <span className="details">Sell Requests</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* <div className="row m-0 p-0"> */}
            <div className="mt-3 px-3">
              <div className="dashboard--msg-table--container position-relative" style={{ backgroundColor: "#fff" }}>
                <div className="custom-table-outter" style={{ marginRight: isMessageBoxVisible ? "490px" : "48px" }}>
                  {(!isOpenRequestLoading) ? (
                    <>
                      {isTableFetched && (
                        <div
                          ref={displayGridRef}
                          className="dashboard__inner"
                        >
                          <DisplayGrid
                            tableData={tableData}
                            columns={columns}
                            localeText={localeText}
                            title={title}
                            totalRows={totalCount}
                            onPageChange={handlePageChange}
                            isLoading={isLoading}
                            paginationModel={paginationModel}
                          />
                        </div>
                      )}
                      {!isTableFetched && (
                        <div className="">
                          <RecentRequest />
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <Skeleton
                        animation="wave"
                        variant="rounded"
                        className="mt-3"
                        height={560}
                        style={{ width: "100%" }}
                      />
                    </>
                  )}
                </div>
                <div className="position-absolute top-0 end-0 unread-msg-wrapper" style={{ width: isMessageBoxVisible ? "490px" : "48px" }}>
                  {/* <div className="dashboard__messageBox--title align-items-center">
                    {isMessageBoxVisible && <h4 className="mb-0 lh-base">Unread Messages</h4>}
                    <div className="msg-count position-relative cursor-pointer ms-auto" onClick={handleIconClick}>
                      <MailOutlineSharpIcon sx={{ color: "#fff", height: 40, width: 40 }} />
                      <span className="count position-absolute top-0 start-100 translate-middle badge">
                        2
                      </span>
                    </div>
                  </div> */}
                  {/* <div className="msg-header d-flex justify-content-between">
                  <<MailOutlineSharpIcon sx={{ color: "#97AEB8" }} onClick={handleIconClick}/></div> */}
                  {/* hare i am sending the inventory count just for handling error */}
                  {/* {isMessageBoxVisible && (<div> */}
                    {/* {!isOpenRequestLoading ? ( */}
                      <UnreadMessage inventoryCount={1} onValueChange={handleValueChange}/>
                      {/* ) : ( */}
                      {/* <>
                        <Skeleton
                          animation="wave"
                          variant="rounded"
                          className="mt-3"
                          height={800}
                          style={{ width: "100%" }}
                        />
                      </> */}
                    {/* )} */}
                  {/* </div>)} */}
                </div>
              </div>
            </div>
                {/* </div> */}
            {/* <UnreadMessage inventoryCount={1} /> */}
          </div>
          {/* <RecentRequest /> */}
          {/* <div style={{ display: 'flex' }}>
                        <div style={{ float: 'left', width: '47%' }}><DisplayGrid tableData={tableData} columns={columns} localeText={localeText} title={title} /></div>
                        <div style={{ float: 'right', width: '47%', marginLeft: '2%' }}><RecentRequest /></div>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
