import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import MessageBox from "./MessageBox";
import { WebSocketProvider } from "../../Websocket/WebSocketProvider";
import { useParams } from "react-router";
import { MessageApiService } from "../../Service/MessageApiService";
import "./GroupMessageBox.scss";
import { Skeleton, Typography } from "@mui/material";
import ListGroup from "react-bootstrap/ListGroup";
import OfferPercentage from "../../assets/images/icons/OfferPercentage.svg";
import SampleDrop from "../../assets/images/icons/Sample.svg";
import ShoppingCartSimple from "../../assets/images/icons/ShoppingCartSimple.svg";
import SellRequestSimple from "../../assets/images/icons/SellRequestSimple.svg";
import SearchIcon from "@mui/icons-material/Search";
import CurrencyFormatter from "../Static/CurrencyFormatter";
import { CommonService } from "../../Service/CommonService";
import NoGroupMessage from "../Common/NoGroupMessage";
import NoChatMessage from "../Common/NoChatMessage";
import AdminMessageBox from "./AdminMessageBox";

const GroupMessageBox: React.FC<{}> = ({}) => {
  //requestId
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [groups, setGroups] = useState<any[]>([]);
  const [originalGroups, setOriginalGroups] = useState<any[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<any>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [search, setSearch] = useState("");
  const [isSeller, setIsSeller] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState("All");

  useEffect(() => {
    loadMessages("All");
  }, []);

  const loadMessages = async (tab: string) => {
    try {
      setIsLoading(true);
      setActiveTab(tab);

      const response = await MessageApiService.getGroupsMessage(tab);

      if (response.data.length > 0) {
        response.data[0].unreadMessageCount = 0;
      }

      setSearch("");
      setOriginalGroups(response.data);
      setGroups(response.data);
      setSelectedGroup(response.data?.length ? response.data[0] : null);
      setIsSeller(response.data[0].hasSeller);

      setIsLoading(false);
    } catch (error) {
      console.log("error fetching data" + error);
      setIsLoading(false);
    }
  };

  const toTitleCase = (str: string) => {
    return str
      .replace(/_/g, " ") // Replace underscores with spaces
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };

  const handleSearch = (searchTerm) => {
    if (!searchTerm) {
      setGroups(originalGroups);
      setSelectedGroup(originalGroups?.length ? originalGroups[0] : null);

      return;
    }

    const lowerCaseSearch = searchTerm.toLowerCase();

    // Filter the groups based on search term
    const filteredGroups = originalGroups.filter(
      (row) =>
        row.requestNumber?.toLowerCase().includes(lowerCaseSearch) ||
        row.sendBy?.toLowerCase().includes(lowerCaseSearch) ||
        row.companyName?.toLowerCase().includes(lowerCaseSearch) ||
        row.requestType
          ?.toLowerCase()
          .replace(/_/g, " ")
          .includes(lowerCaseSearch) ||
        row.createdDate?.toLowerCase().includes(lowerCaseSearch)
    );

    console.log(filteredGroups);

    // Update state with filtered data
    setGroups(filteredGroups);
    setSelectedGroup(filteredGroups.length ? filteredGroups[0] : null);
  };

  // Filter rows based on search query
  // const filteredRows = groups.filter(
  //   (row) =>
  //   row.requestNumber?.toLowerCase().includes(search?.toLowerCase()) ||
  //     row.sendBy?.toLowerCase().includes(search?.toLowerCase()) ||
  //     row.companyName?.toLowerCase().includes(search?.toLowerCase()) ||
  //     row.requestType
  //       ?.toLowerCase()
  //       .replace(/_/g, " ")
  //       .includes(search?.toLowerCase()) ||
  //     row.createdDate?.toLowerCase().includes(search?.toLowerCase())
  // );

  // Map filtered rows with unique IDs
  // const groupsData = filteredRows.map((rowData, index) => ({
  //   ...rowData,
  // }));

  const setGroupMessagesAsRead = (group: any) => {
    const updatedGroups = groups.map((g) =>
      g.requestId === group.requestId ? { ...g, unreadMessageCount: 0 } : g
    );

    // Update state with the new groups array
    setGroups(updatedGroups);

    // Set the selected group
    setSelectedGroup(group);

    setIsSeller(group.hasSeller);
  };

  return (
    <div className="group-chat-wrapper">
      {CommonService.isAdmin() && (
        <Typography
          variant="h1"
          gutterBottom
          sx={{
            px: 1,
            mb: 2,
            fontSize: "25px",
            fontWeight: 600,
            color: "#333333",
          }}
        >
          Messages
        </Typography>
      )}
      <div className="d-flex">
        <div className="group-chat-container row d-flex flex-grow-1 m-0">
          {isLoading ? (
            <>
              <Skeleton
                animation="wave"
                variant="rounded"
                style={{ height: "calc(100vh - 200px)" }}
                className="col-md-6 col-lg-5 col-xl-4 col-xxl-3 mb-3 mb-md-0"
              />
            </>
          ) : (
            <div className="group-list-wrapper col-md-6 col-lg-5 col-xl-4 col-xxl-4 mb-3 mb-md-0">
              {/* {groups.map((group) => (
              <div
                key={group.requestId}
                className={`group-item ${selectedGroup?.requestId === group.requestId ? 'selected' : ''}`}
                onClick={() => setSelectedGroup(group)}
              >
                {group.sendBy}
              </div>
            ))} */}
              {CommonService.isUser() && (
                <nav className="group-chat-nav-wrapper">
                  <div
                    className="nav nav-tabs justify-content-around mt-3"
                    id="nav-tab"
                    role="tablist"
                  >
                    <button
                      onClick={() => loadMessages("All")}
                      //className="nav-link active"
                      className={`nav-link ${
                        activeTab === "All" ? "active" : ""
                      }`}
                      id="nav-all-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-all"
                      type="button"
                      role="tab"
                      aria-controls="nav-all"
                      aria-selected="true"
                    >
                      All
                    </button>
                    <button
                      onClick={() => loadMessages("My Wearhouse")}
                      className={`nav-link ${
                        activeTab === "My Wearhouse" ? "active" : ""
                      }`}
                      //className="nav-link"
                      id="nav-warehouse-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-warehouse"
                      type="button"
                      role="tab"
                      aria-controls="nav-warehouse"
                      aria-selected="false"
                    >
                      As Owner
                    </button>
                    <button
                      onClick={() => loadMessages("My Requests")}
                      //className="nav-link"
                      className={`nav-link ${
                        activeTab === "My Requests" ? "active" : ""
                      }`}
                      id="nav-requests-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-requests"
                      type="button"
                      role="tab"
                      aria-controls="nav-requests"
                      aria-selected="false"
                    >
                      As Buyer
                    </button>
                  </div>
                </nav>
              )}

              <div className="position-relative mt-3">
                <Form.Control
                  type="text"
                  placeholder="Search"
                  className="chat-search mr-sm-2 pl"
                  onChange={(e) => handleSearch(e.target.value)}
                />
                <SearchIcon
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "15px",
                    transform: "translateY(-50%)",
                  }}
                />
              </div>
              <div className="text-muted">
                <hr />
              </div>

              <div className="tab-content" id="nav-tabContent">
                <>
                  <div
                    className="tab-pane fade show active"
                    id="nav-all"
                    role="tabpanel"
                    aria-labelledby="nav-all-tab"
                    tabIndex={0}
                  >
                    {/* {selectedGroup ? (  */}
                    <ListGroup>
                      {groups?.map((group, index) => (
                        <ListGroup.Item
                          className="cursor-pointer"
                          style={{
                            backgroundColor:
                              selectedGroup?.requestId === group.requestId
                                ? "white"
                                : "initial",
                          }}
                          onClick={() => {
                            setGroupMessagesAsRead(group);
                          }}
                        >
                          <div className="chat-info-wrapper py-2">
                            <div className="row">
                              <div className="col-auto pe-0">
                                <div className="avtar d-inline-flex justify-content-center align-items-center position-relative">
                                  {group?.sendBy?.charAt(0)}
                                  <span className="active-status online position-absolute top-0 start-0"></span>
                                </div>
                              </div>
                              <div className="col-auto pe-0">
                                <div className="chat__info d-flex d-sm-block flex-column">
                                  {CommonService.isUser() &&
                                    !CommonService.isSameCompany(
                                      group.companyId
                                    ) && (
                                      <span className="chat__info--name">
                                        {" "}
                                        #{group.requestNumber}
                                      </span>
                                    )}
                                  {(CommonService.isAdmin() ||
                                    CommonService.isSameCompany(
                                      group.companyId
                                    )) && (
                                    <>
                                      <span className="chat__info--name">
                                        {group.sendBy}
                                      </span>
                                    </>
                                  )}

                                  <div>
                                    <div className="d-flex align-items-center">
                                      {(CommonService.isAdmin() ||
                                        CommonService.isSameCompany(
                                          group.companyId
                                        )) && (
                                        <>
                                          <p className="chat__info--company me-2 m-0">
                                            {group.companyName}
                                          </p>
                                          <span className="d-none d-sm-inline">
                                            |
                                          </span>
                                        </>
                                      )}

                                      <span className="chat__info--reqType ms-sm-2">
                                        {group.requestType ==
                                          "OFFER_REQUEST" && (
                                          <img
                                            src={OfferPercentage}
                                            alt="OfferPercentage"
                                          />
                                        )}
                                        {group.requestType ==
                                          "SAMPLE_REQUEST" && (
                                          <img
                                            src={SampleDrop}
                                            alt="SampleDrop"
                                          />
                                        )}
                                        {group.requestType ==
                                          "ORDER_REQUEST" && (
                                          <img
                                            src={ShoppingCartSimple}
                                            alt="ShoppingCartSimple"
                                          />
                                        )}
                                        {group.requestType ==
                                          "SELL_REQUEST" && (
                                          <img
                                            src={SellRequestSimple}
                                            alt="SellRequestSimple"
                                          />
                                        )}
                                        {toTitleCase(group.requestType)}
                                      </span>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <p
                                        className="msg-preview mb-0 ps-0"
                                        style={{
                                          fontWeight:
                                            selectedGroup?.requestId ===
                                            group.requestId
                                              ? "500"
                                              : "700",
                                        }}
                                      >
                                        {" "}
                                        {
                                          JSON.parse(group.messages[0])[
                                            JSON.parse(group.messages[0])
                                              .length - 1
                                          ]
                                        }
                                      </p>
                                      {group.unreadMessageCount !== 0 && (
                                        <span className="msg-count d-inline-flex justify-content-center align-items-center rounded-circle">
                                          {group.unreadMessageCount}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-auto ms-auto">
                                {(CommonService.isAdmin() ||
                                  CommonService.isSameCompany(
                                    group.companyId
                                  )) && (
                                  <>
                                    <span className="chat__info--name">
                                      #{group.requestNumber}
                                    </span>
                                  </>
                                )}
                                <div
                                  className="time__info d-flex flex-column text-end lh-base"
                                  style={{
                                    fontWeight:
                                      selectedGroup?.requestId ===
                                      group.requestId
                                        ? "500"
                                        : "700",
                                  }}
                                >
                                  <span className="date">
                                    {CommonService.formatStrictDate(
                                      group.createdDate
                                    )}
                                  </span>
                                  <span className="time">
                                    {CommonService.formatStrictTime(
                                      group.createdDate
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                            {/* <div className="d-flex justify-content-between">
              <p className="msg-preview mb-0"
              style={{
                fontWeight:
                selectedGroup?.requestId === group.requestId
                  ? "500"
                  : "700",
          }}>
                {" "}
                {
                  JSON.parse(group.messages[0])[
                    JSON.parse(group.messages[0]).length - 1
                  ]
                }
                ...
              </p>
              {group.unreadMessageCount !== 0 && (
                <span className="msg-count d-inline-flex justify-content-center align-items-center rounded-circle">
                  {group.unreadMessageCount}
                </span>
              )}
            </div> */}
                          </div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>

                    {groups.length <= 0 && <NoGroupMessage />}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-warehouse"
                    role="tabpanel"
                    aria-labelledby="nav-warehouse-tab"
                    tabIndex={0}
                  >
                    {/* {selectedGroup ? ( */}
                    <ListGroup>
                      {groups.map((group, index) => (
                        <ListGroup.Item
                          className="cursor-pointer"
                          style={{
                            backgroundColor:
                              selectedGroup?.requestId === group.requestId
                                ? "white"
                                : "initial",
                          }}
                          onClick={() => {
                            setGroupMessagesAsRead(group);
                          }}
                        >
                          <div className="chat-info-wrapper py-2">
                            <div className="row">
                              <div className="col-auto pe-0">
                                <div className="avtar d-inline-flex justify-content-center align-items-center position-relative">
                                  {group?.sendBy?.charAt(0)}
                                  <span className="active-status online position-absolute top-0 start-0"></span>
                                </div>
                              </div>
                              <div className="col-auto pe-0">
                                <div className="chat__info d-flex d-sm-block flex-column">
                                  {CommonService.isUser() &&
                                    !CommonService.isSameCompany(
                                      group.companyId
                                    ) && (
                                      <span className="chat__info--name">
                                        {" "}
                                        #{group.requestNumber}
                                      </span>
                                    )}
                                  {(CommonService.isAdmin() ||
                                    CommonService.isSameCompany(
                                      group.companyId
                                    )) && (
                                    <>
                                      <span className="chat__info--name">
                                        {group.sendBy}
                                      </span>
                                    </>
                                  )}
                                  <div>
                                    <div className="d-flex align-items-center">
                                      {CommonService.isAdmin() && (
                                        <>
                                          <p className="chat__info--company me-2 mb-0">
                                            {group.companyName}
                                          </p>
                                          <span className="d-none d-sm-inline">
                                            |
                                          </span>
                                        </>
                                      )}
                                      <span className="chat__info--reqType ms-sm-2">
                                        {group.requestType ==
                                          "OFFER_REQUEST" && (
                                          <img
                                            src={OfferPercentage}
                                            alt="OfferPercentage"
                                          />
                                        )}
                                        {group.requestType ==
                                          "SAMPLE_REQUEST" && (
                                          <img
                                            src={SampleDrop}
                                            alt="SampleDrop"
                                          />
                                        )}
                                        {group.requestType ==
                                          "ORDER_REQUEST" && (
                                          <img
                                            src={ShoppingCartSimple}
                                            alt="ShoppingCartSimple"
                                          />
                                        )}
                                        {group.requestType ==
                                          "SELL_REQUEST" && (
                                          <img
                                            src={SellRequestSimple}
                                            alt="SellRequestSimple"
                                          />
                                        )}
                                        {toTitleCase(group.requestType)}
                                      </span>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <p className="msg-preview mb-0 ps-0">
                                        {" "}
                                        {
                                          JSON.parse(group.messages[0])[
                                            JSON.parse(group.messages[0])
                                              .length - 1
                                          ]
                                        }
                                      </p>
                                      {group.unreadMessageCount !== 0 && (
                                        <span className="msg-count d-inline-flex justify-content-center align-items-center rounded-circle">
                                          {group.unreadMessageCount}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-auto ms-auto">
                                {(CommonService.isAdmin() ||
                                  CommonService.isSameCompany(
                                    group.companyId
                                  )) && (
                                  <>
                                    <span className="chat__info--name">
                                      #{group.requestNumber}
                                    </span>
                                  </>
                                )}
                                <div
                                  className="time__info d-flex flex-column text-end lh-base"
                                  style={{
                                    fontWeight:
                                      selectedGroup?.requestId ===
                                      group.requestId
                                        ? "500"
                                        : "700",
                                  }}
                                >
                                  <span className="date">
                                    {CommonService.formatStrictDate(
                                      group.createdDate
                                    )}
                                  </span>
                                  <span className="time">
                                    {CommonService.formatStrictTime(
                                      group.createdDate
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                            {/* <div className="d-flex justify-content-between">
              <p className="msg-preview mb-0">
                {" "}
                {
                  JSON.parse(group.messages[0])[
                    JSON.parse(group.messages[0]).length - 1
                  ]
                }
                ...
              </p>
              {group.unreadMessageCount !== 0 && (
                <span className="msg-count d-inline-flex justify-content-center align-items-center rounded-circle">
                  {group.unreadMessageCount}
                </span>
              )}
            </div> */}
                          </div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                    {groups.length <= 0 && <NoGroupMessage />}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-requests"
                    role="tabpanel"
                    aria-labelledby="nav-requests-tab"
                    tabIndex={0}
                  >
                    {/* {selectedGroup ? (  */}
                    <ListGroup>
                      {groups.map((group, index) => (
                        <ListGroup.Item
                          className="cursor-pointer"
                          style={{
                            backgroundColor:
                              selectedGroup?.requestId === group.requestId
                                ? "white"
                                : "initial",
                          }}
                          onClick={() => {
                            setGroupMessagesAsRead(group);
                          }}
                        >
                          <div className="chat-info-wrapper py-2">
                            <div className="row">
                              <div className="col-auto pe-0">
                                <div className="avtar d-inline-flex justify-content-center align-items-center position-relative">
                                  {group?.sendBy?.charAt(0)}
                                  <span className="active-status online position-absolute top-0 start-0"></span>
                                </div>
                              </div>
                              <div className="col-auto pe-0">
                                <div className="chat__info d-flex d-sm-block flex-column">
                                  {CommonService.isUser() &&
                                    !CommonService.isSameCompany(
                                      group.companyId
                                    ) && (
                                      <span className="chat__info--name">
                                        {" "}
                                        #{group.requestNumber}
                                      </span>
                                    )}
                                  {(CommonService.isAdmin() ||
                                    CommonService.isSameCompany(
                                      group.companyId
                                    )) && (
                                    <>
                                      <span className="chat__info--name">
                                        {group.sendBy}
                                      </span>
                                    </>
                                  )}

                                  <div>
                                    <div className="d-flex align-items-center">
                                      {(CommonService.isAdmin() ||
                                        CommonService.isSameCompany(
                                          group.companyId
                                        )) && (
                                        <>
                                          <p className="chat__info--company me-2 mb-0">
                                            {group.companyName}
                                          </p>
                                          <span className="d-none d-sm-inline">
                                            |
                                          </span>
                                        </>
                                      )}
                                      <span className="chat__info--reqType ms-sm-2">
                                        {group.requestType ==
                                          "OFFER_REQUEST" && (
                                          <img
                                            src={OfferPercentage}
                                            alt="OfferPercentage"
                                          />
                                        )}
                                        {group.requestType ==
                                          "SAMPLE_REQUEST" && (
                                          <img
                                            src={SampleDrop}
                                            alt="SampleDrop"
                                          />
                                        )}
                                        {group.requestType ==
                                          "ORDER_REQUEST" && (
                                          <img
                                            src={ShoppingCartSimple}
                                            alt="ShoppingCartSimple"
                                          />
                                        )}
                                        {group.requestType ==
                                          "SELL_REQUEST" && (
                                          <img
                                            src={SellRequestSimple}
                                            alt="SellRequestSimple"
                                          />
                                        )}
                                        {toTitleCase(group.requestType)}
                                      </span>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <p className="msg-preview mb-0 ps-0">
                                        {" "}
                                        {
                                          JSON.parse(group.messages[0])[
                                            JSON.parse(group.messages[0])
                                              .length - 1
                                          ]
                                        }
                                      </p>
                                      {group.unreadMessageCount !== 0 && (
                                        <span className="msg-count d-inline-flex justify-content-center align-items-center rounded-circle">
                                          {group.unreadMessageCount}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-auto ms-auto">
                                {(CommonService.isAdmin() ||
                                  CommonService.isSameCompany(
                                    group.companyId
                                  )) && (
                                  <>
                                    <span className="chat__info--name">
                                      #{group.requestNumber}
                                    </span>
                                  </>
                                )}
                                <div
                                  className="time__info d-flex flex-column text-end lh-base"
                                  style={{
                                    fontWeight:
                                      selectedGroup?.requestId ===
                                      group.requestId
                                        ? "500"
                                        : "700",
                                  }}
                                >
                                  <span className="date">
                                    {CommonService.formatStrictDate(
                                      group.createdDate
                                    )}
                                  </span>
                                  <span className="time">
                                    {CommonService.formatStrictTime(
                                      group.createdDate
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                            {/* <div className="d-flex justify-content-between">
              <p className="msg-preview mb-0">
                {" "}
                {
                  JSON.parse(group.messages[0])[
                    JSON.parse(group.messages[0]).length - 1
                  ]
                }
                ...
              </p>
              {group.unreadMessageCount !== 0 && (
                <span className="msg-count d-inline-flex justify-content-center align-items-center rounded-circle">
                  {group.unreadMessageCount}
                </span>
              )}
            </div> */}
                          </div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                    {groups.length <= 0 && <NoGroupMessage />}
                  </div>
                </>
              </div>
            </div>
          )}

          <div className="message-list-wrapper col-md-6 col-lg-7 col-xl-8 col-xxl-8 position-relative pe-0">
            {selectedGroup ? (
              <WebSocketProvider
                requestId={selectedGroup.requestId}
                sellerCompanyId={
                  isSeller == true ? CommonService.getCompanyId() : null
                }
              >
                {/* <Container> */}

                <div className="message-list-card h-100">
                  {CommonService.isAdmin() &&
                  selectedGroup.requestType != "SELL_REQUEST" &&
                  selectedGroup.requestType != "CUSTOMER_SERVICE_REQUEST" ? (
                    <AdminMessageBox
                      selectedGroup={selectedGroup}
                      requestId={selectedGroup.requestId}
                      requestType={selectedGroup.requestType}
                      unreadGroupsCount={0}
                      setUnreadGroupsCount={() => 0}
                      isSeller={isSeller}
                    />
                  ) : (
                    <MessageBox
                      selectedGroup={selectedGroup}
                      groupRequestId={selectedGroup.requestId}
                      user1="User1"
                      user2={
                        isSeller == true
                          ? CommonService.getCompanyId()
                          : "User2"
                      }
                      searchQuery={searchQuery}
                      requestType={selectedGroup.requestType}
                      isSeller={isSeller}
                    />
                  )}
                </div>
                {/* </Container> */}
              </WebSocketProvider>
            ) : (
              <NoChatMessage />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupMessageBox;
