import React, { useEffect, useState } from "react";
import { Service } from "../../Service/ApiService";
import { Button } from "react-bootstrap";
import { useLocation, useParams } from "react-router";
import StatusDropdown from "../Common/StatusDropdown";
import { CommonService } from "../../Service/CommonService";
import ProfileIcon from "../../assets/images/profile/1.png";
import AlterImage from "../../assets/images/alter_image.png";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box, Skeleton, Tooltip } from "@mui/material";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";

const RecentRequest: React.FC = () => {
  const [recentRequests, setRecentRequests] = useState<any[]>([]);
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const isDashboard = location.pathname === "/master/dashboard";
  const [isLoading, setIsLoading] = useState(false);
  const [isSeller, setIsSeller] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState(0);
  const [search, setSearch] = useState("");

  // Simplified pagination state
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const fetchRecentRequestCount = async (page: number, size: number) => {
    try {
      setIsLoading(true);
      setRecentRequests([]);
      const params = CommonService.isUser()
        ? { id: CommonService.getCompanyId() }
        : { id };

      // Important: Adjust the page number for backend (if it uses 0-based indexing)
      const response: any = await Service.fetchRecentRequestCount(
        params,
        page,
        size,
        CommonService.getDashboardPrefrence()
      );
      setRecentRequests(response.data.content);
      setTotalCount(response.data.totalElements);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    // Fetch initial data when component mounts or route changes
    fetchRecentRequestCount(paginationModel.page, paginationModel.pageSize);
  }, [location.pathname]);

  function formatStatus(status: string): string {
    const formattedStatus = status
      .split(/[_\\]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    return formattedStatus;
  }

  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };

  // Filter rows based on search query
  const filteredRows = recentRequests.filter(
    (row) =>
      row.name?.toLowerCase().includes(search?.toLowerCase()) ||
      row.email?.toLowerCase().includes(search?.toLowerCase()) ||
      row.city?.toLowerCase().includes(search?.toLowerCase())
  );

  const tableDataWithIds = filteredRows.map((rowData, index) => ({
    ...rowData,
    id: index + 1, // Assigning a unique ID based on the index
  }));

  const columns: GridColDef[] = [
    {
      field: "requestType",
      headerName: "Request Type",
      headerClassName: "table-header",
      flex: 1,
      minWidth:210,
      renderCell: (params: any) => (
        <CellWithTooltip value={params.row.requestType} />
      ),
    },
    {
      field: "requestNumber",
      headerName: "Request Number",
      headerClassName: "table-header",
      flex: 1,
      minWidth:210,
      renderCell: (params: any) => (
        <CellWithTooltip value={params.row.requestNumber || "-"} />
      ),
    },
    isDashboard && {
      field: "companyName",
      headerName: "Company Name",
      flex: 1,
      minWidth:210,
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "createdBy",
      headerName: "User",
      flex: 1,
      minWidth:210,
      headerClassName: "table-header",
      renderCell: (params: any) => (
        <div className="d-flex gap-2 align-items-center">
          <div className="user-img-wrapper">
            <img src={AlterImage} alt="CompanyIcn" />
          </div>
          <span>
            <CellWithTooltip value={params.value} />
          </span>
        </div>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "table-header",
      flex: 1,
      minWidth:210,
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
    },
    isDashboard && {
      field: "phoneNumber",
      headerName: "Phone",
      headerClassName: "table-header",
      flex: 1,
      minWidth:210,
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "modifiedDate",
      headerName: "Created At",
      headerClassName: "table-header",
      flex: 1,
      minWidth:190,
      valueGetter: (value: any, row: any) => {
        const dateTimeString = row.modifiedDate ? row.modifiedDate : null;
        if (dateTimeString) {
          return CommonService.isUser() &&
            !CommonService.isSameCompany(row.companyId)
            ? CommonService.formatStrictDate(dateTimeString)
            : CommonService.formatDateTime(dateTimeString);
        }
      },
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "table-header",
      flex: 1,
      minWidth:230,
      renderCell: (params: any) => (
        <div
          className="cell-with-dropdown mb-2"
          onClick={(e) => e.stopPropagation()}
        >
          <StatusDropdown
            currentStatus={formatStatus(params.value)}
            onStatusChange={(newStatus) => {}}
            disableCondition={true}
            allowedLabels={[]}
          />
          {(params.row.status === "REJECTED" || params.row.status === "CANCELLED") && params.row.rejectionNote && (
            <Tooltip
              title={`Rejection Note: ${params.row.rejectionNote}`}
              arrow
            >
              <StickyNote2Icon
                className="note-icon cursor-pointer"
                style={{ color: "gray" }}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ].filter(Boolean) as GridColDef[];

  return (
    <div className="custom-table-outter">
      <div className="dashboard__inner mx-0 gap-0">
        <div className="d-flex justify-content-between mb-3 ps-0">
          <h5 className="mb-0">
            <b>Recent Requests</b>
          </h5>
        </div>
        <div className="table-responsive ps-0">
            <Box sx={{ height: 350, width: "100%" }}>
              <DataGrid
                localeText={localeText}
                rows={tableDataWithIds}
                columns={columns}
                className="data-grid"
                paginationModel={paginationModel}
                onPaginationModelChange={(newPaginationModel) => {
                  setPaginationModel(newPaginationModel);
                  fetchRecentRequestCount(
                    newPaginationModel.page,
                    newPaginationModel.pageSize
                  );
                }}
                pagination
                paginationMode="server"
                rowCount={totalCount}
                disableRowSelectionOnClick={true}
                getRowId={(row) => row.requestId}
                loading={isLoading}
                pageSizeOptions={[10, 20, 50, 100]}
                disableColumnResize
              />
            </Box>
        </div>
      </div>
    </div>
  );
};

export default RecentRequest;
