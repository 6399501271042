import React from 'react'

function SameInventoryOrders() {
  return (
   <>
   <div className='same-order-table-wrapper table-responsive'>
        <table>
            <thead>
                <tr>
                    <th>Request Number</th>
                    <th>Request Type</th>
                    <th>Company Name</th>
                    <th>Requested By</th>
                    <th>Requested On</th>
                    <th>Total Quantity</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Request Number</td>
                    <td>Request Type</td>
                    <td>Company Name</td>
                    <td>Requested By</td>
                    <td>Requested On</td>
                    <td>Total Quantity</td>
                </tr>
            </tbody>
        </table>
   </div>
   </>
  )
}

export default SameInventoryOrders