import { Url } from "../Constants/Url"
import AxiosApiService from "./AxiosService"

export const SupportApiService = {
    getAllServiceRequests, getAllServiceType, fetchCustomerServiceRequestHistory, fetchCustomerServiceRequestDocs,downloadCustomerServiceRequestDOC, getCustomerServiceRequest,deleteCustomerServiceRequest, updateCustomerServiceRequestStatus,uploadCustomerServiceRequestDocs,getCustomerServiceRequestForEditing,deleteCustomerServiceRequestDoc, fetchCustomerServiceRequestDocsForEditing, fetchUnreadMessageCount,downloadCustomerServiceRequestValueDOC, getAllStatusByServiceTypeId, updateSellRequestFeeDetails
}
// async function getAllServiceRequests(companyId: any, page: any, size: any, filter: any, signal: AbortSignal) {
//     try {
//       const response = await AxiosApiService.getWithParams<any>(Url.GET_CUSTOMER_SUPPORT_REQUESTS, {
//         params: {
//           companyId: companyId,
//           page: page,
//           size: size,
//           name: filter.name,
//           status: filter.status,
//           createdDate: filter.createdDate,
//           requestNumber: filter.requestNumber,
//           requestType: filter.requestType,
//           companyName: filter.companyName
//         },
//         signal: signal // Pass the signal to the Axios request
//       });
  
//       return response;
//     } catch (error) {
//       if (error.name === 'AbortError') {
//         console.log('Request was cancelled');
//       } else {
//         console.error('Error fetching service requests:', error);
//         throw error; // Re-throw the error to handle it in the calling function
//       }
//     }
//   }

async function getAllServiceRequests(companyId: any, page: any, size: any, filter?: any, signal?: AbortSignal) {
  try {
    const params: Record<string, any> = {
      companyId,
      page,
      size,
    };

    // Add filter parameters only if defined
    if (filter) {
      params.name = filter.name;
      params.status = filter.status;
      params.createdDate = filter.createdDate;
      params.requestNumber = filter.requestNumber;
      params.requestType = filter.requestType;
      params.companyName = filter.companyName;
    }

    const response = await AxiosApiService.getWithParams<any>(Url.GET_CUSTOMER_SUPPORT_REQUESTS, {
      params,
      ...(signal ? { signal } : {}), // Include signal only if provided
    });

    return response;
  } catch (error: any) {
    if (error.name === 'AbortError') {
      console.log('Request was cancelled');
    } else {
      console.error('Error fetching service requests:', error);
      throw error;
    }
  }
}

  

async function getAllServiceType() {
    return await AxiosApiService.get<any>(Url.GET_ALL_SERVICE_TYPE);
}

async function fetchCustomerServiceRequestHistory(customerServiceRequestId:any) {
    return await AxiosApiService.get<any>(Url.FETCH_CUSTOMER_SERVICE_REQUEST_HISTORY + '/' + customerServiceRequestId);
}

async function fetchCustomerServiceRequestDocs(customerServiceRequestId:any) {
    return await AxiosApiService.get<any>(Url.FETCH_CUSTOMER_SERVICE_REQUEST_DOCS + '/' + customerServiceRequestId);
}

async function fetchCustomerServiceRequestDocsForEditing(customerServiceRequestId:any) {
  return await AxiosApiService.get<any>(Url.FETCH_CUSTOMER_SERVICE_REQUEST_DOCS_FOR_EDITING + '/' + customerServiceRequestId);
}

async function downloadCustomerServiceRequestDOC(downloadDoc: any) {
    return await AxiosApiService.postBlob<Object>(Url.DOWNLOAD_CUSTOMER_SERVICE_REQUEST_DOC, downloadDoc);
}

async function getCustomerServiceRequest(customerServiceRequestId: any) {
    return await AxiosApiService.get<any>(Url.GET_CUSTOMER_SERVICE_REQUEST + '/' + customerServiceRequestId);
}

async function deleteCustomerServiceRequest(customerServiceRequestId: any) {
    return await AxiosApiService.get(Url.DELETE_CUSTOMER_SUPPORT_REQUEST + '/' + customerServiceRequestId);
}

async function deleteCustomerServiceRequestDoc(id: any) {
  return await AxiosApiService.get(Url.DELETE_CUSTOMER_SUPPORT_REQUEST_DOC + '/' + id);
}

async function updateCustomerServiceRequestStatus(request: any) {
    return await AxiosApiService.post(Url.UPDATE_CUSTOMER_SUPPORT_REQUEST,request);
}

async function getCustomerServiceRequestForEditing(customerServiceRequestId: any) {
  return await AxiosApiService.get<any>(Url.GET_CUSTOMER_SERVICE_REQUEST_FOR_EDITING + '/' + customerServiceRequestId);
}

async function uploadCustomerServiceRequestDocs(fileUpload: any) {
  return await AxiosApiService.post<any>(Url.UPLOAD_CUSTOMER_SERVICE_REQUEST_DOC, fileUpload)
}

async function fetchUnreadMessageCount (customerServiceRequestId: any) {
  return await AxiosApiService.get<any> (Url.FETCH_UNREAD_MESSAGE_COUNT_FOR_CUSTOMER_SERVICE_REQUEST + '/' + customerServiceRequestId);
}

async function downloadCustomerServiceRequestValueDOC(downloadDoc: any) {
  return await AxiosApiService.postBlob<Object>(Url.DOWNLOAD_CUSTOMER_SERVICE_REQUEST_VALUE_DOC, downloadDoc);
}

async function getAllStatusByServiceTypeId(serviceTypeId: any, currentStatus:any) {
  return await AxiosApiService.getWithParams<any>(Url.GET_ALL_STATUS_BY_SERVICE_TYPE_ID + "/" + serviceTypeId, {params: {
    currentStatus: currentStatus
  }});
}

async function updateSellRequestFeeDetails(updateFeeDetails:any) {
  return await AxiosApiService.post<any>(Url.UPDATE_SELL_REQUEST_FEE_DETAILS, updateFeeDetails);
}