import React from 'react';
import DOMPurify from 'dompurify';

interface CKContentProps {
  content?: string | null;
  className?: string;
}

const CKContent: React.FC<CKContentProps> = ({ 
  content,
  className = ''
}) => {
  if (!content) return null;

  return (
    <div 
      className={`ck-content ${className}`.trim()}
      dangerouslySetInnerHTML={{ 
        __html: DOMPurify.sanitize(content) 
      }} 
    />
  );
};

export default CKContent;