import { IconButton, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import { InventoryApiService } from "../../Service/InventoryApiService";
import SearchIcon from "@mui/icons-material/Search";
import { CommonService } from "../../Service/CommonService";
import { useHistory, useParams } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { format } from "date-fns";

const ViewInventoryListingDetails: React.FC = () => {

    const { id } = useParams<{ id: string }>();
    const [tableData, setTableData] = useState<any[]>([]);
    const [showDetailTable, setShowDetailTable] = useState(false);
    const [inventoryListingDetails, setInventoryListingDetails] = useState<any>([]);
    const inventory = null;
    const [activeSection, setActiveSection] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });

    const [filter, setFilter] = useState<any>({
        lotNo: null,
        rackhouse: null,
        productName: null,
    });

    function handleShowDetailTable(section) {
        setActiveSection(prev => (prev === section ? null : section));
        fetchInventoryListingDetailsData(section, paginationModel.page, paginationModel.pageSize, filter);
    }
    useEffect(() => {
        getInventoryListingDetails();
    }, []);

    const getInventoryListingDetails = async () => {
        try {
            const response = await InventoryApiService.getInventoryListingDetailsRequest(id);
            setInventoryListingDetails(response?.data);
        } catch (error) {
            console.log("error occurred while api calling ");
        }
    }

    const fetchInventoryListingDetailsData = async (selectActiveSection: any, page: any, size: any, filter: any) => {
        const controller = new AbortController();
        const signal = controller.signal;

        try {
            setIsLoading(true);
            setTableData([]);

            const response: any = await InventoryApiService.getInventoryListingDetailsByStatusRequest(id, selectActiveSection, page, size, filter, signal);
            
            setTableData(Object.values(response.data.content));
            setTotalCount(response.data.totalElements);
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false);
        }
    };

    const handleFilterChange = (
            event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
            field: keyof any
        ) => {
            const value = event.target.value;
    
            const tempFilter = {
                ...filter,
                [field]: value,
            };
    
            setFilter(tempFilter);
            fetchInventoryListingDetailsData(
                activeSection,
                paginationModel.page,
                paginationModel.pageSize,
                tempFilter
            );
        };

    const CellWithTooltip = ({ value }: { value: string | number }) => {
        return (
            <Tooltip arrow title={value?.toString()}>
                <span>{value?.toString()}</span>
            </Tooltip>
        );
    };
    const columns: GridColDef[] = [
        {
            field: "productName",
            headerName: "Inventory",
            flex: 1,
            minWidth: 220,
            headerClassName: "table-header border-0 my-2 h-100",
            renderHeader: (params) => (
                <div>
                    <Typography
                        variant="body2"
                        sx={{ color: "#6F6F6F", fontSize: "14px" }}
                    >
                        {params.colDef.headerName}
                    </Typography>
                    <div className="position-relative" style={{ width: "200px" }}>
                        <input
                            type="text"
                            className="form-control border mt-1"
                            style={{ textIndent: "24px", boxShadow: "none" }}
                            placeholder="Search"
                             defaultValue={filter.productName}
                             onChange={(event) => handleFilterChange(event, "productName")}
                            onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                        />
                        <SearchIcon
                            sx={{
                                color: "#6F6F6F",
                                position: "absolute",
                                top: "50%",
                                left: "8px",
                                transform: "translateY(-50%)",
                            }}
                        />
                    </div>
                </div>
            ),
            renderCell: (params: any) => <CellWithTooltip value={params.value} />
        },
        {
            field: "lotNo",
            headerName: "Lot ID",
            flex: 1,
            minWidth: 180,
            headerClassName: "table-header border-0 my-2 h-100",
            renderHeader: (params) => (
                <div>
                    <Typography
                        variant="body2"
                        sx={{ color: "#6F6F6F", fontSize: "14px" }}
                    >
                        {params.colDef.headerName}
                    </Typography>
                    <div className="position-relative" style={{ width: "180px" }}>
                        <input
                            type="text"
                            className="form-control border mt-1"
                            style={{ textIndent: "24px", boxShadow: "none" }}
                            placeholder="Search"
                            defaultValue={filter.lotNo}
                            onChange={(event) => handleFilterChange(event, "lotNo")}
                            onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                        />
                        <SearchIcon
                            sx={{
                                color: "#6F6F6F",
                                position: "absolute",
                                top: "50%",
                                left: "8px",
                                transform: "translateY(-50%)",
                            }}
                        />
                    </div>
                </div>
            ),
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
            field: "barrelNumber",
            headerName: "Barrel Number",
            flex: 1,
            minWidth: 180,
            headerClassName: "table-header border-0 my-2 h-100",
            renderHeader: (params) => (
                <div>
                    <Typography
                        variant="body2"
                        sx={{ color: "#6F6F6F", fontSize: "14px" }}
                    >
                        {params.colDef.headerName}
                    </Typography>
                    <div className="position-relative" style={{ width: "200px" }}>
                        <input
                            type="text"
                            className="form-control border mt-1"
                            style={{ textIndent: "24px", boxShadow: "none" }}
                            placeholder="Search"
                             defaultValue={filter.barrelNumber}
                             onChange={(event) => handleFilterChange(event, "barrelNumber")}
                            onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                        />
                        <SearchIcon
                            sx={{
                                color: "#6F6F6F",
                                position: "absolute",
                                top: "50%",
                                left: "8px",
                                transform: "translateY(-50%)",
                            }}
                        />
                    </div>
                </div>
            ),
            renderCell: (params: any) => <CellWithTooltip value={params.value} />
        },
        {
            field: "fillDate",
            headerName: "Fill Date",
            headerClassName: "table-header border-0 my-2 h-100",
            minWidth: 139,
            flex: 1,
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
            valueGetter: (value: any, row: any) => {
                if (!row.fillDate) return "";
                return format(new Date(row.fillDate), "MMM dd, yyyy");
            },
        },  
        {
            field: "ttbType",
            headerName: "TTB Type",
            flex: 1,
            minWidth: 180,
            headerClassName: "table-header border-0 my-2 h-100",
            renderHeader: (params) => (
                <div>
                    <Typography
                        variant="body2"
                        sx={{ color: "#6F6F6F", fontSize: "14px" }}
                    >
                        {params.colDef.headerName}
                    </Typography>
                    <div className="position-relative" style={{ width: "200px" }}>
                        <input
                            type="text"
                            className="form-control border mt-1"
                            style={{ textIndent: "24px", boxShadow: "none" }}
                            placeholder="Search"
                             defaultValue={filter.ttbType}
                             onChange={(event) => handleFilterChange(event, "ttbType")}
                            onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                        />
                        <SearchIcon
                            sx={{
                                color: "#6F6F6F",
                                position: "absolute",
                                top: "50%",
                                left: "8px",
                                transform: "translateY(-50%)",
                            }}
                        />
                    </div>
                </div>
            ),
            renderCell: (params: any) => <CellWithTooltip value={params.value} />
        },
        {
            field: "ttbClass",
            headerName: "TTB Classs",
            flex: 1,
            minWidth: 180,
            headerClassName: "table-header border-0 my-2 h-100",
            renderHeader: (params) => (
                <div>
                    <Typography
                        variant="body2"
                        sx={{ color: "#6F6F6F", fontSize: "14px" }}
                    >
                        {params.colDef.headerName}
                    </Typography>
                    <div className="position-relative" style={{ width: "200px" }}>
                        <input
                            type="text"
                            className="form-control border mt-1"
                            style={{ textIndent: "24px", boxShadow: "none" }}
                            placeholder="Search"
                             defaultValue={filter.ttbClass}
                             onChange={(event) => handleFilterChange(event, "ttbClass")}
                            onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                        />
                        <SearchIcon
                            sx={{
                                color: "#6F6F6F",
                                position: "absolute",
                                top: "50%",
                                left: "8px",
                                transform: "translateY(-50%)",
                            }}
                        />
                    </div>
                </div>
            ),
            renderCell: (params: any) => <CellWithTooltip value={params.value} />
        },
        {
            field: "volume",
            headerName: "Volume",
            flex: 1,
            minWidth: 180,
            headerClassName: "table-header border-0 my-2 h-100",
            renderHeader: (params) => (
                <div>
                    <Typography
                        variant="body2"
                        sx={{ color: "#6F6F6F", fontSize: "14px" }}
                    >
                        {params.colDef.headerName}
                    </Typography>
                    <div className="position-relative" style={{ width: "200px" }}>
                        <input
                            type="text"
                            className="form-control border mt-1"
                            style={{ textIndent: "24px", boxShadow: "none" }}
                            placeholder="Search"
                             defaultValue={filter.volume}
                             onChange={(event) => handleFilterChange(event, "volume")}
                            onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                        />
                        <SearchIcon
                            sx={{
                                color: "#6F6F6F",
                                position: "absolute",
                                top: "50%",
                                left: "8px",
                                transform: "translateY(-50%)",
                            }}
                        />
                    </div>
                </div>
            ),
            renderCell: (params: any) => <CellWithTooltip value={params.value} />
        },
        {
            field: "row",
            headerName: "Row",
            flex: 1,
            minWidth: 180,
            headerClassName: "table-header border-0 my-2 h-100",
            renderHeader: (params) => (
                <div>
                    <Typography
                        variant="body2"
                        sx={{ color: "#6F6F6F", fontSize: "14px" }}
                    >
                        {params.colDef.headerName}
                    </Typography>
                    <div className="position-relative" style={{ width: "200px" }}>
                        <input
                            type="text"
                            className="form-control border mt-1"
                            style={{ textIndent: "24px", boxShadow: "none" }}
                            placeholder="Search"
                             defaultValue={filter.row}
                             onChange={(event) => handleFilterChange(event, "row")}
                            onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                        />
                        <SearchIcon
                            sx={{
                                color: "#6F6F6F",
                                position: "absolute",
                                top: "50%",
                                left: "8px",
                                transform: "translateY(-50%)",
                            }}
                        />
                    </div>
                </div>
            ),
            renderCell: (params: any) => <CellWithTooltip value={params.value} />
        },
        {
            field: "depth",
            headerName: "Depth",
            flex: 1,
            minWidth: 180,
            headerClassName: "table-header border-0 my-2 h-100",
            renderHeader: (params) => (
                <div>
                    <Typography
                        variant="body2"
                        sx={{ color: "#6F6F6F", fontSize: "14px" }}
                    >
                        {params.colDef.headerName}
                    </Typography>
                    <div className="position-relative" style={{ width: "200px" }}>
                        <input
                            type="text"
                            className="form-control border mt-1"
                            style={{ textIndent: "24px", boxShadow: "none" }}
                            placeholder="Search"
                             defaultValue={filter.depth}
                             onChange={(event) => handleFilterChange(event, "depth")}
                            onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                        />
                        <SearchIcon
                            sx={{
                                color: "#6F6F6F",
                                position: "absolute",
                                top: "50%",
                                left: "8px",
                                transform: "translateY(-50%)",
                            }}
                        />
                    </div>
                </div>
            ),
            renderCell: (params: any) => <CellWithTooltip value={params.value} />
        },
        {
            field: "height",
            headerName: "Height",
            flex: 1,
            minWidth: 180,
            headerClassName: "table-header border-0 my-2 h-100",
            renderHeader: (params) => (
                <div>
                    <Typography
                        variant="body2"
                        sx={{ color: "#6F6F6F", fontSize: "14px" }}
                    >
                        {params.colDef.headerName}
                    </Typography>
                    <div className="position-relative" style={{ width: "200px" }}>
                        <input
                            type="text"
                            className="form-control border mt-1"
                            style={{ textIndent: "24px", boxShadow: "none" }}
                            placeholder="Search"
                             defaultValue={filter.height}
                             onChange={(event) => handleFilterChange(event, "height")}
                            onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                        />
                        <SearchIcon
                            sx={{
                                color: "#6F6F6F",
                                position: "absolute",
                                top: "50%",
                                left: "8px",
                                transform: "translateY(-50%)",
                            }}
                        />
                    </div>
                </div>
            ),
            renderCell: (params: any) => <CellWithTooltip value={params.value} />
        },
    ].filter(Boolean) as GridColDef[];

    const tableDataWithIds = tableData.map((rowData, index) => ({
        ...rowData,
    }));

    const routeBack = () => {
        history.goBack();
      };

    return (
        <>
            <div className="usersList position-relative pt-0">
                <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center p-3">
                    <div className="d-flex flex-wrap justify-content-between gap-4">
                        {CommonService.isAdmin() && (
                            <div className="d-flex align-items-center gap-1">
                                <><div className="back__button me-1" onClick={routeBack}>
                                    <IconButton
                                        aria-label="ArrowBackIcon"
                                        sx={{ backgroundColor: "#FFF" }}
                                    >
                                        <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
                                    </IconButton>
                                </div>
                                    <div className="header--title me-3">
                                        <h1 className="title mb-0">
                                            Inventory Details
                                            <i className="bi bi-heart-fill"></i>
                                        </h1>
                                    </div></>
                            </div>
                        )}
                    </div>
                </div>
                <div className="viewSampleRequest--wrapper view_request--wrapper view_sample-wrapper viewOrderRequest--wrapper viewServiceRequest--wrapper viewSupport-req inventory-listing-detail">
                    {/* {CommonService.isAdmin() && (<div className="header--title me-2">
                                <h1 className="title mb-0 ms-2">
                                  Offer Requests
                                </h1>
                              </div>)} */}
                    <div className="no-margin w-100">
                        <div className="view-sample-request m-0">
                            <div className="row m-0">
                                <div className="col-12 px-0">
                                    <div className="inventory-file-list-table" style={{minHeight:"calc(100vh - 300px)"}}>
                                        <div className="row m-0 h-100">
                                            <div className="col-xl-4 px-0">
                                                {CommonService.isAdmin() &&
                                                    inventoryListingDetails && inventoryListingDetails.length > 0 && (
                                                        <p className="content-text inventory-class-name" >
                                                            {inventoryListingDetails[0].ownerName || ""}
                                                        </p>
                                                )}
                                                <div className="left-wrapper">
                                                    <div className="left-heading">Rye Whiskey made with Hotaling 74/18/0/8</div>
                                                    <div className="left-sub-heading">Description</div>
                                                    <div className="left-content-wrapper">
                                                        <p className="content">
                                                            Get ready to immerse yourself in the spirit and tradition of the Kentucky Derby with Woodford Reserve's Kentucky Derby® 150 Limited Edition Bourbon Whiskey! This exceptional bourbon is a celebration of the 150th running of "The Greatest Two Minutes in Sports." Crafted with meticulous care at the historic Woodford Reserve Distillery in Versailles, Kentucky, this special edition bottle honors the rich and prolific partnership between Woodford Reserve and the Kentucky Derby®, a legacy that's been the heart of the event since 1999. Each sip transports you to the heart of Kentucky, with tasting notes that dance across the palate: think rich dried fruits mingling with a whisper of mint, zesty citrus cutting through smooth cocoa, and the indulgent sweetness of caramel and toffee, all rounded off with a touch of baking spice and chocolate. It's a flavor profile as complex and captivating as the Derby itself, bottled at a perfect 90.4 proof for a finish that's as smooth as silk and as long-lasting as the memories of the race day. Whether you're a die-hard bourbon aficionado, a Kentucky Derby® enthusiast, or simply someone who appreciates the finer things in life, the Woodford Reserve Kentucky Derby® 150 Limited Edition Bourbon Whiskey is an absolute must-have.
                                                        </p>
                                                    </div>
                                                    <div className="left-content-wrapper">
                                                        <p className="content-heading">
                                                            Grab your commemorative barrel today!
                                                        </p>
                                                        <p className="content">
                                                            There are not many things more American than bourbon, and although most of it is produced in Kentucky, it can be produced all over the USA.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-8 px-0">
                                                <div className="right-wrapper">
                                                    {inventoryListingDetails && inventoryListingDetails.length > 0 && (
                                                        <div className="row m-0">
                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div className="content-wrapper">
                                                                    <p className="content-title">
                                                                        Price/Barrel
                                                                    </p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <p className="content-text">
                                                                        ${inventoryListingDetails[0].price ? Number(inventoryListingDetails[0].price).toFixed(2) : "0.00"}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div className="content-wrapper">
                                                                    <p className="content-title">
                                                                        Fee/Barrel
                                                                    </p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <p className="content-text">
                                                                        ${inventoryListingDetails[0].fee ? Number(inventoryListingDetails[0].fee).toFixed(2) : "0.00"}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div className="content-wrapper">
                                                                    <p className="content-title">Age</p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <p className="content-text">
                                                                        {(() => {
                                                                            if (!inventoryListingDetails[0]?.fillDate) return "";

                                                                            const currentDate = new Date();
                                                                            const fillDate = new Date(inventoryListingDetails[0].fillDate);

                                                                            let years = currentDate.getFullYear() - fillDate.getFullYear();
                                                                            let months = currentDate.getMonth() - fillDate.getMonth();

                                                                            // Adjust for negative months
                                                                            if (months < 0) {
                                                                                years--;
                                                                                months += 12;
                                                                            }

                                                                            return `${years} years ${months} months`;
                                                                        })()}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div className="content-wrapper">
                                                                    <p className="content-title">Lot IDs</p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <div className="content-text">
                                                                        <div className="lot-id-list-wrapper d-flex flex-nowrap gap-2 h-100 align-items-center">
                                                                            {inventoryListingDetails[0].lotNo?.split(", ").map((lot, index) => (
                                                                                <div key={index} className="lot-id-item px-2 py-1 border rounded">{lot}</div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div className="content-wrapper">
                                                                    <p className="content-title">
                                                                        Created Date
                                                                    </p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <p className="content-text"> {new Date(inventoryListingDetails[0].createdDate).toLocaleDateString("en-US", {
                                                                        day: "2-digit",
                                                                        month: "2-digit",
                                                                        year: "numeric",
                                                                    }) || ""}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div className="content-wrapper">
                                                                    <p className="content-title">
                                                                        Location
                                                                    </p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <p className="content-text">{inventoryListingDetails[0].location || "-"}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div className="content-wrapper">
                                                                    <p className="content-title">
                                                                        Rackhouse
                                                                    </p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <p className="content-text">{inventoryListingDetails[0].rackhouse || "-"}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div className="content-wrapper">
                                                                    <p className="content-title">
                                                                        Facility
                                                                    </p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <p className="content-text">{inventoryListingDetails[0].facility || "-"}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div className="content-wrapper">
                                                                    <p className="content-title">
                                                                        Mashbill
                                                                    </p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <p className="content-text">{inventoryListingDetails[0].mashBill || "-"}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div className="content-wrapper">
                                                                    <p className="content-title">
                                                                        DSP
                                                                    </p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <p className="content-text">{inventoryListingDetails[0].dsp || "-"}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div className="content-wrapper">
                                                                    <p className="content-title">
                                                                        Batch Name
                                                                    </p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <p className="content-text">{inventoryListingDetails[0].batchName || "-"}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div className="content-wrapper">
                                                                    <p className="content-title">
                                                                       Producer
                                                                    </p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <p className="content-text">{inventoryListingDetails[0].producer || "-"}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div
                                                                    className={`content-wrapper ${activeSection === "LISTED" ? "active" : ""}`}
                                                                    onClick={() => handleShowDetailTable("LISTED")}
                                                                >
                                                                    <p className="content-title">Available Barrels</p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <p className="content-text-status">
                                                                        {inventoryListingDetails[0]?.availableBarrels ?? 0}{" "}
                                                                        {inventoryListingDetails[0]?.availableBarrels === 1 || inventoryListingDetails[0]?.availableBarrels === 0 ? "Barrel" : "Barrels"}
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div
                                                                    className={`content-wrapper ${activeSection === "RESERVED" ? "active" : ""}`}
                                                                    onClick={() => handleShowDetailTable("RESERVED")}
                                                                >
                                                                    <p className="content-title">Reserved Barrels</p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <p className="content-text-status">
                                                                        {inventoryListingDetails[0]?.reservedBarrels ?? 0}{" "}
                                                                        {inventoryListingDetails[0]?.reservedBarrels === 1 || inventoryListingDetails[0]?.reservedBarrels === 0 ? "Barrel" : "Barrels"}
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                                <div
                                                                    className={`content-wrapper ${activeSection === "SOLD" ? "active" : ""}`}
                                                                    onClick={() => handleShowDetailTable("SOLD")}
                                                                >
                                                                    <p className="content-title">Sold Barrels</p>
                                                                    <div className="title-bottom-line"></div>
                                                                    <p className="content-text-status">
                                                                        {inventoryListingDetails[0]?.soldBarrels ?? 0}{" "}
                                                                        {inventoryListingDetails[0]?.soldBarrels === 1 || inventoryListingDetails[0]?.soldBarrels === 0 ? "Barrel" : "Barrels"}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {activeSection &&
                                                        <div className="row m-0 mt-4">
                                                            <div className="col-12 px-0">
                                                                <div className="userTable inventory-listing-table-wrapper">
                                                                    <DataGrid
                                                                    autoHeight
                                                                        rows={tableDataWithIds}
                                                                        columns={columns}
                                                                        className="data-grid data-grid__myinventories"
                                                                        pagination
                                                                        paginationMode="server"
                                                                        paginationModel={paginationModel}
                                                                        onPaginationModelChange={(newPaginationModel) => {
                                                                             setPaginationModel(newPaginationModel);
                                                                             fetchInventoryListingDetailsData(
                                                                                "",
                                                                                newPaginationModel.page,
                                                                                newPaginationModel.pageSize,
                                                                                filter
                                                                             );
                                                                         }}
                                                                        pageSizeOptions={[10, 20, 50, 100]}
                                                                        rowCount={totalCount}
                                                                        disableRowSelectionOnClick={true}
                                                                        loading={isLoading}
                                                                        hideFooter
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default ViewInventoryListingDetails;
