import React, { useEffect, useMemo, useRef, useState } from "react";
import { Service } from "../../Service/ApiService";
import SearchIcon from "@mui/icons-material/Search";
import "../Dynamic/GroupMessageBox.scss";
import "../../sass/responsive.scss";
import "../Dynamic/AddSupportRequest.scss";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Typography,
  Box,
  Button,
  TextField,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material";
import { Container, Form, ListGroup } from "react-bootstrap";
import { CommonService } from "../../Service/CommonService";
import NoGroupMessage from "../Common/NoGroupMessage";
import SupportCommentBox from "./SupportCommentBox"; // Import the SupportCommentBox
import TrashIcon from "../../assets/images/icons/Trash.svg";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  useWebSocket,
  WebSocketProvider,
} from "../../Websocket/WebSocketProvider";
import { InventoryApiService } from "../../Service/InventoryApiService";
import DoneIcon from "@mui/icons-material/Done";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import Spinner from "../Common/Spinner";
import { useHistory, useParams } from "react-router";
import InputMask from "react-input-mask";
import { SupportApiService } from "../../Service/SupportApiService";
import Constant from "../../Constant";
import SupportFaqPanel from "./SupportFaqPanel";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Divider from '@mui/material/Divider';
import CloseIcon from "@mui/icons-material/Close";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const SupportRequest: React.FC = () => {
  const [files, setFiles] = useState<File[]>([]);
  const [file, setFile] = useState<File>(null);
  const [signatureFile, setSignatureFile] = useState<File>();
  const [activeTab, setActiveTab] = useState("open");
  const [search, setSearch] = useState("");
  const [selectedRequest, setSelectedRequest] = useState<any>(null);
  const [openRequestCount, setOpenRequestCount] = useState<number>(0);
  const [closedRequestCount, setClosedRequestCount] = useState<number>(0);
  const [supportRequests, setSupportRequests] = useState<any[]>([]);
  const [isNewTicket, setNewTicket] = useState(true);
  const [comment, setComment] = useState("");
  const [selectedCurrentUser, setSelectedCurrentUser] = useState(
    CommonService.getUserId()
  );
  const [serviceDocDetails, setServiceDocDetails] = useState([]);
  const [requestDescription, setRequestDescription] = useState("");
  const [sellRequestFee, setSellRequestFee] = useState('');
  const [sellRequestFeeType, setSellRequestFeeType] = useState('');
  const [selectedServiceName, setSelectedServiceName] = useState("");
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedCompanyError, setSelectedCompanyError] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedInventory, setSelectedInventory] = useState("");
  const [fields, setFields] = useState([]);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [formData, setFormData] = useState<Record<string, any>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isServiceFieldLoading, setServiceFieldLoading] = useState(false);
  const [companiesList, setCompaniesList] = useState([]);
  const [filteredCompaniesList, setFilteredCompaniesList] = useState([]);
  const [activeInactiveCompaniesList, setActiveInactiveCompaniesList] = useState([]);
  const [filteredActiveInactiveCompaniesList, setFilteredActiveInactiveCompaniesList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [currentCompaniesUserList, setCurrentCompaniesUserList] = useState([]);
  const [inventoriesList, setInventoriesList] = useState([]);
  const [quantity, setQuantity] = useState<number>(0);
  const [price, setPrice] = useState<number>(0);
  const [searchedInventories, setSearchedInventories] = useState<any>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const singleFileInputRef = useRef<HTMLInputElement>(null);
  const [searchSelectedInventory, setSearchSelectedInventory] = useState(null);
  const [barrelSearchTerm, setBarrelSearchTerm] = useState("");
  const [selectedBarrels, setSelectedBarrels] = useState([]);
  const [desiredQuantity, setDesiredQuantity] = useState("");
  const history = useHistory();
  const [isSearch, setIsSearch] = useState(false);
  const [isAdminSelectService, setAdminSelectService] = useState(false);
  const [isDoneButtonClicked, setIsDoneButtonClicked] = useState(false);
  const [companyInputValue, setCompanyInputValue] = useState('');
  const [userInputValue, setUserInputValue] = useState('');
  const [showCompanyDropdown, setShowCompanyDropdown] = useState(false);
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const filteredCompanyOptions = filteredActiveInactiveCompaniesList.filter(company =>
    companyInputValue !== '' && company.companyName.toLowerCase().includes(companyInputValue.toLowerCase())
  );
  const filteredUserOptions = usersList.filter(user =>
    userInputValue !== '' && user.userName.toLowerCase().includes(userInputValue.toLowerCase())
  );
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);
  const [viewFaq, setViewFaq] = useState(false);
  const handleCloseFaq = () => setViewFaq(false);
  const handleShowFaq = () => setViewFaq(true);
  const [selectedDsp, setSelectedDsp] = useState('');
  const [selectedBatchId, setSelectedBatchId] = useState('');
  const [selectedMashBill, setSelectedMashBill] = useState('');
  const [isInventoryNotFound, setIsInventoryNotFound] = useState(false);
  const [selectedBarrelInventoryId, setSelectedBarrelInventoryId] = useState(null);

  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [isValidated, setIsValidated] = useState(false);
  const [validationsList, setValidationsList] = useState<any[]>([]);
  const [isEditMode, setEditMode] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [ownerNameError, setOwnerNameError] = useState<string>("");
  const [ownerName, setOwnerName] = useState<string>("");
  const serviceRequestUpdateStatus = useRef({
        customerServiceRequestId: "",
        reason: "",
        status: "",
        spendHourOnRequest: ""
      });

  const [
    finalSelectedInventoriesWithBarrels,
    setFinalSelectedInventoriesWithBarrels,
  ] = useState([
    // Initial empty structure, or you can start with an empty array
    // { lotId: null, productName: "", barrelNumbers: [] }
  ]);

  const handleClose = () => {
    setValidationsList([]); // Clear the validation list on close
    setFile(null);
    setIsValidated(false);
  };

  const [searchTerm, setSearchTerm] = useState("");

  const {id} = useParams<{id: string}>();

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleSingleButtonClick = () => {
    if (singleFileInputRef.current) {
      singleFileInputRef.current.click();
    }
  };


  const fetchEditExistingCompanies = async () => {
    try {
      const response = await Service.fetchCompaniesList();
      const companyId = selectedCompany ? selectedCompany : (CommonService.isUser() ? CommonService.getCompanyId() : '');
      const existingCompanies = response.data.filter(
        (company) => company.id === companyId
      );
      let selectedCompanyName = null;
      if(isEditMode){
        const selectedCompany = existingCompanies.find(company => company.id === companyId);
        if(selectedCompany !== undefined) {
          selectedCompanyName = selectedCompany.companyName;
          setSelectedCompanyName(selectedCompanyName);
        }
      }
      return { selectedCompanyName };
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };
//   const fetchCustomerServiceRequestDocsForEditing = async () => {
//     //fetch all the docs service request

//     try {
//         const response = await SupportApiService.fetchCustomerServiceRequestDocsForEditing(id);
//         console.log(response?.data);
//         setServiceDocDetails(response?.data);
//     } catch (error) {
//         console.log("error occurred while api calling ");
//     }
// }


  //this method will call only when admin or user will edit the service request
  const  fetchCustomerServiceRequestForEditing = async () => {
    try {

      setEditMode(true);
      
      //call api and get all the details of customer service request 
      const response = await SupportApiService.getCustomerServiceRequestForEditing(id);

      //fetch the doc details
      // await fetchCustomerServiceRequestDocsForEditing();

      //set the service details
      setSelectedService(response?.data?.selectedServiceId);
      setSelectedServiceName(response?.data?.serviceName);

      //now call the api which load all the dynamic field for service 
      setServiceFieldLoading(true);
      const fieldResponse = await Service.getFieldByServiceType(response?.data?.selectedServiceId);

      //exclude the fee_type_dropdown and commission_fee because this is not editable and set fields
      setFields(fieldResponse.data.filter((field) => field.dataType !== "fee_type_dropdown" && field.dataType !== "commission_fee"));

      
      //fill all the details
      setSelectedCompany(response?.data?.selectedCompanyId);
      setSelectedUser(response?.data?.selectedUserId);
      setRequestDescription(response?.data?.comment);
      setIsDoneButtonClicked(true);
      setFinalSelectedInventoriesWithBarrels(response?.data?.finalSelectedInventoriesWithBarrels);
      setAdminSelectService(CommonService.isAdmin()? true : false);

      //now fetch all the existing companies
      await fetchExistingCompanies(response?.data?.selectedCompanyId);

      if(response?.data?.serviceName == 'Private Sell' || response?.data?.serviceName == 'Sell Request') {
        setSelectedMashBill(response?.data?.finalSelectedInventoriesWithBarrels[0]?.mashBill);
        setSelectedDsp(response?.data?.finalSelectedInventoriesWithBarrels[0]?.dsp);
        setSelectedBatchId(response?.data?.finalSelectedInventoriesWithBarrels[0]?.batchId);

      }

      if(response?.data?.serviceName == 'Ownership Transfer') {
        setSelectedBarrelInventoryId(response?.data?.finalSelectedInventoriesWithBarrels[0]?.inventoryId);
      }
      

      // Initialize form data with default values
      const initialData = {};
      const initialError = {};


      //set all the dynamic field data
      response?.data?.dynamicFields?.forEach((field) => {
        //add logic for 'owner name' enable or disable
        if(field.fieldName === 'Are you the owner of uploaded inventories?' && field.value === 'true') {
          setIsDisabled(true);
        }
        initialData[field.id] = (field.value == 'true' ? true : (field.value == 'false' ? false : field.value ))
      });

      setFormData(initialData);
      setErrors(initialError);
      setServiceFieldLoading(false);

      //if the service name is ownership transfer then we need to find the new companyId and call the api which fetch user of this company
      if(response?.data?.serviceName == 'Ownership Transfer') {
        fieldResponse?.data?.forEach(async (field) => {
          if(field?.fieldName == 'New Company') {
            await fetchUsersByCompany(initialData[field?.serviceTypeServiceFieldId]);
          }
          else if(field?.fieldName == 'Current Company') {
            await fetchUsersByCurrentCompany(initialData[field?.serviceTypeServiceFieldId]);
          }
        })
      }

      if (response?.data?.serviceName === Constant.CustomerService.PRIVATE_SELL) {
        for (const field of fieldResponse?.data || []) {
          if (field?.fieldName === 'Private Buyer Org') {
            setCompanyInputValue(initialData[field?.serviceTypeServiceFieldId]);
            await fetchAllActiveAndInactiveCompanies(initialData[field?.serviceTypeServiceFieldId]);
          } else if (field?.fieldName === 'Private Buyer User') {
            setUserInputValue(initialData[field?.serviceTypeServiceFieldId]);
          }
        }
      }
    } catch(error) {
      setServiceFieldLoading(false);
      console.log("error occurred while api calling ");

    }
  }
  

  useEffect(() => {
    // loadSupportRequests("OPEN");
    // getOpenRequestCount();
    // getClosedRequestCount();
     
    fetchAllServices();
    

    if(id) {
      fetchCustomerServiceRequestForEditing(); 
    }
  }, []);

  const fetchAllServices = async () => {
    try {
      setIsLoading(true);
      const response = await Service.getAllServiceType();
      setServices(response.data);
    } catch (error) {
      console.log("Error occurred while API calling:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFieldsByServiceType = async (serviceTypeId, serviceTypeName) => {
    try {
      setServiceFieldLoading(true);
      const response = await Service.getFieldByServiceType(serviceTypeId);
      setFields(response.data);

      // Initialize form data with default values
      const initialData = {};
      const initialError = {};
      response.data.forEach((field) => {
        if (
          field.dataType === "current_company" ||
          field.dataType === "current_user"
        ) {
          initialData[field.serviceTypeServiceFieldId] =
            field.dataType === "current_company"
              ? (CommonService.isUser() ? CommonService.getCompanyId() : '' )
              : field.dataType === "current_user"
              ? (CommonService.isUser() ? CommonService.getUserId() : '' )
              : "";
        }
      });
      setSelectedCompany(CommonService.isUser() ? CommonService.getCompanyId() : '')
      setFormData(initialData);
      setErrors(initialError);
      fetchExistingCompanies(CommonService.isUser() ? CommonService.getCompanyId() : '');

      if(serviceTypeName === Constant.CustomerService.PRIVATE_SELL)
        fetchAllActiveAndInactiveCompanies('');    
    } catch (error) {
      console.log("Error occurred while API calling:", error);
    } finally {
      setServiceFieldLoading(false);
    }
  };

  const fetchExistingCompanies = async (companyId) => {
    try {
      const response = await Service.fetchCompaniesList();
      // const companyId = selectedCompany ? selectedCompany : (CommonService.isUser() ? CommonService.getCompanyId() : '');

      console.log("these are my company id "+ companyId);
      setFilteredCompaniesList(response?.data);
      //exclude this company from the list

      
      const existingCompanies = response.data.filter(
        (company) => company.id !== companyId
      );
      setCompaniesList(existingCompanies);
      if(companyId != '')
        fetchUsersByCurrentCompany(companyId);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const fetchAllActiveAndInactiveCompanies = async (companyName: string) => {
    try {
      const response = await Service.getAllActiveAndInactiveCompanies();
      const companyId = selectedCompany ? selectedCompany : (CommonService.isUser() ? CommonService.getCompanyId() : '');
      setActiveInactiveCompaniesList(response?.data);
      //exclude selected company from the list
      const existingCompanies = response.data.filter(
        (company) => company.id !== companyId
      );
      setFilteredActiveInactiveCompaniesList(existingCompanies);

      if(id){
        const companyDetails = response.data.find(
          (item) => item.companyName === companyName
        );

        if (companyDetails)
          fetchUsersByCompany(companyDetails.id);
      }else
        fetchUsersByCompany(companyId);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  // const fetchInventoriesByCompany = async (companyId: any) => {
  //   try {
  //     const response = await InventoryApiService.getInventoryListByCompanyId(
  //       companyId
  //     );
  //     setInventoriesList(response.data);
  //   } catch (error) {
  //     console.error("Error while calling api:", error);
  //   }
  // };

  const fetchUsersByCompany = async (companyId: any) => {

    try {
      const response = await Service.fetchUsersList(companyId);
      setUsersList(response.data);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const fetchUsersByCurrentCompany = async (companyId) => {

    try {
      const response = await Service.fetchUsersList(
        companyId
      );
      setCurrentCompaniesUserList(response.data);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const handleServiceChange = (serviceId, serviceName) => {
    
    setEditMode(false);
    if(serviceName === 'Bulk Inventory Upload'){
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }

    if(id) return ;
    setSelectedServiceName(serviceName);
    setSelectedService(serviceId);
    if (serviceId) {
      setFormData((formData) => ({ ...formData, serviceId: serviceId }));
      setRequestDescription("");
      setFile(null);
      setFiles([]);
      setSelectedCompanyError("");
      setSearchedInventories([]);
      setSelectedMashBill('');
      setSelectedBatchId('');
      setSelectedDsp('');
      setFinalSelectedInventoriesWithBarrels([]);
      setSearchTerm('');
      setBarrelSearchTerm('');
      setIsInventoryNotFound(false);
      setAdminSelectService(true);
      setShowCompanyDropdown(false);
      setShowUserDropdown(false);
      fetchFieldsByServiceType(serviceId, serviceName);
      //set this serviceId into the formData
      if (serviceId) {
        setFormData((formData) => ({ ...formData, serviceId: serviceId }));
      }
      
    } else {
      setFields([]);
      setFormData({});
    }
  };

  const handleCompanyChange = (value: any, serviceTypeServiceFieldId: any) => {
    setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "" }));
    const companyId = value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [serviceTypeServiceFieldId]: value,
    }));

    fields.forEach((field) => {
      if (
        field.fieldName == "New User"
      ) {
        formData[field.serviceTypeServiceFieldId] = ''
      }
    });

    setUsersList([]);

    if(companyId) {
       fetchUsersByCompany(companyId);
    }
  };

  const handleUserCompanyChange = async (value: any) => {
    if (id) return;
    setSelectedCompanyError("");
    const companyId = value;

    //find selected companyName and set
    const selectedCompany = companiesList.find(company => company.id === companyId);
    if(selectedCompany !== undefined) {
      setSelectedCompanyName(selectedCompany.companyName);
    }
    
    //if admin change the company reset the all the inventory details
    setSearchedInventories([]);
    setSearchSelectedInventory(null);
    setFinalSelectedInventoriesWithBarrels([]);
    setSelectedMashBill('');
    setSelectedBatchId('');
    setIsInventoryNotFound(false);
    setSelectedDsp('');

    if(companyId) {
      setSelectedCompany(companyId);
      const existingCompanies = filteredCompaniesList.filter(
        (company) => company.id !== companyId
      );
      setCompaniesList(existingCompanies);

      const existingActiveInactiveCompanies = activeInactiveCompaniesList.filter(
        (company) => company.id !== companyId
      );
      setFilteredActiveInactiveCompaniesList(existingActiveInactiveCompanies);
      if(selectedServiceName == 'Ownership Transfer') {
        fields.forEach((field) => {
          if(field.fieldName == 'Current Company') {
            formData[field.serviceTypeServiceFieldId] = companyId;
            errors[field.serviceTypeServiceFieldId] = '';
          }
          else if(field.dataType == 'company_dropdown') {
            formData[field.serviceTypeServiceFieldId] = '';
          }
          else if(field.fieldName == 'Current User') {
            formData[field.serviceTypeServiceFieldId] = '';
          }
        });
        
        fetchUsersByCurrentCompany(companyId);
      } else if(selectedServiceName == Constant.CustomerService.PRIVATE_SELL) {
        fields.forEach((field) => {
          if(field.fieldName == 'Private Buyer Org') {
            errors[field.serviceTypeServiceFieldId] = '';
          }
        });
      }
      else if (selectedServiceName == 'Bulk Inventory Upload') {
          fetchUsersByCompany(companyId);
          fields.forEach((field) => {
            if(field.dataType == 'radio') {
              formData[field.serviceTypeServiceFieldId] = '';
            }
            else if(field.dataType == 'text') {
              formData[field.serviceTypeServiceFieldId] = '';
            }
            else if(field.dataType == 'upload_file') {
              setFile(null);
              formData[field.serviceTypeServiceFieldId] = '';
            }
            else if(field.dataType == 'upload_doc') {
              setFiles(null);
              formData[field.serviceTypeServiceFieldId] = '';
            }
          });
      }
    }
  };

  const handleUserChange = (value: any, serviceTypeServiceFieldId: any) => {
    setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "" }));
    const userId = value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [serviceTypeServiceFieldId]: value,
    }));
  };


  const handleSellRequestFeeTypeChange = (value: any, serviceTypeServiceFieldId: any) => {
    setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "" }));

    setSellRequestFeeType(value);
  };

  const handleCurrentUserChange = (
    value: any,
    serviceTypeServiceFieldId: any
  ) => {
    setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "" }));
    // const userId = value;
    // setSelectedCurrentUser(userId);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [serviceTypeServiceFieldId]: value == 'Current user' ? '': value
    }));
  };

  const handleOptionClick = (optionFor: string, selectedOptionId:any, selectedOptionName: string, serviceTypeServiceFieldId: any) => {    
    setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "" }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      [serviceTypeServiceFieldId]: selectedOptionName,
    }));
    if(optionFor === 'COMPANY'){
        setCompanyInputValue(selectedOptionName);  // Set selected option in the input
        setShowCompanyDropdown(false); // Close the dropdown after selection
        fetchUsersByCompany(selectedOptionId);// fetch users of selected company
        //add companyid to formdata
        setFormData((prevFormData) => ({
          ...prevFormData,
          privateBuyerOrgId: selectedOptionId, //selectedCompanyId,
        }));
        setFormData(({ privateBuyerUserId, ...rest }) => rest);  // remove userid from formdata
        // reset private buyer user data when company is changed
        fields.forEach((field) => {
          if(field.fieldName == 'Private Buyer User') {
            formData[field.serviceTypeServiceFieldId] = '';
          }
        });
    }else{
        setUserInputValue(selectedOptionName);  // Set selected option in the input
        setShowUserDropdown(false); // Close the dropdown after selection
        setFormData((prevFormData) => ({
          ...prevFormData,
          privateBuyerUserId: selectedOptionId, //selectedUserId,
        }));
    }
  };

  const handleOptionChange = (optionFor: string, value, serviceTypeServiceFieldId) => {
    setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "" }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      [serviceTypeServiceFieldId]: value,
    }));
    if(optionFor === 'COMPANY'){
      setCompanyInputValue(value); // Set selected option in the input
      setShowCompanyDropdown(value !== ''); // Close the dropdown after selection
      setUsersList([]);
      //on change company remove selected company id
      setFormData((prevFormData) => {
        const { privateBuyerOrgId, privateBuyerUserId, ...rest } = prevFormData;
        return rest; // returning the rest of the object after removing 'privateBuyerOrgId' and 'privateBuyerUserId'
      });
      // reset private buyer user data when company is changed
      fields.forEach((field) => {
        if(field.fieldName == 'Private Buyer User') {
          formData[field.serviceTypeServiceFieldId] = '';
        }
      });
    }else{
      setUserInputValue(value); // Set selected option in the input
      setShowUserDropdown(value !== '');  // Close the dropdown after selection
      setFormData(({ privateBuyerUserId, ...rest }) => rest); //on change company remove selected user id
    }
  };

  const addCompanyAndUserForPrivateSell = async () => {
    let savedCompanyId;
  
    // Check if the company needs to be added
    if (formData?.privateBuyerOrgId === undefined) {
      for (const field of fields) {
        if (field.fieldName === 'Private Buyer Org') {
          const sanitizedValue = formData[field.serviceTypeServiceFieldId].replace(/[^a-zA-Z0-9]/g, "");
  
          // Construct the email
          const email = `${sanitizedValue}@unknown.com`.toLowerCase();
  
          const requestData = {
            companyEmail: email,
            isActive: false,
            isDelete: false,
            companyName: formData[field.serviceTypeServiceFieldId]
          };
  
          try {
            // Save company and get the savedCompanyId
            const response = await Service.addCompany(requestData);
            savedCompanyId = response.data; // add company method returns id
          } catch (error) {
            console.error("Error saving company:", error);
            return; // Exit if the company save fails
          }
          break; // Exit the loop once the company is saved
        }
      }
    }
  
    // Check if the user needs to be added
    if (formData?.privateBuyerUserId === undefined) {
      for (const field of fields) {
        if (field.fieldName === 'Private Buyer User') {
          const sanitizedValue = formData[field.serviceTypeServiceFieldId].replace(/[^a-zA-Z0-9]/g, "");
  
          // Construct the email
          const email = `${sanitizedValue}@unknown.com`.toLowerCase();
  
          const requestData = {
            email: email,
            isActive: false,
            isDelete: false,
            firstName: formData[field.serviceTypeServiceFieldId],
            companyId: formData?.privateBuyerOrgId === undefined ? savedCompanyId : formData?.privateBuyerOrgId
          };
  
          try {
            if(requestData.companyId != false) // means user have company id
              await Service.addUser(requestData);// Save user
          } catch (error) {
            console.error("Error saving user:", error);
          }
          break; // Exit the loop once the user is added
        }
      }
    }
  };

  const handleRequestDescriptionChange = (
    value: any,
    serviceTypeServiceFieldId: any
  ) => {
    setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "" }));
    setRequestDescription(value);
  };

  const handleSellRequestFeeChange = (
    value: any,
    serviceTypeServiceFieldId: any
  ) => {
    setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "" }));
    setSellRequestFee(value);
  };

  // const handleSellRequestFeeTypeChange = (
  //   value: any,
  //   serviceTypeServiceFieldId: any
  // ) => {
  //   setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "" }));
  //   setSellRequestFeeType(value);
  // };

  const handleInventoryChange = (
    value: any,
    serviceTypeServiceFieldId: any
  ) => {
    setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "" }));

    const inventoryId = value;
    setSelectedInventory(inventoryId);
  };

  const handleCkeditorContentChange = (value: string, serviceTypeServiceFieldId: string) => {
    const cleanedValue = value.trim() === "<p>&nbsp;</p>" ? "" : value;
  
    setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "" }));
  
    setFormData((prevFormData) => ({
      ...prevFormData,
      [serviceTypeServiceFieldId]: cleanedValue,
    }));
  };
  

  const handleFieldChange = (value, serviceTypeServiceFieldId) => {
    setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "" }));

    console.log(value);

    let processedValue = value;
    const field = fields.find((item) => item.fieldName === 'Owner Name');
    const radioValue = fields.find((item) => item.serviceTypeServiceFieldId === serviceTypeServiceFieldId);

    //owner name not greater then 500 length
    if(field !== undefined){
      if(processedValue.trim().length > 500){
        setOwnerNameError("Owner Name can't greater then 500 characters.");
        return;
      } else {
        setOwnerNameError(""); // Clear error if within limit
      }
    }

    // Convert "yes"/"no" to true/false
    if (value === "yes") {
        processedValue = true;
        if(radioValue.fieldName === 'Are you the owner of uploaded inventories?'){
            setIsDisabled(true);
            if(isEditMode) {
              fetchEditExistingCompanies().then(({ selectedCompanyName }) => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  [field?.serviceTypeServiceFieldId]: CommonService.isUser()
                    ? CommonService.getCompanyName()
                    : selectedCompanyName || "",
                }));
              }).catch((error) => {
                console.error("Error fetching companies:", error);
              });
            } else {
              setFormData((prevFormData) => ({
                ...prevFormData,
                [field?.serviceTypeServiceFieldId]: CommonService.isUser() ? CommonService.getCompanyName() : selectedCompanyName
              }));
            } 
          setErrors((prev) => ({ ...prev, [field?.serviceTypeServiceFieldId]: "" })); 
        } 
      } else if (value === "no") {
        processedValue = false;
        if(radioValue.fieldName === 'Are you the owner of uploaded inventories?'){
          setIsDisabled(false);
          setFormData((prevFormData) => ({
            ...prevFormData,
            [field?.serviceTypeServiceFieldId]: ''
          })); 
        } 
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [serviceTypeServiceFieldId]: processedValue,
    }));
  };

  const isValidate = () => {
    let isValid = true;

    const newErrors: Record<string, string> = {};

    fields.forEach((field) => {
      if (field.mandatory) {
        //first check if the data type is add_inventory or price
        if (field.dataType === "add_inventory" || field.dataType === "add_barrel" || field.dataType === "price" ||  errors[field.serviceTypeServiceFieldId]) {
          if (
            ((field.dataType === "add_inventory" || field.dataType === "add_barrel") &&
              finalSelectedInventoriesWithBarrels?.length <= 0) ||
            (field.dataType === "price" && price <= 0) 
          ) {
            newErrors[field.serviceTypeServiceFieldId] = "error"
            isValid = false;
          }
          // else if((CommonService.isAdmin() && (field.dataType === "fee" || field.dataType == "fee_type_dropdown")) && (sellRequestFee == '' || sellRequestFeeType == '')) {
          //   newErrors[field.serviceTypeServiceFieldId] = "error"
          //   isValid = false;
          // }
          else if(errors[field.serviceTypeServiceFieldId]) {
            newErrors[field.serviceTypeServiceFieldId] = errors[field.serviceTypeServiceFieldId];
            isValid = false;
          }
        
        }

        //now check for radio buttons
        else if (field.dataType === "radio") {
          // Check if the value is actually undefined
          if (
            typeof formData[field.serviceTypeServiceFieldId] === "undefined"
          ) {
            newErrors[field.serviceTypeServiceFieldId] = "error";
            isValid = false;
          }
        } else if (field.dataType === "upload_file") {
          if (!formData[field.serviceTypeServiceFieldId] || formData[field.serviceTypeServiceFieldId]?.length === 0) {
            newErrors[field.serviceTypeServiceFieldId] = "Please upload csv or xlsx file.";
            isValid = false;
          }
        }

        else if(field.dataType == "contact_number") {
          if(formData[field.serviceTypeServiceFieldId]?.trim()?.length != 10) {
            newErrors[field.serviceTypeServiceFieldId] = errors[field.serviceTypeServiceFieldId] ? errors[field.serviceTypeServiceFieldId]: "This field is required";
            isValid = false; 
          }
        }

        else if(field.dataType == "email") {
          if(!validateEmail(formData[field.serviceTypeServiceFieldId])) {
            newErrors[field.serviceTypeServiceFieldId] = errors[field.serviceTypeServiceFieldId] ? errors[field.serviceTypeServiceFieldId] : "This field is required";
            isValid = false; 
          }
        }

        // check if for other dynamic fields
        else if (!formData[field.serviceTypeServiceFieldId]) {
          newErrors[field.serviceTypeServiceFieldId] = "error";
          isValid = false;
        }

        else if(field.dataType == "private_buyer_org") {
          if(!validatePrivateBuyerDetail(formData[field.serviceTypeServiceFieldId])){
            newErrors[field.serviceTypeServiceFieldId] = "Please enter valid detail";
            isValid = false; 
          }else if(formData['privateBuyerOrgId'] === selectedCompany) {
            newErrors[field.serviceTypeServiceFieldId] = "The buyer's company is not the same as the owner's company.";
            isValid = false; 
          }else if(!formData[field.serviceTypeServiceFieldId]){
            newErrors[field.serviceTypeServiceFieldId] = "error";
            isValid = false;
          }else if(showCompanyDropdown && filteredCompanyOptions.length > 0){
            newErrors[field.serviceTypeServiceFieldId] = `Please select an option`;
            isValid = false;
          }
        }

        else if(field.dataType == "private_buyer_user") {
          if(!validatePrivateBuyerDetail(formData[field.serviceTypeServiceFieldId])){
            newErrors[field.serviceTypeServiceFieldId] = "Please enter valid detail";
            isValid = false; 
          }else if(showUserDropdown && filteredUserOptions.length > 0){
            newErrors[field.serviceTypeServiceFieldId] = `Please select an option`;
            isValid = false;
          }
        }

      }
    });

    if(CommonService.isAdmin() && !selectedCompany) {
      setSelectedCompanyError("Please select company");
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

  
    const dynamicFields = Object.keys(formData)
      .filter(key => key !== 'undefined' && key !== '')  // Remove undefined or empty keys
      .map((key) => {
        const field = fields.find(f => f.serviceTypeServiceFieldId === key);
    
        if (field) {
          return {
            id: key,
            fieldName: field.fieldName,  // Get fieldName from fields array
            value: formData[key]
          };
        }
      });

    if (await isValidate()) {
      // Prepare the object to send
      const requestData = {
        dynamicFields,
        selectedCompanyId: selectedCompany,
        selectedUserId: CommonService.getUserId(),
        selectedInventoryId: selectedInventory,
        selectedServiceId: selectedService,
        customerServiceRequestId : id ? id : null,
        comment: requestDescription,
        price: price,
        quantity: quantity,
        ownerName: ownerName,
        finalSelectedInventoriesWithBarrels,
        tempPath:'',
      };


      const serviceRequestData = new FormData();
      if (files) {
        Array.from(files).forEach((file) => {
          serviceRequestData.append("file[]", file);
        });
      }
      
      if (file) {
        serviceRequestData.append("file", file);
      }

      serviceRequestData.append(
        "param",
        new Blob([JSON.stringify(requestData)], {
          type: "application/json",
        })
      );

      // Log the form data to the console
      setIsLoading(true);
      setValidationsList([]);
      // call the api
      try {
        const response = await Service.saveCustomerSupportRequest(
          serviceRequestData
        );
        const id = response.data;
          
        if (CommonService.isAdmin() && isAdminSelectService && selectedServiceName === 'Bulk Inventory Upload' && isEditMode === false) {

          const currentStatus = {
            customerServiceRequestId: id,
            serviceName: "Bulk Inventory Upload",
            reason: "",
            status: "UPLOADED",
            spendHourOnRequest: null,
            companyId: selectedCompany,
            userId: CommonService.getUserId(),
            dynamicFields: dynamicFields || [],
          };
        
          try {
            // Call the long-running API
            const statusResponse = await SupportApiService.updateCustomerServiceRequestStatus(currentStatus);
            
            console.log(statusResponse.data);
            // Show success message after the API call completes
            CommonService.Toast.fire({
              title: "Your barrel is being uploaded and will be available shortly.",
              icon: "success",
            });
          } catch (error) {
            // Handle any errors that occur during the API call
            CommonService.Toast.fire({
              title: "An error occurred while uploading your barrel. Please try again later.",
              icon: "error",
            });
          }
        } else {
          // Show the general success message only if the admin condition is not met
          CommonService.Toast.fire({
            title: `Request ${requestData.customerServiceRequestId ? "Updated" : "Submitted"} Successfully`,
            icon: "success",
          });
        }
        
        setIsLoading(false);

        //if(response.data == true){
        if(response.data != null && response.data != undefined) {
          if(selectedServiceName == Constant.CustomerService.PRIVATE_SELL)
            addCompanyAndUserForPrivateSell();
  
          const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";
          history.push(`${linkPrefix}/customer-service`);
        }
      } catch (error) {
        setIsLoading(false);

        if (error.response) {
        // The backend error message should be inside error.response.data.message

        console.log(error?.response?.data?.errorMessage);
        const errorMessage =
          error?.response?.data?.errorMessage || "Failed to upload CSV";

        // Log the error for debugging
        console.log("Error message from server: ", errorMessage);

        // Show the error in a toaster
        if (error?.response?.data?.data != null && errorMessage.includes("Error occurred while validating")) {
          CommonService.Toast.fire({
            title: "Please Upload a valid file",
            icon: "error",
          });

          setValidationsList(error?.response?.data?.data);
          setIsValidated(true);
        } else if (errorMessage.includes("Invalid Csv File")) {
          CommonService.Toast.fire({
            title: "Please Upload a valid CSV file",
            icon: "error",
          });
        } else if (errorMessage.includes("Excel file")) {
          CommonService.Toast.fire({
            title: "Please Upload a valid excel file",
            icon: "error",
          });
        } else if (errorMessage.includes("UNSUPPORTED_MEDIA_TYPE")) {
          CommonService.Toast.fire({
            title: "Please select a valid inventory file format either CSV or Excel (XLSX). You can add non-CSV or non-Excel files as supporting documents.",
            icon: "error",
          });
        } else {
          CommonService.Toast.fire({
            title: errorMessage,
            icon: "error",
          });
        }
      }else {
        // Handle errors without a response (e.g., file locked, network issues)
        console.error("Error without response:", error.message);
      
        if (error.message.includes("Failed to fetch")) {
          CommonService.Toast.fire({
            title: "Network error occurred. Please check your internet connection and try again.",
            icon: "error",
          });
        } else if (error.message.includes("File is locked")) {
          CommonService.Toast.fire({
            title: "Please close the file in Excel or any other program before uploading.",
            icon: "error",
          });
        } else if (error.message.includes("Cannot read properties of null")) {
          CommonService.Toast.fire({
            title: "Invalid file reference. Please select the file again and submit.",
            icon: "error",
          });
        } else {
          CommonService.Toast.fire({
            title: "Unexpected error occurred. Please try again.",
            icon: "error",
          });
        }
      }
      }
    }
  };

  const handleQuantityChange = (
    quantity: any,
    serviceTypeServiceFieldId: any
  ) => {
    setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "" }));

    const value = Number(quantity);
    setQuantity(value);
  };

  const handlePriceChange = (price: any, serviceTypeServiceFieldId: any) => {
    setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "" }));

    const value = Number(price);
    setPrice(value);
  };

  const handleNumberOnBlur = (value: any, serviceTypeServiceFieldId: any) => {
    const numValue = Number(value);
    if (numValue <= 0 || !value) {
      setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "error" }));
    }
  };

  const handleSellRequestFeeOnBlur = (value: any, serviceTypeServiceFieldId: any) => {
    const numValue = Number(value);
    if (numValue < 0 || !value) {
      setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "error" }));
    }
  };

  const handleFieldBlur = (value: any, serviceTypeServiceFieldId: any) => {
    if (!value || value == null || value?.trim() == "") {
      setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "error" }));
    }
  };

  const handleEmailBlur = (value: any, serviceTypeServiceFieldId: any) => {
    if (!value || value == null || value?.trim() == "") {
      setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "This field is required" }));
    }

    else if(!validateEmail(value?.trim())) {
      setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "Please enter valid email" }));
    }
  }

  const handleContactBlur = (value: any, serviceTypeServiceFieldId: any) => {
    if (!value || value == null || value?.trim() == "") {
      setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "This field is required" }));
    }

    else if(value?.trim()?.length != 10) {
      setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "Please enter valid contact number" }));
    }
  }

  const handleCompanyBlur = (serviceTypeServiceFieldId: any) => {
    if (!formData[serviceTypeServiceFieldId]) {
      setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "error" }));
    }
  };

  const handleInventoryBlur = (serviceTypeServiceFieldId: any) => {
    if (!selectedInventory) {
      setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "error" }));
    }
  };

  const handleUserBlur = (serviceTypeServiceFieldId: any) => {
    if (!formData[serviceTypeServiceFieldId]) {
      setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "error" }));
    }
  };

  const handleSellRequestFeeTypeBlur = (serviceTypeServiceFieldId: any) => {
    if (!sellRequestFeeType || sellRequestFeeType.length == 0) {
      setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "error" }));
    }
  };

  const handleSearchInventory = async (
    value: any,
    serviceTypeServiceFieldId: any
  ) => {
    // handleFieldChange(value,serviceTypeServiceFieldId);
    setSearchSelectedInventory(null);
    setErrors((prev) => ({ ...prev, [serviceTypeServiceFieldId]: "" }));
    setSearchTerm(value);

     if(!selectedCompany) {
      setSelectedCompanyError("Please select company");
      setSearchedInventories([]);
      setSearchSelectedInventory(null);
      return ;
     } 
    //for searching required minimum 4 characters
    if (value?.trim().length > 2) {
      setIsSearch(true);

      //now create the request object for searching inventory
      const inventorySearchRequestForCustomerService = {
        companyId: selectedCompany,
        query: value?.trim(),
        serviceName: selectedServiceName,
        mashBill: selectedMashBill,
        batchId: selectedBatchId,
        dsp: selectedDsp,
        inventoryId: selectedBarrelInventoryId
      };

      //now call the api which fetched matching inventories
      try {
        const response = await InventoryApiService.searchInventory(inventorySearchRequestForCustomerService);
        setSearchedInventories(response?.data);
        setSearchSelectedInventory(response?.data[0]);

        setIsInventoryNotFound(response?.data?.length > 0 ? false : true);
        setIsSearch(false);
      } catch (error) {
        console.log("error while calling the api");
        setIsSearch(false);
      }
    } else {
      setIsInventoryNotFound(false);
      setSearchedInventories([]);
      setSearchSelectedInventory(null);
    }
  };

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePrivateBuyerDetail = (value: string): boolean => {
    const alphaNumericRegex = /[a-zA-Z]/;
    return alphaNumericRegex.test(value);
  };

  // Filter barrel numbers based on search term
  const filteredBarrelNumbers = searchSelectedInventory
    ? searchSelectedInventory.barrelNumbers.filter((barrel) =>
        barrel?.barrelNumber?.toLowerCase()
          .includes(barrelSearchTerm?.toLowerCase())
      )
    : [];

  // Handle select all barrels checkbox
  const handleSelectAllBarrels = async () => {
    const { lotNo, title, mashBill, batchId, dsp, inventoryId, ownerName} = searchSelectedInventory;

    // Check if all barrels are currently selected
    const isAllSelected =
      selectedBarrels.length === filteredBarrelNumbers.length;

    if (isAllSelected) {
      // Deselect all barrels
      setSelectedBarrels([]);

      // Update finalSelectedInventoriesWithBarrels by removing the current lot
      await setFinalSelectedInventoriesWithBarrels((prev) =>
        prev.filter((inventory) => inventory.lotNo !== lotNo)
      );

      if(selectedServiceName == 'Private Sell' || selectedServiceName == 'Sell Request' || selectedServiceName == 'Ownership Transfer') {
        resetAttributesIfEmpty(finalSelectedInventoriesWithBarrels.length - 1);
      }

    } else {
      // Select all filtered barrel numbers
      const selectedBarrelsForCurrentLot = filteredBarrelNumbers.map(
        (barrel) => ({
          barrelNumber: barrel.barrelNumber,
          barrelId: barrel.barrelId,
          row: barrel.row,
          depth: barrel.depth,
          height: barrel.height
        })
      );

      setSelectedBarrels([...filteredBarrelNumbers]);

      // Update finalSelectedInventoriesWithBarrels
      setFinalSelectedInventoriesWithBarrels((prev) => {
        // Remove any previous selection of this lot
        const updatedInventories = prev.filter(
          (inventory) => inventory.lotNo !== lotNo
        );

        //if the inventory attribute is empty then selected the attributes for only private sell and Sale Request
        if((selectedDsp == '' || selectedBarrelInventoryId == '') && (selectedServiceName == 'Private Sell' || selectedServiceName == 'Sell Request' || selectedServiceName == 'Ownership Transfer')) {
          updateAttributesIfEmpty(dsp, batchId, mashBill,inventoryId);
        }

        setOwnerName(ownerName);

        // Add the updated selection for this lot
        return [
          ...updatedInventories,
          {
            lotNo: lotNo,
            productName: title,
            barrelNumbers: selectedBarrelsForCurrentLot,
          },
        ];
      });

    }

    
    
  };

  const handleRandomSelection = (value: any) => {
    setDesiredQuantity(value);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout); // Clear any existing debounce timeout
    }
    const timeoutId = setTimeout(() => {
      if (value?.trim().length <= 0) {
        return;
      }

      const quantity = parseInt(value);
      const { lotNo, title, mashBill, batchId, dsp, inventoryId, ownerName} = searchSelectedInventory;

      if (quantity > 0 && quantity <= filteredBarrelNumbers.length) {
        // Take the first 'quantity' barrels
        const randomBarrels = filteredBarrelNumbers.slice(0, quantity).map((barrel) => ({
          barrelNumber: barrel.barrelNumber,
          barrelId: barrel.barrelId,
          row: barrel.row,
          depth: barrel.depth,
          height: barrel.height
        }));

        // Update selected barrels for current UI
        setSelectedBarrels(randomBarrels);

        // Update finalSelectedInventoriesWithBarrels
        setFinalSelectedInventoriesWithBarrels((prev) => {
          // Remove any previous selection of this lot
          const updatedInventories = prev.filter(
            (inventory) => inventory.lotNo !== lotNo
          );


          //if the inventory attribute is empty then selected the attributes for only private sell and Sale Request
          if((selectedDsp == '' || selectedBarrelInventoryId == '') && (selectedServiceName == 'Private Sell' || selectedServiceName == 'Sell Request' || selectedServiceName == 'Ownership Transfer')) {
            updateAttributesIfEmpty(dsp, batchId, mashBill,inventoryId);
          }
          setOwnerName(ownerName);

          // Add the updated selection for this lot
          return [
            ...updatedInventories,
            {
              lotNo: lotNo,
              productName: title,
              barrelNumbers: randomBarrels,
            },
          ];
        });
      } else {
        CommonService.Toast.fire({
          title: `Please enter a number between 1 and ${filteredBarrelNumbers.length}`,
          icon: "error",
        });
      }
    }, 500); // 500ms debounce delay

    setDebounceTimeout(timeoutId); // Save the debounce timeout
  };

  const handleSelectBarrel = (barrelNumber) => {
    // Check if an inventory is selected
    if (!searchSelectedInventory) {
      CommonService.Toast.fire({
        title: "Please select an inventory first",
        icon: "error",
      });
      return;
    }
  
    const { lotNo, title, mashBill, batchId, dsp, inventoryId, ownerName } =
      searchSelectedInventory;
  
    // Find the selected barrel details
    const selectedBarrel = filteredBarrelNumbers.find(
      (barrel) => barrel.barrelNumber === barrelNumber
    );
  
    if (!selectedBarrel) {
      CommonService.Toast.fire({
        title: "Selected barrel not found in the current inventory",
        icon: "error",
      });
      return;
    }
  
    const { barrelId, row, depth, height } = selectedBarrel;
  
    // Find the selected inventory in the final selected inventories
    const existingInventoryIndex = finalSelectedInventoriesWithBarrels.findIndex(
      (inv) => inv.lotNo === lotNo
    );
  
    if (existingInventoryIndex !== -1) {
      // Inventory already exists, update the selected barrels
      setFinalSelectedInventoriesWithBarrels((prev) => {
        const updatedInventories = [...prev];
        const existingInventory = updatedInventories[existingInventoryIndex];
  
        // Check if the barrel is already selected
        const isBarrelSelected = existingInventory.barrelNumbers.some(
          (barrel) => barrel.barrelNumber === barrelNumber
        );
  
        if (isBarrelSelected) {
          // Barrel is already selected, remove it
          existingInventory.barrelNumbers = existingInventory.barrelNumbers.filter(
            (barrel) => barrel.barrelNumber !== barrelNumber
          );
  
          // If no barrels are left after removal, remove the entire inventory entry
          if (existingInventory.barrelNumbers.length === 0) {
            if (
              selectedServiceName === "Private Sell" ||
              selectedServiceName === "Sell Request" ||
              selectedServiceName === "Ownership Transfer"
            ) {
              resetAttributesIfEmpty(finalSelectedInventoriesWithBarrels.length - 1);
            }
            updatedInventories.splice(existingInventoryIndex, 1);
          }
        } else {
          // Barrel is not selected, add it
          existingInventory.barrelNumbers.push({
            barrelNumber,
            barrelId,
            row,
            depth,
            height,
          });
  
          // If the inventory attributes are empty, update them for certain services
          if (
            (selectedDsp === "" || selectedBarrelInventoryId === "") &&
            (selectedServiceName === "Private Sell" ||
              selectedServiceName === "Sell Request" ||
              selectedServiceName === "Ownership Transfer")
          ) {
            updateAttributesIfEmpty(dsp, batchId, mashBill, inventoryId);
          }
          setOwnerName(ownerName);
        }
  
        return updatedInventories;
      });
    } else {
      // Inventory does not exist, create a new one
      const newInventorySelection = {
        lotNo: lotNo,
        productName: title,
        barrelNumbers: [
          {
            barrelNumber,
            barrelId,
            row,
            depth,
            height,
          },
        ],
      };
  
      setFinalSelectedInventoriesWithBarrels((prev) => [
        ...prev,
        newInventorySelection,
      ]);
  
      // If the inventory attributes are empty, update them for certain services
      if (
        (selectedDsp === "" || selectedBarrelInventoryId === "") &&
        (selectedServiceName === "Private Sell" ||
          selectedServiceName === "Sell Request" ||
          selectedServiceName === "Ownership Transfer")
      ) {
        updateAttributesIfEmpty(dsp, batchId, mashBill, inventoryId);
      }

      setOwnerName(ownerName);
    }
  };
  

  //update inventory attributes like dsp, mash bill and batch id
  const updateAttributesIfEmpty = (dsp, batchId, mashBill,inventoryId) => {

    if(selectedServiceName == 'Private Sell' || selectedServiceName == 'Sell Request') {
      setSelectedDsp(dsp);
      setSelectedMashBill(mashBill);
      setSelectedBatchId(batchId);
     
    } else if(selectedServiceName == 'Ownership Transfer') {
      setSelectedBarrelInventoryId(inventoryId);

    }
  };

  //update inventory attributes like dsb, mash bill and batch id when user unselect all the barrels
  const resetAttributesIfEmpty = (length:any) => {


    if(length == 0 && (selectedServiceName == 'Private Sell' || selectedServiceName == 'Sell Request')) {
      setSelectedDsp('');
      setSelectedMashBill('');
      setSelectedBatchId('');
      setOwnerName('')
    }

    else if (length == 0 && selectedServiceName == 'Ownership Transfer') {
      setSelectedBarrelInventoryId(null);
      setOwnerName('');
    }
  }

  // Handle Done button click to add to final selection
  const handleDoneSelection = () => {
    // Reset states
    setSearchSelectedInventory(null);
    setSearchedInventories([]);
    setSelectedBarrels([]);
    setSearchTerm("");
    setBarrelSearchTerm("");
    setDesiredQuantity("");
    setIsDoneButtonClicked(true);
  };

  // Remove an inventory from the final selection
  const handleRemoveInventory = (lotNo) => {
    const updatedFinalSelectedInventoriesWithBarrels =
      finalSelectedInventoriesWithBarrels.filter((inv) => inv.lotNo !== lotNo);
    setFinalSelectedInventoriesWithBarrels(
      updatedFinalSelectedInventoriesWithBarrels
    );

    resetAttributesIfEmpty(finalSelectedInventoriesWithBarrels.length -1);
  };

  const handleDeleteFile = (indexToDelete: number) => {
    setFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToDelete)
    );
  };

  const handleSingleDeleteFile = (fieldId) => {
    setFile(null); // Remove the file
    formData[fieldId] = '';
  };

  const handleDeleteDoc = async (id:any) => {
    //call the api and delete this doc
    try {
    const response = await SupportApiService.deleteCustomerServiceRequestDoc(id);
    // fetchCustomerServiceRequestDocsForEditing();
    } catch (error) {
      console.log("error occurred while api calling" + error);
    }
  }

  const handleDeleteSignatureFile = () => {
    setSignatureFile(null);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      // Replace old files with new ones
      const newFiles = Array.from(event.target.files);
      setFiles(newFiles);

      // Clear the input value to allow selecting the same file again
      event.target.value = "";
    }
  };
  

  const handleSingleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldId: string
  ) => {
    setErrors((prev) => ({ ...prev, [fieldId]: "" }));
  
    if (event.target.files && event.target.files.length > 0) {
      const newFile = event.target.files[0]; // Access the first file
  
      // Allowed file extensions
      const allowedExtensions = [".csv", ".xlsx"];
      const fileName = newFile.name.toLowerCase();
      const isAllowed = allowedExtensions.some((ext) => fileName.endsWith(ext));
  
      if (!isAllowed) {
        // Show error message and reset input
        setErrors((prev) => ({
          ...prev,
          [fieldId]: "Invalid file type. Only .csv and .xlsx files are allowed.",
        }));
        event.target.value = ""; // Reset input field
        return;
      }
  
      // File is valid, set it
      setFile(newFile);
      setFormData((prevData) => ({
        ...prevData,
        [fieldId]: newFile,
      }));
  
      // Clear input value to allow selecting the same file again
      event.target.value = "";
    }
  };
  

  const handleSignatureFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      // Replace old files with new ones
      setSignatureFile(event.target.files[0]);

      // Clear the input value to allow selecting the same file again
      event.target.value = "";
    }
  };

  const highlightMatchedForBarrels = (text: string, searchTerm: string) => {
    if (!searchTerm) return text;
  
    const lowerText = text.toLowerCase();
    const lowerSearchTerm = searchTerm.toLowerCase();
    const matchIndex = lowerText.indexOf(lowerSearchTerm);
  
    if (matchIndex === -1) return text;
  
    return (
      <>
        {text.slice(0, matchIndex)}
        <strong style={{fontWeight:"900"}}>
          {text.slice(matchIndex, matchIndex + searchTerm.length)}
        </strong>
        {text.slice(matchIndex + searchTerm.length)}
      </>
    );
  };

const getButtonLabel = () => {
  if (selectedServiceName === "Bulk Inventory Upload" && CommonService.isAdmin()) {
    if (id) {
      return "Update";
    } else if (isLoading) {
      return "Validating";
    } else {
      return "Upload";
    }
  }

  if (selectedServiceName === "Ownership Transfer" && CommonService.isAdmin()) {
    return id ? "Update" : "Transfer";
  }

  if (selectedServiceName === "Sell Request" && CommonService.isAdmin()) {
    return id ? "Update" : "Publish";
  }

  return id ? "Update" : "Submit";
};
  
  const renderField = (field) => {
    switch (field.dataType) {
      case "company_dropdown":
        return (
          <div className={` ${
            (selectedServiceName === "Ship Barrels")
              ? "col-sm-6 col-md-4"
              : "col-sm-6 col-lg-4"
          }`}>
           
            <div className="form__field--wrapper">
              <label htmlFor="service-dropdown" className="form-label">
                {field.fieldName}
                {field.mandatory && (
                  <span className="star-required text-danger">*</span>
                )}
              </label>
              <div className="w-100">
                <select
                  id="company-dropdown"
                  value={formData[field.serviceTypeServiceFieldId]}
                  onChange={(e) =>
                    handleCompanyChange(
                      e.target.value,
                      field.serviceTypeServiceFieldId
                    )
                  }
                  onBlur={() =>
                    handleCompanyBlur(field.serviceTypeServiceFieldId)
                  }
                  className={`select-dropdown w-100 ${
                    field.mandatory && errors[field.serviceTypeServiceFieldId]
                      ? "is_invalid"
                      : ""
                  }`}
                >
                  <option value="">Select Company</option>
                  {companiesList.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.companyName}
                    </option>
                  ))}
                </select>
              </div>

              {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
                <span className="error-message ">Please select a company</span>
              )}
            </div>
          </div>
        );
      case "user_dropdown":
        return (
          <div className="col-sm-6 col-lg-4">
            <div className="mb-6 form__field--wrapper">
              <label
                htmlFor="service-dropdown"
                className="block mb-2 form-label"
              >
                {field.fieldName}
                {field.mandatory && (
                  <span className="star-required text-danger">*</span>
                )}
              </label>
              <div className="w-100">
                <select
                  id="company-dropdown"
                  value={formData[field.serviceTypeServiceFieldId]}
                  onChange={(e) =>
                    handleUserChange(
                      e.target.value,
                      field.serviceTypeServiceFieldId
                    )
                  }
                  onBlur={() => handleUserBlur(field.serviceTypeServiceFieldId)}
                  className={`select-dropdown w-100 ${
                    field.mandatory && errors[field.serviceTypeServiceFieldId]
                      ? "is_invalid"
                      : ""
                  }`}
                >
                  <option value="">Select User</option>
                  {usersList.map((user) => (
                    <option key={user.userId} value={user.userId}>
                      {user.userName}
                    </option>
                  ))}
                </select>
              </div>

              {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
                <span className="error-message ">Please select a user </span>
              )}
            </div>
          </div>
        );
      case "add_inventory":
      case "add_barrel":
        return (
          <div className="col-12">
            <div key={field.fieldName} className="form__field--wrapper">
              {/* Field Label */}
              <label className="block mb-2 form-label">
                {field.fieldName}
                {field.mandatory && (
                  <span className="star-required text-danger">*</span>
                )}
              </label>
              <div className="position-relative">
                {/* Search Input */}
                <SearchIcon
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "15px",
                    transform: "translateY(-50%)",
                  }}
                />
                <input
                  type="text"
                  value={searchTerm}
                  placeholder="Search by Product Name, Lot ID or Barrel Number"
                  onChange={(e) =>
                    handleSearchInventory(
                      e.target.value,
                      field.serviceTypeServiceFieldId
                    )
                  }
                  className={`form-control service-req-input add-inventory ${
                    field.mandatory && errors[field.serviceTypeServiceFieldId]
                      ? "is_invalid"
                      : ""
                  }`}
                />
                {isSearch && (
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "15px",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <Spinner />
                  </div>
                )}

               
                  <div className="position-absolute top-100 left-0 right-0 bottom-0 w-100">
                   {/* {!isSearch && isInventoryNotFound && !errors[field.serviceTypeServiceFieldId] && (<span>No inventory found</span>)} */}
                  {(searchedInventories.length > 0 || isInventoryNotFound) && (<div className="serched-inventory-modal row m-0 w-100 bg-white">
                      <div
                        className={`px-0 ${
                          !searchSelectedInventory ? "col-lg-12" : "col-lg-3"
                        }`}
                      >
                   {!isSearch && isInventoryNotFound && !errors[field.serviceTypeServiceFieldId] && (<div className= "p-2 support-no-record-found">
                    <span>No barrel found</span>
                    </div>)}
                   
                   {searchedInventories.length > 0 && (<div className="list-wrapper">
                          {searchedInventories.filter((inventory) => {
      // Apply filtering logic based on selectedMashBill, selectedDsp, and selectedBatchId
      const matchMashBill = !selectedMashBill || inventory.mashBill === selectedMashBill;
      const matchDsp = !selectedDsp || inventory.dsp === selectedDsp;
      const matchBatchId = !selectedBatchId || inventory.batchId === selectedBatchId;
      const matchInventoryId = !selectedBarrelInventoryId || inventory?.inventoryId === selectedBarrelInventoryId;
      return matchMashBill && matchDsp && matchBatchId && matchInventoryId;

      
    }).map((inventory, index) => (
                            <div
                              key={index}
                              className={`list-item-wrapper ${
                                selectedInventory === inventory ? "" : ""
                              }`}
                              onClick={() =>
                                setSearchSelectedInventory(inventory)
                              }
                              style={{
                                backgroundColor:
                                  searchSelectedInventory != inventory
                                    ? "#f0f0f0"
                                    : "#fff",
                              }}
                            >
                              <p className="list-inventory-title">
                              {highlightMatchedForBarrels(inventory.title, searchTerm)}{" "}
          <span className="list-lot-no">
            ({highlightMatchedForBarrels(inventory.lotNo, searchTerm)})
          </span>
                              </p>
                              {finalSelectedInventoriesWithBarrels.find(
                                (inv) =>
                                  inv.lotNo === inventory.lotNo &&
                                  inv.barrelNumbers.length > 0
                              )?.barrelNumbers.length > 0 && (
                                <span className="barrel-count" style={{
                                  backgroundColor:
                                    searchSelectedInventory != inventory
                                      ? "#fff"
                                      : "#f0f0f0",
                                }}>
                                  {
                                    finalSelectedInventoriesWithBarrels.find(
                                      (inv) =>
                                        inv.lotNo === inventory.lotNo &&
                                        inv.barrelNumbers.length > 0
                                    )?.barrelNumbers.length
                                  }
                                </span>
                              )}
                            </div>
                          ))}
                        </div>)}
                      </div>
                      {searchSelectedInventory && (
                        <div className="col-lg-9 px-0">
                          {/* Barrel Numbers Display */}

                          <div className="searched-inventory-modal--right">
                            <div className="right-header d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between">
                              {/* <div> */}
                              {/* Barrel Search and Random Selection */}
                              <div className="search-input-wrapper position-relative">
                                {/* Search Input */}
                                <SearchIcon
                                  sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "15px",
                                    transform: "translateY(-50%)",
                                  }}
                                />
                                <input
                                  type="text"
                                  placeholder="Search barrel numbers"
                                  value={barrelSearchTerm}
                                  onChange={(e) =>
                                    setBarrelSearchTerm(e.target.value)
                                  }
                                  className="form-control flex-grow"
                                />
                              </div>
                              <div className="d-flex align-items-center gap-4 ms-auto">
                                {/* Select All Checkbox */}
                                <div className="header-field-wrapper">
                                  <input
                                    type="checkbox"
                                    id="select-all-barrels"
                                    checked={
                                      finalSelectedInventoriesWithBarrels.find(
                                        (inv) =>
                                          inv.lotNo ===
                                          searchSelectedInventory.lotNo
                                      )?.barrelNumbers.length ===
                                      filteredBarrelNumbers.length
                                    }
                                    onChange={handleSelectAllBarrels}
                                    className=""
                                  />
                                  {/* <label htmlFor="select-all-barrels" className="text-sm">
                      Select All ({filteredBarrelNumbers.length} barrels)
                    </label> */}
                                  <label
                                    htmlFor="select-all-barrels"
                                    className="header-field-label"
                                  >
                                    Select All
                                  </label>
                                </div>
                                <div className="header-field-wrapper d-flex align-items-center gap-2">
                                  <label className="header-field-label text-nowrap">
                                    Selected Quantity:
                                  </label>
                                  <div style={{ width: "70px" }}>
                                    <input
                                      type="number"
                                      // placeholder="Qty"
                                      value={desiredQuantity}
                                      onChange={(e) =>
                                        handleRandomSelection(e.target.value)
                                      }
                                      className="form-control"
                                      min="1"
                                    />
                                  </div>
                                </div>
                                {/* </div> */}
                              </div>
                            </div>

                            {/* Barrel Numbers List */}
                            <div className="barrel-list-wrapper ">
                              <div className="d-flex flex-wrap align-items-center gap-2">
                                {filteredBarrelNumbers.map((barrel, index) => (
                                  <div
                                    key={index}
                                    className={`inventery-barrel-wrapper ${
                                      finalSelectedInventoriesWithBarrels
                                        .find(
                                          (inv) =>
                                            inv.lotNo ===
                                            searchSelectedInventory.lotNo
                                        )
                                        ?.barrelNumbers.some(
                                          (selectedBarrel) =>
                                            selectedBarrel.barrelNumber ===
                                            barrel.barrelNumber
                                        )
                                        ? "bg-green-100 border-green-300"
                                        : "bg-gray-50 hover:bg-gray-100"
                                    }`}
                                  >
                                    {/* <div className="d-flex align-items-center gap-2 "> */}

                                    <span
                                      onClick={() =>
                                        handleSelectBarrel(barrel.barrelNumber)
                                      }
                                      className="barrel-number--inner d-flex align-items-center gap-1"
                                      style={{
                                        backgroundColor:
                                          finalSelectedInventoriesWithBarrels
                                            .find(
                                              (inv) =>
                                                inv.lotNo ===
                                                searchSelectedInventory.lotNo
                                            )
                                            ?.barrelNumbers.some(
                                              (selectedBarrel) =>
                                                selectedBarrel.barrelNumber ===
                                                barrel.barrelNumber
                                            )
                                            ? "transparent"
                                            : "#F7F7F7",
                                        borderColor:
                                          finalSelectedInventoriesWithBarrels
                                            .find(
                                              (inv) =>
                                                inv.lotNo ===
                                                searchSelectedInventory.lotNo
                                            )
                                            ?.barrelNumbers.some(
                                              (selectedBarrel) =>
                                                selectedBarrel.barrelNumber ===
                                                barrel.barrelNumber
                                            )
                                            ? "#8F9195"
                                            : "transparent",
                                      }}
                                    >
                                      {finalSelectedInventoriesWithBarrels
                                        .find(
                                          (inv) =>
                                            inv.lotNo ===
                                            searchSelectedInventory.lotNo
                                        )
                                        ?.barrelNumbers.some(
                                          (selectedBarrel) =>
                                            selectedBarrel.barrelNumber ===
                                            barrel.barrelNumber
                                        ) ? (
                                        <DoneIcon />
                                      ) : (
                                        <AddSharpIcon sx={{ mr: 0.5 }} />
                                      )}
                                      <span className="barrel-number">
                                      {highlightMatchedForBarrels(barrel.barrelNumber, barrelSearchTerm)}
                                      </span>
                                    </span>
                                    {/* </div> */}
                                  </div>
                                ))}
                              </div>
                            </div>
                            <div className="modal-footer-wrapper">
                              <button
                                onClick={handleDoneSelection}
                                className="done-btn"
                                // disabled={
                                //   !finalSelectedInventoriesWithBarrels.find(
                                //     (inv) =>
                                //       inv.lotId ===
                                //       searchSelectedInventory.lotNo
                                //   )?.barrelNumbers.length
                                // }
                              >
                                <DoneIcon className="me-1" />
                                Done
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>)}
                  </div>
                
              </div>
              {/* Final Selected Barrels Display */}
              {isDoneButtonClicked &&
                finalSelectedInventoriesWithBarrels.length > 0 && (
                  <div className="selected-inventory-detail-wrapper">
                    {/* <div className="header-wrapper d-flex align-items-center">
                  <h3 className="detail-heading">
                    Selected Inventory Details
                  </h3>
                  <div className="d-flex align-items-center">
                    <p className="total-quantity">Quantity: <span>80</span></p>
                    <button className="remove-btn">Remove</button>
                  </div>
                  </div> */}
                    {finalSelectedInventoriesWithBarrels.map(
                      (inventory, index) => (
                        <div key={index} className="selected-inventory-detail-inner">
                          {/* <div className="font-semibold text-gray-800">
                          Lot No: {inventory.lotId}
                        </div> */}
                          <div className="header-wrapper d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between">
                            <div className="detail-heading">
                              {inventory.productName}{" "}
                              <span className="font-semibold text-gray-800">
                                ({inventory.lotNo})
                              </span>
                            </div>
                            <div className="d-flex align-items-center gap-2 ms-auto">
                              <div className="total-quantity">
                                Quantity:
                                <span> {inventory.barrelNumbers.length}</span>
                              </div>
                              <button
                                className="remove-btn"
                                onClick={() =>
                                  handleRemoveInventory(inventory.lotNo)
                                }
                              >
                                {/* <RemoveIcon className="me-1" /> */}
                                <img
                              src={TrashIcon}
                              alt="TrashIcon"
                                className="me-1"
                            />
                                 Remove Item
                              </button>
                            </div>
                          </div>
                          <div className="total-barrel-list-wrapper">
                            <div className="bareels--list-wrapper">
                              <div className="d-flex flex-wrap gap-2 align-items-center mt-2">
                                {inventory.barrelNumbers.map(
                                  (barrel, barrelIndex) => (
                                    <div
                                      key={barrelIndex}
                                      className=""
                                    >
                                      <div className="barrel-item-wrapper d-flex align-items-center gap-1 w-100">
                                        <DoneIcon />
                                        <span>{barrel.barrelNumber}</span>
                                        {/* <span className=" ">
                                  (ID: {barrel.inventoryId})
                                </span> */}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                )}
              {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
                <span className="error-message">This field is required</span>
              )}

              {/* Searched Inventories Display */}

              {/* Barrel Numbers Display */}

              {/* Final Selected Barrels Display */}
            </div>
          </div>
        );

 
          return (
            <div className="col-12">
              <div key={field.fieldName} className="form__field--wrapper">
                {/* Field Label */}
                <label className="block mb-2 form-label">
                  {field.fieldName}
                  {field.mandatory && (
                    <span className="star-required text-danger">*</span>
                  )}
                </label>
                <div className="position-relative">
                  {/* Search Input */}
                  <SearchIcon
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "15px",
                      transform: "translateY(-50%)",
                    }}
                  />
                  <input
                    type="text"
                    value={searchTerm}
                    placeholder="Search by Inventory Title, Lot ID or Barrel number"
                    onChange={(e) =>
                      handleSearchInventory(
                        e.target.value,
                        field.serviceTypeServiceFieldId
                      )
                    }
                    className={`form-control service-req-input add-inventory ${
                      field.mandatory && errors[field.serviceTypeServiceFieldId]
                        ? "is_invalid"
                        : ""
                    }`}
                  />
                  {isSearch && (
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "15px",
                        transform: "translateY(-50%)",
                      }}
                    >
                      <Spinner />
                    </div>
                  )}
  
                 
                    <div className="position-absolute top-100 left-0 right-0 bottom-0 w-100">
                     {/* {!isSearch && isInventoryNotFound && !errors[field.serviceTypeServiceFieldId] && (<span>No inventory found</span>)} */}
                    {(searchedInventories.length > 0 || isInventoryNotFound) && (<div className="serched-inventory-modal row m-0 w-100 bg-white">
                        <div
                          className={`px-0 ${
                            !searchSelectedInventory ? "col-lg-12" : "col-lg-3"
                          }`}
                        >
                     {!isSearch && isInventoryNotFound && !errors[field.serviceTypeServiceFieldId] && (<div className= "p-2 support-no-record-found">
                      <span>No inventory found</span>
                      </div>)}
                     
                     {searchedInventories.length > 0 && (<div className="list-wrapper">
                            {searchedInventories.filter((inventory) => {
        // Apply filtering logic based on selectedMashBill, selectedDsp, and selectedBatchId
        const matchMashBill = !selectedMashBill || inventory.mashBill === selectedMashBill;
        const matchDsp = !selectedDsp || inventory.dsp === selectedDsp;
        const matchBatchId = !selectedBatchId || inventory.batchId === selectedBatchId;
        const matchInventoryId = !selectedBarrelInventoryId || inventory?.inventoryId === selectedBarrelInventoryId;
        return matchMashBill && matchDsp && matchBatchId && matchInventoryId;
  
        
      }).map((inventory, index) => (
                              <div
                                key={index}
                                className={`list-item-wrapper ${
                                  selectedInventory === inventory ? "" : ""
                                }`}
                                onClick={() =>
                                  setSearchSelectedInventory(inventory)
                                }
                                style={{
                                  backgroundColor:
                                    searchSelectedInventory != inventory
                                      ? "#f0f0f0"
                                      : "#fff",
                                }}
                              >
                                <p className="list-inventory-title">
                                {highlightMatchedForBarrels(inventory.title, searchTerm)}{" "}
            <span className="list-lot-no">
              ({highlightMatchedForBarrels(inventory.lotNo, searchTerm)})
            </span>
                                </p>
                                {finalSelectedInventoriesWithBarrels.find(
                                  (inv) =>
                                    inv.lotNo === inventory.lotNo &&
                                    inv.barrelNumbers.length > 0
                                )?.barrelNumbers.length > 0 && (
                                  <span className="barrel-count" style={{
                                    backgroundColor:
                                      searchSelectedInventory != inventory
                                        ? "#fff"
                                        : "#f0f0f0",
                                  }}>
                                    {
                                      finalSelectedInventoriesWithBarrels.find(
                                        (inv) =>
                                          inv.lotNo === inventory.lotNo &&
                                          inv.barrelNumbers.length > 0
                                      )?.barrelNumbers.length
                                    }
                                  </span>
                                )}
                              </div>
                            ))}
                          </div>)}
                        </div>
                        {searchSelectedInventory && (
                          <div className="col-lg-9 px-0">
                            {/* Barrel Numbers Display */}
  
                            <div className="searched-inventory-modal--right">
                              <div className="right-header d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between">
                                {/* <div> */}
                                {/* Barrel Search and Random Selection */}
                                <div className="search-input-wrapper position-relative">
                                  {/* Search Input */}
                                  <SearchIcon
                                    sx={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "15px",
                                      transform: "translateY(-50%)",
                                    }}
                                  />
                                  <input
                                    type="text"
                                    placeholder="Search barrel numbers"
                                    value={barrelSearchTerm}
                                    onChange={(e) =>
                                      setBarrelSearchTerm(e.target.value)
                                    }
                                    className="form-control flex-grow"
                                  />
                                </div>
                                <div className="d-flex align-items-center gap-4 ms-auto">
                                  {/* Select All Checkbox */}
                                  <div className="header-field-wrapper">
                                    <input
                                      type="checkbox"
                                      id="select-all-barrels"
                                      checked={
                                        finalSelectedInventoriesWithBarrels.find(
                                          (inv) =>
                                            inv.lotNo ===
                                            searchSelectedInventory.lotNo
                                        )?.barrelNumbers.length ===
                                        filteredBarrelNumbers.length
                                      }
                                      onChange={handleSelectAllBarrels}
                                      className=""
                                    />
                                    {/* <label htmlFor="select-all-barrels" className="text-sm">
                        Select All ({filteredBarrelNumbers.length} barrels)
                      </label> */}
                                    <label
                                      htmlFor="select-all-barrels"
                                      className="header-field-label"
                                    >
                                      Select All
                                    </label>
                                  </div>
                                  <div className="header-field-wrapper d-flex align-items-center gap-2">
                                    <label className="header-field-label text-nowrap">
                                      Selected Quantity:
                                    </label>
                                    <div style={{ width: "70px" }}>
                                      <input
                                        type="number"
                                        // placeholder="Qty"
                                        value={desiredQuantity}
                                        onChange={(e) =>
                                          handleRandomSelection(e.target.value)
                                        }
                                        className="form-control"
                                        min="1"
                                      />
                                    </div>
                                  </div>
                                  {/* </div> */}
                                </div>
                              </div>
  
                              {/* Barrel Numbers List */}
                              <div className="barrel-list-wrapper ">
                                <div className="d-flex align-items-center flex-wrap gap-2">
                                  {filteredBarrelNumbers.map((barrel, index) => (
                                    <div
                                      key={index}
                                      className={`inventery-barrel-wrapper  ${
                                        finalSelectedInventoriesWithBarrels
                                          .find(
                                            (inv) =>
                                              inv.lotNo ===
                                              searchSelectedInventory.lotNo
                                          )
                                          ?.barrelNumbers.some(
                                            (selectedBarrel) =>
                                              selectedBarrel.barrelNumber ===
                                              barrel.barrelNumber
                                          )
                                          ? "bg-green-100 border-green-300"
                                          : "bg-gray-50 hover:bg-gray-100"
                                      }`}
                                    >
                                      {/* <div className="d-flex align-items-center gap-2 "> */}
  
                                      <span
                                        onClick={() =>
                                          handleSelectBarrel(barrel.barrelNumber)
                                        }
                                        className="barrel-number--inner d-flex align-items-center gap-1"
                                        style={{
                                          backgroundColor:
                                            finalSelectedInventoriesWithBarrels
                                              .find(
                                                (inv) =>
                                                  inv.lotNo ===
                                                  searchSelectedInventory.lotNo
                                              )
                                              ?.barrelNumbers.some(
                                                (selectedBarrel) =>
                                                  selectedBarrel.barrelNumber ===
                                                  barrel.barrelNumber
                                              )
                                              ? "transparent"
                                              : "#F7F7F7",
                                          borderColor:
                                            finalSelectedInventoriesWithBarrels
                                              .find(
                                                (inv) =>
                                                  inv.lotNo ===
                                                  searchSelectedInventory.lotNo
                                              )
                                              ?.barrelNumbers.some(
                                                (selectedBarrel) =>
                                                  selectedBarrel.barrelNumber ===
                                                  barrel.barrelNumber
                                              )
                                              ? "#8F9195"
                                              : "transparent",
                                        }}
                                      >
                                        {finalSelectedInventoriesWithBarrels
                                          .find(
                                            (inv) =>
                                              inv.lotNo ===
                                              searchSelectedInventory.lotNo
                                          )
                                          ?.barrelNumbers.some(
                                            (selectedBarrel) =>
                                              selectedBarrel.barrelNumber ===
                                              barrel.barrelNumber
                                          ) ? (
                                          <DoneIcon />
                                        ) : (
                                          <AddSharpIcon sx={{ mr: 0.5 }} />
                                        )}
                                        <span className="barrel-number">
                                        {highlightMatchedForBarrels(barrel.barrelNumber, barrelSearchTerm)}
                                        </span>
                                      </span>
                                      {/* </div> */}
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div className="modal-footer-wrapper">
                                <button
                                  onClick={handleDoneSelection}
                                  className="done-btn"
                                  // disabled={
                                  //   !finalSelectedInventoriesWithBarrels.find(
                                  //     (inv) =>
                                  //       inv.lotId ===
                                  //       searchSelectedInventory.lotNo
                                  //   )?.barrelNumbers.length
                                  // }
                                >
                                  <DoneIcon className="me-1" />
                                  Done
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>)}
                    </div>
                  
                </div>
                {/* Final Selected Barrels Display */}
                {isDoneButtonClicked &&
                  finalSelectedInventoriesWithBarrels.length > 0 && (
                    <div className="selected-inventory-detail-wrapper">
                      {/* <div className="header-wrapper d-flex align-items-center">
                    <h3 className="detail-heading">
                      Selected Inventory Details
                    </h3>
                    <div className="d-flex align-items-center">
                      <p className="total-quantity">Quantity: <span>80</span></p>
                      <button className="remove-btn">Remove</button>
                    </div>
                    </div> */}
                      {finalSelectedInventoriesWithBarrels.map(
                        (inventory, index) => (
                          <div key={index} className="selected-inventory-detail-inner">
                            {/* <div className="font-semibold text-gray-800">
                            Lot No: {inventory.lotId}
                          </div> */}
                            <div className="header-wrapper d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between">
                              <div className="detail-heading">
                                {inventory.productName}{" "}
                                <span className="font-semibold text-gray-800">
                                  ({inventory.lotNo})
                                </span>
                              </div>
                              <div className="d-flex align-items-center gap-2 ms-auto">
                                <div className="total-quantity">
                                  Quantity:
                                  <span> {inventory.barrelNumbers.length}</span>
                                </div>
                                <span
                                  className="remove-btn"
                                  onClick={() =>
                                    handleRemoveInventory(inventory.lotNo)
                                  }
                                >
                                  <RemoveIcon className="me-1" /> Remove
                                </span>
                              </div>
                            </div>
                            <div className="total-barrel-list-wrapper">
                              <div className="bareels--list-wrapper">
                                <div className="row m-0 g-2">
                                  {inventory.barrelNumbers.map(
                                    (barrel, barrelIndex) => (
                                      <div
                                        key={barrelIndex}
                                        className="col-sm-6 col-lg-4"
                                      >
                                        <div className="barrel-item-wrapper d-flex align-items-center gap-1 w-100">
                                          <DoneIcon />
                                          <span>{barrel.barrelNumber}</span>
                                          {/* <span className=" ">
                                    (ID: {barrel.inventoryId})
                                  </span> */}
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  )}
                {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
                  <span className="error-message">This field is required</span>
                )}
  
                {/* Searched Inventories Display */}
  
                {/* Barrel Numbers Display */}
  
                {/* Final Selected Barrels Display */}
              </div>
            </div>
          );
      case "product_dropdown":
        return (
          <div className="mb-6 form__field--wrapper">
            <label htmlFor="service-dropdown" className="block mb-2 form-label">
              {field.fieldName}
              {field.mandatory && (
                <span className="star-required text-danger">*</span>
              )}
            </label>
            <select
              id="company-dropdown"
              value={selectedInventory}
              onChange={(e) =>
                handleInventoryChange(
                  e.target.value,
                  field.serviceTypeServiceFieldId
                )
              }
              onBlur={() =>
                handleInventoryBlur(field.serviceTypeServiceFieldId)
              }
              className={`select-dropdown ${
                field.mandatory && errors[field.serviceTypeServiceFieldId]
                  ? "is_invalid"
                  : ""
              }`}
            >
              <option value="">Select Product</option>
              {inventoriesList.map((inventory) => (
                <option
                  key={inventory.barrelId}
                  value={inventory.barrelId }
                >
                  {inventory?.inventoryName}
                </option>
              ))}
            </select>
            {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
              <span className="error-message ">Please select a product</span>
            )}
          </div>
        );
      case "fee_type_dropdown":
        if (CommonService.isAdmin()) {
          return (
            <div className="col-sm-6 col-lg-4">
            <div className="mb-6 form__field--wrapper">
              <label htmlFor="service-dropdown" className="block mb-2 form-label">
                {field.fieldName}
                {field.mandatory && (
                  <span className="star-required text-danger">*</span>
                )}
              </label>
              <div className="w-100">
              <select
                id="fee-type-dropdown"
                style={{ cursor: "pointer" }}
                value={formData[field.serviceTypeServiceFieldId] || ""}
                onChange={(e) =>
                  handleFieldChange(
                    e.target.value,
                    field.serviceTypeServiceFieldId
                  )
                }
                onBlur={(e) =>
                  handleFieldBlur(e.target.value, field.serviceTypeServiceFieldId)
                }
                className={`select-dropdown w-100 ${
                  field.mandatory && errors[field.serviceTypeServiceFieldId]
                    ? "is_invalid"
                    : ""
                }`}
              >
                <option value="">Select Fee Type</option>
                <option value="FLAT">Flat</option>
                <option value="PERCENTAGE">Percentage</option>

              </select>
              </div>
              {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
                <span className="error-message ">Please select a fee type</span>
              )}
            </div>
            </div>
          );
        }
        return null;
      case "price":
        return (
          <div key={field.fieldName} className="col-sm-6 col-lg-4">
            <div className="form__field--wrapper">
            <label className="block mb-2 form-label">
              {field.fieldName}
              {field.mandatory && (
                <span className="star-required text-danger">*</span>
              )}
            </label>
            <input
              type="number"
              value={price}
              onChange={(e) =>
                handlePriceChange(
                  e.target.value,
                  field.serviceTypeServiceFieldId
                )
              }
              onBlur={(e) =>
                handleNumberOnBlur(
                  e.target.value,
                  field.serviceTypeServiceFieldId
                )
              }
              className={`form-control service-req-input no-spinner ${
                field.mandatory && errors[field.serviceTypeServiceFieldId]
                  ? "is_invalid"
                  : ""
              }`}
            />

            {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
              <span className="error-message ">This field is required </span>
            )}
          </div>
          </div>
        );
      case "quantity":
        return (
          <div key={field.fieldName} className="mb-4 form__field--wrapper">
            <label className="block mb-2 form-label">
              {field.fieldName}
              {field.mandatory && (
                <span className="star-required text-danger">*</span>
              )}
            </label>
            <input
              type="number"
              value={quantity}
              onChange={(e) =>
                handleQuantityChange(
                  e.target.value,
                  field.serviceTypeServiceFieldId
                )
              }
              onBlur={(e) =>
                handleNumberOnBlur(
                  e.target.value,
                  field.serviceTypeServiceFieldId
                )
              }
              className={`form-control service-req-input ${
                field.mandatory && errors[field.serviceTypeServiceFieldId]
                  ? "is_invalid"
                  : ""
              }`}
            />
            {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
              <span className="error-message ">This field is required </span>
            )}
          </div>
        );
      case "number":
        if (field.fieldName != 'Fee' || CommonService.isAdmin()) {
            return (
      <div key={field.fieldName} className="col-sm-6 col-lg-4">
        <div className="form__field--wrapper">
          <label className="block mb-2 form-label">
            {field.fieldName == 'Price' ? `${field.fieldName}/Barrel` : field.fieldName}
            {field.mandatory && (
              <span className="star-required text-danger">*</span>
            )}
          </label>
          <input
            type="number"
            value={formData[field.serviceTypeServiceFieldId] || ""}
            onChange={(e) =>
              handleFieldChange(
                e.target.value,
                field.serviceTypeServiceFieldId
              )
            }
            onBlur={(e) =>
              handleNumberOnBlur(
                e.target.value,
                field.serviceTypeServiceFieldId
              )
            }
            className={`form-control service-req-input no-spinner ${
              field.mandatory && errors[field.serviceTypeServiceFieldId]
                ? "is_invalid"
                : ""
            }`}
            placeholder={`Enter ${field.fieldName}`}
          />
          {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
            <span className="error-message">This field is required</span>
          )}
        </div>
      </div>
      );
          }
      return null;
      case "radio":
        return (
          <div key={field.fieldName} className="col-sm-6 col-lg-4">
            <div className="form__field--wrapper"> 
              <div className="d-flex flex-column gap-3">
            <label className="block form-label mb-0">
              {field.fieldName}
              {field.mandatory && (
                <span className="star-required text-danger">*</span>
              )}
            </label>

            <div className="d-flex align-items-center">
            <div className="d-inline-flex">
                <input
                  type="radio"
                  name={field.fieldName}
                  id={`${field.fieldName}-yes`}
                  className="custom-radio service-req-input"
                  value="yes"
                  checked={formData[field.serviceTypeServiceFieldId] === true}
                  onChange={(e) =>
                    handleFieldChange(
                      e.target.value,
                      field.serviceTypeServiceFieldId
                    )
                  }
                />
                <label
                  className="custom-radio-label"
                  htmlFor={`${field.fieldName}-yes`}
                >
                  Yes
                </label>
              </div>
              <div className="d-inline-flex ms-2 me-2">
                <input
                  type="radio"
                  name={field.fieldName}
                  id={`${field.fieldName}-no`}
                  className="custom-radio service-req-input"
                  value="no"
                  checked={formData[field.serviceTypeServiceFieldId] === false}
                  onChange={(e) =>
                    handleFieldChange(
                      e.target.value,
                      field.serviceTypeServiceFieldId
                    )
                  }
                />
                <label
                  className="custom-radio-label"
                  htmlFor={`${field.fieldName}-no`}
                >
                  No
                </label>
              </div>
              
            </div>
          </div>
          {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
                <span className="error-message">This field is required </span>
              )}
          </div>
          </div>
        );
      case "text":
        return (
          <div key={field.fieldName}  className={` ${
            (selectedServiceName === "Ship Barrels")
              ? "col-sm-6 col-md-4"
              : "col-sm-6 col-lg-4"
          }`}>
            <div className="form__field--wrapper">
            <label className="block mb-2 form-label">
              {field.fieldName}
              {field.mandatory && (
                <span className="star-required text-danger">*</span>
              )}
            </label>
            <input
              type="text"
              placeholder={`Enter ${field.fieldName}`}
              value={formData[field.serviceTypeServiceFieldId]}
              onChange={(e) =>
                handleFieldChange(
                  e.target.value,
                  field.serviceTypeServiceFieldId
                )
              }
              onBlur={(e) =>
                handleFieldBlur(e.target.value, field.serviceTypeServiceFieldId)
              }
              className={`form-control service-req-input ${
                field.mandatory && errors[field.serviceTypeServiceFieldId]
                  ? "is_invalid"
                  : ""
              }`}
              disabled={isDisabled}
            />
            {/* Error message for "Owner Name" */}
            {field.fieldName === "Owner Name" && ownerNameError && (
                <span className="error-message text-danger">{ownerNameError}</span>
              )}
            {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
              <span className="error-message ">This field is required </span>
            )}
          </div>
          </div>
        );
      case "comment":
        return (
          <div key={field.fieldName} className={` ${
            (selectedServiceName === "Ownership Transfer" || selectedServiceName === "Tasting" || selectedServiceName === "Private Sell")
              ? (selectedServiceName === "Private Sell" ? "col-sm-12 col-xl-9" : "col-sm-12 col-lg-6") 
              : ((selectedServiceName === "Bottling" || selectedServiceName === "Blending") ? "col-md-8 col-lg-9 col-xl-12" :(selectedServiceName === "Ship Barrels" ? "col-md-8 col-lg-9 col-xl-4" : (selectedServiceName === "Dumping" ? "col-md-12 col-xl-9" : (selectedServiceName === "Sell Request" ? "col-md-6" : "col-md-8 col-lg-9")))
        )}`} >
            <div className="form__field--wrapper">
              <label className="block mb-2 form-label">
                {field.fieldName}
                {field.mandatory && (
                  <span className="star-required text-danger">*</span>
                )}
              </label>
              <textarea
                value={requestDescription}
                placeholder="Enter Text"
                onChange={(e) =>
                  handleRequestDescriptionChange(
                    e.target.value,
                    field.serviceTypeServiceFieldId
                  )
                }
                className={`form-control service-req-input ${
                  field.mandatory && errors[field.serviceTypeServiceFieldId]
                    ? "is_invalid"
                    : ""
                }`}
                rows={3}
                style={{ resize: "none" }}
              />
              {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
                <span className="error-message ">This field is required </span>
              )}
            </div>
          </div>
        );
      // case "textarea":
      //   return (
      //     <div key={field.fieldName} className="col-sm-6 col-md-4">
      //       <div className="form__field--wrapper">
      //       <label className="block mb-2 form-label">
      //         {field.fieldName}
      //         {field.mandatory && (
      //           <span className="star-required text-danger">*</span>
      //         )}
      //       </label>
      //       <textarea
      //         value={formData[field.serviceTypeServiceFieldId]}
      //         placeholder={field.fieldName}
      //         onChange={(e) =>
      //           handleFieldChange(
      //             e.target.value,
      //             field.serviceTypeServiceFieldId
      //           )
      //         }
      //         onBlur={(e) =>
      //           handleFieldBlur(e.target.value, field.serviceTypeServiceFieldId)
      //         }
      //         className={`form-control service-req-input ${
      //           field.mandatory && errors[field.serviceTypeServiceFieldId]
      //             ? "is_invalid"
      //             : ""
      //         }`}
      //         rows={3}
      //         style={{ resize: "none" }}
      //       />
      //       {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
      //         <span className="error-message ">This field is required </span>
      //       )}
      //     </div>
      //     </div>
      //   );

      case "textarea":
        return (
          <div key={field.fieldName} className="col-sm-12 col-lg-12">
      <div className="form__field--wrapper">
        <label className="block mb-2 form-label">
          {field.fieldName}
          {field.mandatory && (
            <span className="star-required text-danger">*</span>
          )}
        </label>
        <CKEditor
           editor={ ClassicEditor }
          data={formData[field.serviceTypeServiceFieldId] || ''}
          onInit={ editor => {
            // You can store the "editor" and use when it is needed.
            console.log( 'Editor is ready to use!', editor.state );
          } }
          // data = "<p>Hello from CKEditor 5!</p>"
          config={{
            toolbar: {
              items: [
                'bold', 
                'italic', 
                'underline',
                '|',
                'bulletedList', 
                'numberedList',
                '|',
                'undo', 
                'redo'
              ]
            },
            // Ensure these plugins are not removed
            removePlugins: ['MediaEmbed', 'Link', 'ImageUpload', 'BlockQuote', 'Table']
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            handleCkeditorContentChange(
              data,
              field.serviceTypeServiceFieldId
            );
          }}
          onBlur={(event, editor) => {
            const data = editor.getData();
            handleFieldBlur(
              data,
              field.serviceTypeServiceFieldId
            );
          }}
        />
        {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
          <span className="error-message">This field is required</span>
        )}
      </div>
    </div>
        );

      case "date":
        return (
          <div key={field.fieldName} className="col-sm-6 col-lg-4">
            <div className=" form__field--wrapper">
              <label className="block mb-2 form-label">
                {field.fieldName}
                {field.mandatory && (
                  <span className="star-required text-danger">*</span>
                )}
              </label>
              <input
                type="date"
                value={formData[field.serviceTypeServiceFieldId]}
                onChange={(e) =>
                  handleFieldChange(
                    e.target.value,
                    field.serviceTypeServiceFieldId
                  )
                }
                onBlur={(e) =>
                  handleFieldBlur(
                    e.target.value,
                    field.serviceTypeServiceFieldId
                  )
                }
                className={`form-control service-req-input ${
                  field.mandatory && errors[field.serviceTypeServiceFieldId]
                    ? "is_invalid"
                    : ""
                }`}
                name="date"
              />
              {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
                <span className="error-message ">This field is required </span>
              )}
            </div>
          </div>
        );
      case "upload_file":
        return (
          <>
            {!isEditMode && ( // Hide the entire block in edit mode
              <div
                className={`${selectedServiceName === "Ship Barrels"
                    ? "col-sm-6 col-md-4 col-lg-4"
                    : "col-sm-6 col-lg-4"
                  }`}
              >
                <div className="">
                  <div className="create-ticket-attachement-wrapper d-flex flex-wrap align-items-center gap-1">
                    <IconButton
                      type="button"
                      aria-label="submit"
                      sx={{
                        transform: "rotate(45deg)",
                        padding: "0",
                      }}
                      onClick={handleSingleButtonClick}
                    >
                      <AttachFileIcon />
                    </IconButton>
                    <span className="cursor-pointer" onClick={handleSingleButtonClick}>
                      {field.fieldName}
                    </span>
                    {field.mandatory && (
                      <span className="star-required text-danger">*</span>
                    )}
                  </div>
                  <div className="d-flex align-items-center gap-2 flex-wrap mt-2">
                      {file && (
                        <div className="file__upload--info d-flex align-items-center mb-1 me-2">
                          <div className="file-name me-3">{file.name}</div>
                          <div className="file-delete">
                            <img
                              src={TrashIcon}
                              alt="TrashIcon"
                              onClick={() => handleSingleDeleteFile(field.serviceTypeServiceFieldId)}
                            />
                          </div>
                        </div>
                      )}

                      {/* if user or admin edits the service request, show already uploaded file */}
                      {id &&
                        serviceDocDetails?.map((serviceDoc, index) => (
                          <div
                            className="file__upload--info d-flex align-items-center mb-1 me-2"
                            key={index}
                          >
                            <div className="file-name me-3">
                              {serviceDoc?.fileName.split("_").slice(1).join("_")}
                            </div>
                            <div className="file-delete">
                              <img
                                src={TrashIcon}
                                alt="TrashIcon"
                                onClick={() => handleDeleteDoc(serviceDoc?.id)}
                              />
                            </div>
                          </div>
                        ))}
                    </div>
                    <div style={{ position: "relative" }}>
                      <input
                        type="file"
                        ref={singleFileInputRef}
                        style={{ display: "none" }}
                        accept=".csv, .xls, .xlsx"
                        onChange={(e) =>
                          handleSingleFileChange(e, field.serviceTypeServiceFieldId)
                        }
                      />
                      {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
                        <span className="error-message ">Please select csv or xlsx file.</span>
                      )}
                    </div>
                </div>
              </div>
            )}
          </>
        );
      case "upload_doc":
        return (
          <>
            {!isEditMode && ( // Hide the entire block in edit mode
              <div
                className={`${selectedServiceName === "Ship Barrels"
                    ? "col-sm-6 col-md-4 col-lg-4"
                    : "col-sm-6 col-lg-4"
                  }`}
              >
                <div className="">
                  <div className="create-ticket-attachement-wrapper d-flex flex-wrap align-items-center gap-1">
                    <IconButton
                      type="button"
                      aria-label="submit"
                      sx={{
                        transform: "rotate(45deg)",
                        padding: "0",
                      }}
                      onClick={handleButtonClick}
                    >
                      <AttachFileIcon />
                    </IconButton>
                    <span className="cursor-pointer" onClick={handleButtonClick}>
                      {field.fieldName}
                    </span>
                    {field.mandatory && (
                      <span className="star-required text-danger">*</span>
                    )}
                  </div>
                  <div className="d-flex align-items-center gap-2 flex-wrap mt-2">
                      {files?.map((file, index) => (
                        <div
                          className="file__upload--info d-flex align-items-center mb-1 me-2"
                          key={index}
                        >
                          <div className="file-name me-3">{file.name}</div>
                          <div className="file-delete">
                            <img
                              src={TrashIcon}
                              alt="TrashIcon"
                              onClick={() => handleDeleteFile(index)}
                            />
                          </div>
                        </div>
                      ))}

                      {/* Show already uploaded files if user/admin edits */}
                      {id &&
                        serviceDocDetails?.map((serviceDoc, index) => (
                          <div
                            className="file__upload--info d-flex align-items-center mb-1 me-2"
                            key={index}
                          >
                            <div className="file-name me-3">
                              {serviceDoc?.fileName.split("_").slice(1).join("_")}
                            </div>
                            <div className="file-delete">
                              <img
                                src={TrashIcon}
                                alt="TrashIcon"
                                onClick={() => handleDeleteDoc(serviceDoc?.id)}
                              />
                            </div>
                          </div>
                        ))}
                    </div>
                    <div style={{ position: "relative" }}>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        multiple
                        onChange={handleFileChange}
                      />
                    </div>
                </div>
              </div>
            )}
          </>
        );
      case "current_user":
        return (
          <div className="col-sm-6 col-lg-4">
            <div className="form__field--wrapper">
              <label
                htmlFor="service-dropdown"
                className="block mb-2 form-label"
              >
                {field.fieldName}
                {field.mandatory && (
                  <span className="star-required text-danger">*</span>
                )}
              </label>
              <div className="w-100">
                <select
                  id="company-dropdown"
                  value={formData[field.serviceTypeServiceFieldId]}
                  onChange={(e) =>
                    handleCurrentUserChange(
                      e.target.value,
                      field.serviceTypeServiceFieldId
                    )
                  }
                  // onBlur = {() => handleUserBlur(field.serviceTypeServiceFieldId)}
                  className={`select-dropdown w-100 ${field.mandatory && errors[field.serviceTypeServiceFieldId]
                    ? "is_invalid"
                    : ""
                    }`}
                >
                  <option>Current user</option>
                  {currentCompaniesUserList.map((user) => (
                    <option key={user.userId} value={user.userId}>
                      {user.userName}
                    </option>
                  ))}
                </select>
              </div>
              {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
                <span className="error-message ">Please select a user </span>
              )}
            </div>
          </div>
        );
      case "current_company":
        return (
          <div key={field.fieldName} className="col-sm-6 col-lg-4">
            <div className="form__field--wrapper">
              <label
                htmlFor="service-dropdown"
                className="block mb-2 form-label"
              >
                {field.fieldName}
                {field.mandatory && (
                  <span className="star-required text-danger">*</span>
                )}
              </label>
              <div className="w-100">
                <select
                  id="company-dropdown"
                  value={formData[field.serviceTypeServiceFieldId]}
                  // onBlur = {() => handleUserBlur(field.serviceTypeServiceFieldId)}
                  className={`select-dropdown w-100 ${
                    field.mandatory && errors[field.serviceTypeServiceFieldId]
                      ? "is_invalid"
                      : ""
                  }`}
                  disabled={true}
                >
                  <option>Current company</option>
                   {filteredCompaniesList.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.companyName}
                    </option>
                  ))}
                </select>
                {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
                <span className="error-message ">Please select a current company </span>
              )}
              </div>
             
            </div>
          </div>
        );
      case "size_dropdown":
        return (
          <div key={field.fieldName} className="col-6">
          <div className="form__field--wrapper">
          <label htmlFor="size" className="form-label">
              {field.fieldName}
              {field.mandatory && (
                <span className="star-required text-danger">*</span>
              )}
            </label>
            <div className="col">
              <select
                id="size"
                className={`form-control ${
                  field.mandatory && errors[field.serviceTypeServiceFieldId]
                    ? "is_invalid"
                    : ""
                }`}
                style={{ cursor: "pointer" }}
                value={formData[field.serviceTypeServiceFieldId] || ""}
                onChange={(e) =>
                  handleFieldChange(
                    e.target.value,
                    field.serviceTypeServiceFieldId
                  )
                }
              >
                <option value="">Select Size</option>
                <option value="750mL">750mL</option>
                <option value="200mL">200mL</option>
                <option value="100mL">100mL</option>
              </select>
              {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
                <span className="error-message ">This field is required </span>
              )}
            </div>
          </div>
          </div>
        );
      case "purpose_dropdown":
        return (
          <div key={field.fieldName} className="col-6">
          <div className="form__field--wrapper">
          <label htmlFor="size" className="mb-2 form-label">
              {field.fieldName}
              {field.mandatory && (
                <span className="star-required text-danger">*</span>
              )}
            </label>
            <div className="col">
              <select
                id="purpose"
                style={{ cursor: "pointer" }}
                className={`form-control ${
                  field.mandatory && errors[field.serviceTypeServiceFieldId]
                    ? "is_invalid"
                    : ""
                }`}
                value={formData[field.serviceTypeServiceFieldId] || ""}
                onChange={(e) =>
                  handleFieldChange(
                    e.target.value,
                    field.serviceTypeServiceFieldId
                  )
                }
              >
                <option value="">Select Purpose</option>
                <option value="Taste Profile Analysis">
                  Taste Profile Analysis
                </option>
                <option value="Product Testing">Product Testing</option>
                <option value="Quantity Testing">Quantity Testing</option>
              </select>
              {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
                <span className="error-message ">This field is required </span>
              )}
            </div>
          </div>
          </div>
        );
      case "contact_number":
        return (
          <div key={field.fieldName}  className={` ${
            (selectedServiceName === "Ship Barrels")
              ? "col-sm-6 col-md-4"
              : "col-sm-6"
          }`}>
            <div className="form__field--wrapper">
            <label className="block mb-2 form-label">
              {field.fieldName}
              {field.mandatory && (
                <span className="star-required text-danger">*</span>
              )}
            </label>
              <input
              type="number"
              placeholder={field.fieldName}
              value={formData[field.serviceTypeServiceFieldId]}
              onChange={(e) =>
                handleFieldChange(
                  e.target.value,
                  field.serviceTypeServiceFieldId
                )
              }
              onBlur={(e) =>
                handleContactBlur(e.target.value, field.serviceTypeServiceFieldId)
              }
              className={`form-control service-req-input no-spinner ${
                field.mandatory && errors[field.serviceTypeServiceFieldId]
                  ? "is_invalid"
                  : ""
              }`}
              
            />
            {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
              <span className="error-message ">{errors[field.serviceTypeServiceFieldId]}</span>
            )}
          </div>
          </div>
        );
      case "email" :
          return (
            <div key={field.fieldName}  className={` ${
              (selectedServiceName === "Ship Barrels")
                ? "col-sm-6 col-md-4"
                : "col-sm-6"
            }`}>
              <div className="form__field--wrapper">
              <label className="block mb-2 form-label">
                {field.fieldName}
                {field.mandatory && (
                  <span className="star-required text-danger">*</span>
                )}
              </label>
                <input
                type="email"
                placeholder={field.fieldName}
                value={formData[field.serviceTypeServiceFieldId]}
                onChange={(e) =>
                  handleFieldChange(
                    e.target.value,
                    field.serviceTypeServiceFieldId
                  )
                }
                onBlur={(e) =>
                  handleEmailBlur(e.target.value, field.serviceTypeServiceFieldId)
                }
                className={`form-control service-req-input ${
                  field.mandatory && errors[field.serviceTypeServiceFieldId]
                    ? "is_invalid"
                    : ""
                }`}
                
              />
              {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
                <span className="error-message ">{errors[field.serviceTypeServiceFieldId]} </span>
              )}
            </div>
            </div>
          );
      case "private_buyer_org":
        return (
          <div key={field.fieldName} className='col-sm-6'>
            <div className="form__field--wrapper position-relative">
              <label className="block mb-2 form-label">
                {field.fieldName}
                {field.mandatory && (
                  <span className="star-required text-danger">*</span>
                )}
              </label>
              <div>
                <input
                  type="text"
                  placeholder={field.fieldName}
                  value={formData[field.serviceTypeServiceFieldId]}
                  onChange={(e) => {
                    handleOptionChange(
                      'COMPANY',
                      e.target.value,
                      field.serviceTypeServiceFieldId
                    )
                  }}
                  className={`form-control service-req-input ${field.mandatory && errors[field.serviceTypeServiceFieldId]
                    ? "is_invalid"
                    : ""
                    }`}
                />
                {showCompanyDropdown && filteredCompanyOptions.length > 0 && (
                  <ul className="private-sell-dropdown" 
                  >
                    {filteredCompanyOptions.map(option => (
                      <li
                        key={option.id}
                        onClick={() => handleOptionClick('COMPANY', option.id, option.companyName, field.serviceTypeServiceFieldId)}
                        className="private-sell-dropdown-item"
                      >
                        {option.companyName}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
                
                   <span className="error-message ">
                    {errors[field.serviceTypeServiceFieldId] === 'error' && 'This field is required'}
                    {errors[field.serviceTypeServiceFieldId] !== 'error' && errors[field.serviceTypeServiceFieldId]}
                  </span>
                
              )}
            </div>
          </div>
        );
      case "private_buyer_user":
        return (
          <div className="col-sm-6 col-lg-4">
            <div className="form__field--wrapper position-relative">
              <label
                htmlFor="service-dropdown"
                className="block mb-2 form-label"
              >
                {field.fieldName}
                {field.mandatory && (
                  <span className="star-required text-danger">*</span>
                )}
              </label>
              <div>
                <input
                  type="text"
                  placeholder={field.fieldName}
                  value={formData[field.serviceTypeServiceFieldId]}
                  onChange={(e) => {
                    handleOptionChange(
                      'USER',
                      e.target.value,
                      field.serviceTypeServiceFieldId
                    )
                  }}
                  className={`form-control service-req-input ${field.mandatory && errors[field.serviceTypeServiceFieldId]
                    ? "is_invalid"
                    : ""
                    }`}
                />
                {showUserDropdown && filteredUserOptions.length > 0 && (
                  <ul className="private-sell-dropdown">
                    {filteredUserOptions.map(option => (
                      <li
                        key={option.userId}
                        onClick={() => handleOptionClick('USER', option.userId, option.userName, field.serviceTypeServiceFieldId)}
                        className="private-sell-dropdown-item"
                      >
                        {option.userName}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
                <span className="error-message ">
                    {errors[field.serviceTypeServiceFieldId] === 'error' && 'Please select a user'}
                    {errors[field.serviceTypeServiceFieldId] !== 'error' && errors[field.serviceTypeServiceFieldId]}
                </span>
              )}
            </div>
          </div>
        );
      case "commission_fee":
        return (
          <div key={field.fieldName} className="col-sm-6 col-lg-4">
            <div className="form__field--wrapper">
            <label className="block mb-2 form-label">
              {field.fieldName} ($)/Barrel
              {field.mandatory && (
                <span className="star-required text-danger">*</span>
              )}
            </label>
            <input
              type="number"
              value={formData[field.serviceTypeServiceFieldId] || ""}
              placeholder="Enter commission fee"
              onChange={(e) =>
                handleFieldChange(
                  e.target.value,
                  field.serviceTypeServiceFieldId
                )
              }
              onBlur={(e) =>
                handleSellRequestFeeOnBlur(
                  e.target.value,
                  field.serviceTypeServiceFieldId
                )
              }
              className={`form-control service-req-input no-spinner ${
                field.mandatory && errors[field.serviceTypeServiceFieldId]
                  ? "is_invalid"
                  : ""
              }`}
            />

            {field.mandatory && errors[field.serviceTypeServiceFieldId] && (
              <span className="error-message ">This field is required </span>
            )}
          </div>
          </div>
        );
    }
  };


  // const loadSupportRequests = async (status: string) => {
  //   try {
  //     setIsLoading(true);
  //     const response = await Service.getAllSupportRequests(
  //       CommonService.isUser() ? CommonService.getCompanyId() : null,
  //       status
  //     );
  //     setSupportRequests(response.data);

  //     if (response.data.length > 0) setSelectedRequest(response?.data[0]);

  //     setIsLoading(false);
  //   } catch (error) {
  //     setIsLoading(false);
  //     console.error("Error fetching support requests:", error);
  //   }
  // };

  const getClosedRequestCount = async () => {
    try {
      const response = await Service.getCloseSupportRequestCount(
        CommonService.isUser() ? CommonService.getCompanyId() : null
      );
      setClosedRequestCount(response.data);
    } catch (error) {
      console.error("Error fetching closed request count:", error);
    }
  };

  const getOpenRequestCount = async () => {
    try {
      const response = await Service.getOpenSupportRequestCount(
        CommonService.isUser() ? CommonService.getCompanyId() : null
      );
      setOpenRequestCount(response.data);
    } catch (error) {
      console.error("Error fetching open request count:", error);
    }
  };

  const handleTabChange = (newValue: string) => {
    setActiveTab(newValue);
    setSelectedRequest(null);
    if (newValue !== "create") {
      setNewTicket(false);
      // loadSupportRequests(newValue.toUpperCase());
    }
  };

  // Filter the list whenever `search` changes
  const filteredItems = useMemo(
    () =>
      supportRequests.filter(
        (item) =>
          item.companyName.toLowerCase().includes(search.toLowerCase()) ||
          item.latestComment.toLowerCase().includes(search.toLowerCase()) ||
          item.name.toLowerCase().includes(search.toLowerCase())
      ),
    [supportRequests, search]
  );

  return (
    <div className="group-chat-wrapper support-main-wrapper pt-0">
           {/* {CommonService.isAdmin() && ( */}
            <div className="d-flex align-items-center justify-content-between p-3">
            <div className="d-flex align-items-center gap-2">
      <div className="back__button" onClick={() => history.goBack()}>
      {CommonService.isAdmin() && ( <IconButton
            aria-label="ArrowBackIcon"
            sx={{ backgroundColor: "#FFF" }}
          >
            <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
          </IconButton>)}
        </div><Typography
          variant="h1"
          gutterBottom
          sx={{
            fontSize: "24px",
            fontWeight: 700,
            color: "#242424",
            lineHeight: "22px",
          }}
        >
          {id ? "Update Customer Service Request" : "Create New Request"}
            
          </Typography>
        </div>
        {/* <div>
          <button className="support-faq-btn" onClick={handleShowFaq}>
          FAQ’s
          </button>
        </div> */}
        </div>
        {/* )} */}

      {/* <div className="d-flex"> */}
      <div className="group-chat-container service--req-wrapper position-relative" style={{minHeight:CommonService.isAdmin() ? "calc(100vh - 167px)" :"calc(100vh - 384px)"}}>
        {viewFaq &&(<SupportFaqPanel handleCloseFaq={handleCloseFaq}/>)}

        {/* <div className="col-md-6 col-lg-5 col-xl-4 mb-3 mb-md-0 px-0 px-sm-2">
            <div
              className="group-list-wrapper"
              style={{
                height: isNewTicket === false ? "805px" : "initial",
                minHeight: CommonService.isAdmin() ? "895px" : "550px",
              }}
            >
              <nav className="group-chat-nav-wrapper d-flex align-items-end justify-content-between w-100 position-sticky top-0">
                <div
                  className="nav nav-tabs justify-content-between mt-3 w-100 px-2 px-sm-3"
                  id="nav-tab"
                  role="tablist"
                >
                  <div className="d-flex">
                    <button
                      onClick={() => handleTabChange("open")}
                      className={`nav-link ${
                        activeTab === "open" ? "active" : ""
                      }`}
                      id="nav-all-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-all"
                      type="button"
                      role="tab"
                      aria-controls="nav-all"
                      aria-selected={activeTab === "open"}
                    >
                      {`Open (${openRequestCount})`}
                    </button>
                    <button
                      onClick={() => handleTabChange("close")}
                      className={`nav-link ${
                        activeTab === "close" ? "active" : ""
                      }`}
                      id="nav-warehouse-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-warehouse"
                      type="button"
                      role="tab"
                      aria-controls="nav-warehouse"
                      aria-selected={activeTab === "close"}
                    >
                      {`Close (${closedRequestCount})`}
                    </button>
                  </div>
                  {CommonService.isUser() && (
                    <button
                      onClick={() => {
                        setSelectedRequest(null);
                        setNewTicket(true);
                        setComment("");
                        setFiles(null);
                      }}
                      className="create-ticket-btn mb-2"
                      title="Create Ticket"
                    >
                      <AddIcon />
                      Create Ticket
                    </button>
                  )}
                </div>
              </nav>
              <div className="position-sticky ">
                <div className="position-relative mt-3 px-2 px-sm-3">
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    className="chat-search mr-sm-2 pl"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <SearchIcon
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "25px",
                      transform: "translateY(-50%)",
                    }}
                  />
                </div>
              </div>
              <div className="text-muted">
                <hr />
              </div>

              <ListGroup className="px-2 px-sm-3">
                {filteredItems?.map((request, index) => (
                  <ListGroup.Item
                    className="cursor-pointer"
                    onClick={() => {
                      setNewTicket(false);
                      setSelectedRequest(request);
                    }}
                  >
                    <div
                      className="chat-info-wrapper py-2 px-2"
                      style={{
                        backgroundColor:
                          selectedRequest?.supportRequestId ===
                          request.supportRequestId
                            ? "white"
                            : "initial",
                      }}
                    >
                      <div className="row m-0">
                        <div className="col-12 px-0">
                          <div className="d-flex justify-content-between">
                            <p className="request-name mb-0">{request?.name}</p>
                            <p className="request-ticketnumber mb-0">{`#${request?.requestNumber}`}</p>
                          </div>
                        </div>
                        <div className="col-12 px-0 mt-2">
                          <div className="row">
                            <div className="col-8">
                              <p className="msg-preview mb-0">
                                {request.latestComment}
                              </p>
                            </div>
                            <div className="col-4">
                              <div className="time__info d-flex flex-column text-end lh-base ms-auto">
                                <div className="date ">
                                  <p className="mb-1">
                                    {CommonService.formatDateForSupportRequest(
                                      request.createdDate
                                    )}
                                  </p>
                                  <p className="mb-0">
                                    {CommonService.formatTimeForSupportRequest(
                                      request.createdDate
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>

              {filteredItems.length <= 0 && (
                <NoTicketCreated
                  openRequestCount={openRequestCount}
                  closeRequestCount={closedRequestCount}
                />
              )}
            </div>
          </div> */}
        {/* <div className=""> */}
        <div className="container">
          {/* {selectedRequest && !isNewTicket && (
                <>
                  <WebSocketProvider
                    requestId={selectedRequest?.supportRequestId}
                    sellerCompanyId={null}
                  >
                    <div className="message-list-header d-flex align-items-center justify-content-between flex-wrap gap-2">
                      <div className="d-flex align-items-center">
                        <Typography
                          variant="subtitle1"
                          sx={{
                            display: "inline",
                            fontWeight: 600,
                            fontSize: "18px",
                            color: "#333333",
                            lineHeight: "23.94px",
                          }}
                        >
                          Ticket #{selectedRequest?.requestNumber}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            display: "inline",
                            fontWeight: 700,
                            fontSize: "18px",
                            lineHeight: "23.94px",
                            marginLeft: "10px",
                          }}
                        >
                          {selectedRequest.name}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            display: "inline",
                            ml: 1,
                            fontWeight: 400,
                            fontSize: "16px",
                            color: "#333333",
                            lineHeight: "21.28px",
                          }}
                        >
                          | {selectedRequest.companyName}
                        </Typography>
                      </div>
                      <div className="ms-auto">
                        {selectedRequest.status == "OPEN" ? (
                          <button
                            className="close-btn d-flex align-items-center"
                            onClick={() => {
                              updateSupportStatus(
                                selectedRequest.supportRequestId,
                                "CLOSE"
                              );
                              setActiveTab("close");
                            }}
                            title="Close Ticket"
                          >
                            <CloseIcon /> Close
                          </button>
                        ) : (
                          <button
                            className="reopen-btn d-flex align-items-center"
                            onClick={() => {
                              updateSupportStatus(
                                selectedRequest.supportRequestId,
                                "OPEN"
                              );
                              setActiveTab("open");
                            }}
                            title="Reopen Ticket"
                          >
                            <ReplayIcon />
                            Re-open
                          </button>
                        )}
                      </div>
                      {/* Anderson Smith |  Max Inc 
                    </div>
                    <div className="message-list-card">
                      <SupportCommentBox
                        supportRequestId={selectedRequest.supportRequestId}
                        status={selectedRequest.status}
                      />
                    </div>
                  </WebSocketProvider>
                </>
              )}
              {!selectedRequest && !isNewTicket && !isLoading && (
                <div className="support-faq-wrapper">
                  <p className="heading">FAQ</p>
                  <FAQList />
                </div>
              )} */}

          {isNewTicket && (
            <div className="service-req--inner">
              {/* <div className="service-header">
                <h3 className="heading mb-3">New Service Request</h3>
              </div> */}

              <div className="service-req-form-wrapper">
                <form onSubmit={handleSubmit} className="service-req-form">
                  <div className=" form__field--wrapper">
                    <div className="row m-0">
                      <div className="col-lg-4">
                        {/* <div className="col-4"> */}
                        <label
                          htmlFor="service-dropdown"
                          className="form-label"
                        >
                          Select a Request
                          <span className="star-required text-danger">*</span>
                        </label>
                        <div className="w-100">
                          <select
                            id="service-dropdown"
                            value={selectedService}
                            onChange={(e) => {
                              const selectedServiceId = e.target.value;
                              const selectedServiceObject = services.find(service => service.id === selectedServiceId);
                              handleServiceChange(selectedServiceId, selectedServiceObject?.serviceName);
                            }}
                            className="select-dropdown w-100"
                            required
                            disabled = {id ? true : false}
                          >
                            <option value="">Select</option>
                            {services.map((service) => (
                              <option key={service.id} value={service.id}>
                                {service.serviceName}
                              </option>
                            ))}
                          </select>
                        </div>
                        {/* </div> */}
                      </div>
                       
                      {CommonService.isAdmin() && isAdminSelectService && (
                        <div className="col-lg-4">
                        {/* <div className="col-4"> */}
                        <label
                          htmlFor="user-company-dropdown"
                          className="form-label"
                        >
                          Select a Company
                          <span className="star-required text-danger">*</span>
                        </label>
                        <div className="w-100">
                          <select
                            id="user-company-dropdown"
                            value={selectedCompany}
                            onChange={(e) => {
                              handleUserCompanyChange(e.target.value);
                            }}
                            className={`select-dropdown w-100 ${
                              selectedCompanyError
                                ? "is_invalid"
                                : ""
                            }`}
                            disabled = {id ? true : false}
                            
                          >
                            <option value="">Select</option>
                            {filteredCompaniesList.map((company) => (
                    <option key={company.id} value={company.id}>
                      {company.companyName}
                    </option>
                  ))}
                          </select>
                        </div>

                        {selectedCompanyError && (
                <span className="error-message ">{selectedCompanyError} </span>
              )}
                      </div>
                      )}

                    </div>
                  </div>

                  {isServiceFieldLoading && (
                    <div className="text-center py-4">Loading...</div>
                  )}
                
                  {!isServiceFieldLoading && fields.length > 0 && (
                    <>
                    <div className="space-y-4 row m-0 g-4">
                      {fields.map((field) => renderField(field))}
                    </div>
                    <div className="req-footer-wrapper px-0 mt-4">
                    {(isValidated && !isLoading) && (<>
                    <div className="error-wrapper">
                    <div className="error-header">
                    <p className="file-error-heading"><WarningRoundedIcon sx={{ color: '#B10E1C' }} />Please fix below  errors and re-upload inventory file</p>
                    <button className="close-error-btn" onClick={handleClose}><CloseIcon />Close</button>
                    </div>
                    <List className="warning-list">
              {validationsList?.map((validation, index) => (
                <React.Fragment key={index}>
                  <ListItem alignItems="flex-start" sx={{ display: 'flex', paddingInline: '0px',paddingRight:"70px" }}>
                    <ListItemAvatar sx={{ minWidth: 'unset', marginTop: 0, marginRight: '2px' }}>
                    </ListItemAvatar>
                    <ListItemText
                      sx={{ margin: 0, display: 'flex', justifyContent: 'space-between' }}
                      primary={<React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          sx={{ color: '#B10E1C', fontWeight: 700, wordBreak: 'break-all',fontSize:15 }}
                        >
                          Row # {validation?.rowNumber} -
                        </Typography>
                        <Typography
                          component="span"
                          variant="body2"
                          sx={{ color: '#B10E1C', fontWeight: 400, marginLeft: '4px',fontSize:15  }}
                        >
                          {validation?.errorMessages}
                        </Typography>
                      </React.Fragment>}
                      secondary={<React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          sx={{ color: '#496673', fontWeight: 400, cursor: 'pointer' }}
                        >
                        </Typography>
                      </React.Fragment>} />
                  </ListItem>
                  <Divider variant="inset" component="li" sx={{ marginLeft: 0,backgroundColor:"#D9D9D9" }} />
                </React.Fragment>
              ))}
            </List>
            </div>
              </>)}
              <div className={`req-footer-inner gap-3 ${isValidated && "mt-3" }`}>
                      <button
                        type="reset"
                        className="secondary-btn"
                        onClick={() => history.goBack()}
                        disabled={isLoading}
                      >
                        Cancel
                      </button>
                      <button
  type="submit"
  className="primary-btn"
  disabled={isLoading}
>
  {isLoading && <Spinner />}
  {getButtonLabel()}
</button>
                      </div>
                    </div>
                    </>
                  )}
                </form>
              </div>
            </div>
          )}
        </div>
        {/* {(isValidated && !isLoading) && (<><List className="warning-list">
                {validationsList?.map((validation, index) => (
                  <React.Fragment key={index}>
                    <ListItem alignItems="flex-start" sx={{ display: 'flex', paddingInline: '0px' }}>
                      <ListItemAvatar sx={{ minWidth: 'unset', marginTop: 0, marginRight: '2px' }}>
                        <WarningRoundedIcon sx={{ color: '#b10e1c' }} />
                      </ListItemAvatar>
                      <ListItemText
                        sx={{ margin: 0, display: 'flex', justifyContent: 'space-between' }}
                        primary={<React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            sx={{ color: '#b10e1c', fontWeight: 600, wordBreak: 'break-all' }}
                          >
                            Row # {validation?.rowNumber} -
                          </Typography>
                          <Typography
                            component="span"
                            variant="body2"
                            sx={{ color: '#b10e1c', fontWeight: 400, marginLeft: '4px' }}
                          >
                            {validation?.errorMessages}
                          </Typography>
                        </React.Fragment>}
                        secondary={<React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            sx={{ color: '#496673', fontWeight: 400, cursor: 'pointer' }}
                          >
                          </Typography>
                        </React.Fragment>} />
                    </ListItem>
                    {index !== companiesList?.length - 1 && (
                      <Divider variant="inset" component="li" sx={{ marginLeft: 0 }} />
                    )}
                  </React.Fragment>
                ))}
              </List>
              
                </>)} */}
        {/* </div> */}
      </div>
      {/* </div> */}
    </div>
  );
};

export default SupportRequest;
