
import { SupportApiService } from "../../Service/SupportApiService";
import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, DropdownButton, Form, Table } from "react-bootstrap";
import "../Dynamic/UserList.scss";
import Accordion from 'react-bootstrap/Accordion';
import { useHistory, useParams } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandMore from '../../assets/images/icons/ExpandMore.svg';
import Swal from "sweetalert2";
import DeleteItemIcon from "../../assets/images/icons/DeleteItem.png";
import DownLoadIcon from '../../assets/images/icons/DownLoadIcon.svg';
import CurrencyFormatter from "../Static/CurrencyFormatter";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";


import {
  Box,
  Grid,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { CookieService } from "../../Service/CookieService";
import ContainerMessageBox from "./ContainerMessageBox";
import UploadDocs from "../popups/uploadDocs";
import "../Dynamic/InventoryCSV.scss";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import Constant from "../../Constant";
import Modal from "react-bootstrap/Modal";
import { CommonService } from "../../Service/CommonService";
import StatusDropdown from "../Common/StatusDropdown";
import Spinner from "../Common/Spinner";
import BootstrapSpinner from 'react-bootstrap/Spinner';
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import EditRequest from "./EditOrderRequest";
import { HistoryStepper } from "../Common/HistoryStepper";
import CloseIcon from "@mui/icons-material/Close";
import AdminMessageBox from "./AdminMessageBox";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { MessageApiService } from "../../Service/MessageApiService";
import TrashIcon from "../../assets/images/icons/Trash_Black.svg";
import "../../sass/responsive.scss";
import EditIcon from '../../assets/images/icons/EditIcon.svg';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import QuestionIcon from '../../assets/images/icons/Question.svg';
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloudArrowUp from "../../assets/images/icons/CloudArrowUp.svg";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { Service } from "../../Service/ApiService";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Divider from '@mui/material/Divider';
import Container from "@mui/material/Container";
import CKContent from "../Common/CKcontent";


const ViewSupportRequest: React.FC = () => {

    const {id} = useParams<{id: string}>();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [isSpinner, setSpinner] = useState(false);
    
    const [isOpenEditSection, setOpenEditSection] = useState(false);
    const [isMessageBoxOpen, setMessageBoxOpen] = useState(false);
    const [showMessageBox, setShowMessageBox] = useState(false);
    const [unreadGroupsCount, setUnreadGroupsCount] = React.useState<number>(0);
    const [unreadMessageCount, setUnreadMessageCount] = React.useState<number>(0);
    const [customerServiceRequest, setCustomerServiceRequest] = useState<any>([]);
    const [customerServiceRequestHistory, setCustomerServiceRequestHistory] = useState<any[]>([]);
    const [customerServiceRequestDocs, setCustomerServiceRequestDocs] = useState([]);
    const [isSellRequestAccepted, setSellRequestAccepted] = useState(false);
    
    const serviceRequestUpdateStatus = useRef({
        customerServiceRequestId: "",
        reason: "",
        status: "",
        spendHourOnRequest: "",
        fee: "",
        feeType: ""
      });

    const [spendHour, setSpendHour]  = useState("");
    const [reason,setReason] = useState(""); 
    const [showFileUploadModal, setShowFileUploadModal] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [validated, setValidated] = useState(false);
    const [isRejected, setIsRejected] = useState(false);
    const [isCancelled, setIsCancelled] = useState(false);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [error, setError] = useState("");
    const [ttbFile, setTTBFile] = useState<File[]>([]);
    const [errorMessage,setErrorMessage] = useState<string>("");
    const [files, setFiles] = useState<File[]>([]);
    const userId = CookieService.getCookie("userId");
    const [validationsList, setValidationsList] = useState<any[]>([]);
    const [isInvited, setIsInvited] = useState(false);
    const [companiesList, setCompaniesList] = useState<any[]>([]);
    const [statusList, setStatusList] = useState([]);
    const [sellRequestFee, setSellRequestFee] = useState('');
    const [sellRequestFeeType, setSellRequestFeeType] = useState('');
    const [feeError, setFeeError] = useState('');
    const [feeTypeError, setFeeTypeError] = useState('');
    const [isSellRequestFeeUpdated, setSellRequestFeeUpdated] = useState(false);


    const handleFileShow = () => setShowFileUploadModal(true);

    const handleClose = () => {
      setShow(false);
      setError("");
      setReason("");
      setFeeError("");
      setFeeTypeError("");
      setSellRequestFee("");
      setSellRequestFeeType("");
      setSpendHour("");
      setSellRequestFeeUpdated(false);
      setIsRejected(false);
      setIsCancelled(false);
      setSellRequestAccepted(false);
    };

    const handleFileClose = () => {
      if (!isLoading) {
        setIsInvited(false);
        setValidated(false);
        setFile(null);
        setTTBFile([]);
        fetchData();
        setShowFileUploadModal(false);
      }
    };

    const handleSellRequestFeeChange = (e) => {
      setFeeError('');
      const value = e.target.value;
      setSellRequestFee(value);
    }

    const handleSellRequestFeeTypeChange = (e) => {
      setFeeTypeError('');
      const value = e.target.value;
      setSellRequestFeeType(value);
    }


    const getAllServiceStatusByServiceTypeId = async (serviceTypeId:any, currentStatus:any) => {
      try {
          const response = await SupportApiService.getAllStatusByServiceTypeId(serviceTypeId, currentStatus);
          setStatusList(response?.data);
      } catch (error) {
          console.log("error occurred while api calling ");
      }
    }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const selectedFile = input.files[0];
      const validTypes = [
        "text/csv",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
      if (validTypes.includes(selectedFile.type)) {
        setFile(selectedFile);
        setValidated(false);
      } else {
        setFile(null);
        setValidated(true);
      }
    }
  };

  const uploadFilesAndDocs = async () => {
      
    console.log("inside submit");
    setValidated(true);
    setIsLoading(true);
    let response;
    
    if (customerServiceRequest.serviceName === Constant.CustomerService.BULK_INVENTORY_UPLOAD ) {
      //Upload document of customer service request request
      response = await uploadCustomerServiceRequestFile(); 
    }

    if (response && !errorMessage) {
      setIsLoading(true);
      setValidated(true);
      //fetchData();
    }
    //setIsLoading(false);
  };

  const fetchCompaniesList = async () => {
      const response: any = await Service.fetchCompaniesList();
      setCompaniesList(response.data);
    };

  const uploadCustomerServiceRequestFile = async() => {
    const serviceRequestData = new FormData();
    let response;
    let customerServiceRequestValueFile = {};

    const fileNames = file.name;

    if (file) {
      serviceRequestData.append("file", file);
    }

    const targetFields = customerServiceRequest.dynamicFields.filter((field) => {
      if (field.fieldName === 'Upload Inventory CSV/Excel File') {
        return typeof field.value === 'string' && (field.value.endsWith('.csv') || field.value.endsWith('.xlsx'));
      }
      if (field.fieldName === 'Are you the owner of uploaded inventories?') {
        return field.value;
      }
      return false;
    });

    customerServiceRequestValueFile = {
      dynamicFields: targetFields || [],
      selectedCompanyId: customerServiceRequest.companyId,
      selectedUserId: customerServiceRequest.userId,
      selectedInventoryId: null,
      selectedServiceId: null,
      customerServiceRequestId : id ? id : null,
      comment: null,
      price: null,
      quantity: null,
      finalSelectedInventoriesWithBarrels: [],
      tempPath:'',
    };

    serviceRequestData.append(
      "param",
      new Blob([JSON.stringify(customerServiceRequestValueFile)], {
        type: "application/json",
      })
    );

    // Log the form data to the console
    setIsLoading(true);
    setValidationsList([]);

    try{
      response = await Service.updateCustomerSupportRequestValue(serviceRequestData);
      if (response) {
          setIsLoading(false);
          setIsInvited(true);
          setValidated(false);
      } else {
        setIsLoading(false);
        CommonService.Toast.fire({
          title: `Request ${id ? "Updated" : "Submitted"} Successfully`,
          icon: "success",
        });
      }
    } catch (error) {
        setIsLoading(false);
        setIsInvited(false);

        if (error.response) {
        // The backend error message should be inside error.response.data.message

        console.log(error?.response?.data?.errorMessage);
        const errorMessage =
          error?.response?.data?.errorMessage || "Failed to upload CSV";

        // Log the error for debugging
        console.log("Error message from server: ", errorMessage);

        // Show the error in a toaster
        if (error?.response?.data?.data != null && errorMessage.includes("Error occurred while validating")) {
          CommonService.Toast.fire({
            title: "Please Upload a valid file",
            icon: "error",
          });

          setValidationsList(error?.response?.data?.data);
          setValidated(true);
        } else if (errorMessage.includes("Invalid Csv File")) {
          CommonService.Toast.fire({
            title: "Please Upload a valid CSV file",
            icon: "error",
          });
        } else if (errorMessage.includes("Excel file")) {
          CommonService.Toast.fire({
            title: "Please Upload a valid excel file",
            icon: "error",
          });
        } else if (errorMessage.includes("UNSUPPORTED_MEDIA_TYPE")) {
          CommonService.Toast.fire({
            title: "Please select a valid inventory file format either CSV or Excel (XLSX). You can add non-CSV or non-Excel files as supporting documents.",
            icon: "error",
          });
        } else {
          CommonService.Toast.fire({
            title: errorMessage,
            icon: "error",
          });
        }
      }
        console.log("error occurred while api calling " + error);
      }
  }
  
  const onDropHandler = (ev: any) => {
    ev.preventDefault();

      let file: any = "";

      if (ev.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        const fileItem = [...ev.dataTransfer.items].find(
          (item: any) => item.kind === "file"
        );
        if (fileItem) {
          file = fileItem.getAsFile();
        }
      } else {
        // Use DataTransfer interface to access the file(s)
        file = ev.dataTransfer.files[0];
      }
      setFile(file);
  };

  const onDragOver = (ev: any) => ev.preventDefault();

  const handleSpendHourChange = (e:any) => {
    setError("");
    setSpendHour(e.target.value);
  }

  const handleReasonChange = (e:any) => {
    setError("");
    setReason(e.target.value);
  }

  const deleteServiceRequest = async () => {
    Swal.fire({
      html: `
        <style>
          .custom-swal .icon-container {
              background-color: #ffebee;
              border-radius: 50%;
              width: 105px;
              height: 105px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 auto 20px;
            }
            .custom-swal .icon-container i {
              color: #ff3e4e;
              font-size: 24px;
            }
            .custom-swal h2 {
              font-size: 20px;
              margin-bottom: 10px;
              margin-top: 20px;
            }
            .custom-swal p {
              font-size: 14px;
              color: #666;
              margin-bottom: 20px;
            }
            .custom-swal .button-container {
              margin-top: 40px;
              display: flex;
              justify-content: center;
              gap: 20px;
            }
            .custom-swal button {
              border: none;
              padding: 10px 50px;
              border-radius: 30px;
              font-size: 14px;
              cursor: pointer;
            }
            .custom-swal .cancel-btn {
              background-color: #e0e0e0;
              color: #333;
            }
            .custom-swal .delete-btn {
              background-color: #ec2323;
              color: white;
            }
        </style>
        <div class="custom-swal">
          <div class="icon-container" id="iconContainer"></div>
          <h2>Are you sure?</h2>
          <p>Remove service request</p>
          <div class="button-container">
            <button class="cancel-btn" id="cancelBtn">Cancel</button>
            <button class="delete-btn" id="deleteBtn">Remove</button>
          </div>
        </div>
      `,
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: true,
      focusCancel: true,
      customClass: {
        popup: "custom-popup",
      },
      backdrop: `rgba(0,0,0,0.4)`,
      width: "500px",
      background: "#f8f8f8",
      didOpen: () => {
        // Add the image after the popup has been rendered
        const iconContainer = Swal.getPopup().querySelector("#iconContainer");
        if (iconContainer) {
          const img = document.createElement("img");
          img.src = DeleteItemIcon;
          img.width = 47;
          img.height = 51;
          iconContainer.appendChild(img);
        }
  
        // Add event listeners to the buttons
        const cancelBtn = Swal.getPopup().querySelector("#cancelBtn");
        const deleteBtn = Swal.getPopup().querySelector("#deleteBtn");
  
        cancelBtn?.addEventListener("click", () => {
          Swal.close();
        });
  
        deleteBtn?.addEventListener("click", async () => {
          console.log("Delete button clicked"); // Debugging log
          try {
            const response = await SupportApiService.deleteCustomerServiceRequest(id);
            console.log("Service request deleted", response.data); // Debugging log
  
            // Check if the response indicates success
            if (response.data ) {
              setTimeout(() => {
                CommonService.Toast.fire({
                  title: "Service request removed successfully",
                  icon: "success",
                });
  
              }, 1000);
              const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";
              history.push(`${linkPrefix}/customer-service`);
            } else {
              throw new Error("Failed to delete service request");
            }
          } catch (error) {
            console.error("Error deleting service request:", error);
          }
  
          Swal.close();
        });
      },
    });
  };
  

    const downloadCustomerServiceRequestDOC = async(item:any) => {
        //hare we need to call the api and download the doc

        const requestDto = {
            id: item.id,
            companyId: item.companyId,
            fileName: item.fileName,
          };
      
          try {
            const response: any = await SupportApiService.downloadCustomerServiceRequestDOC(
              requestDto
            );
            // Assuming response.data is the blob data
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = item.fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
    }

    const fetchCustomerServiceRequestHistory = async () => {
        //fetch all the history for service request

        try {
            const response = await SupportApiService.fetchCustomerServiceRequestHistory(id);
            console.log(response?.data);
            setCustomerServiceRequestHistory(response?.data);
        } catch (error) {
            console.log("error occurred while api calling ");
        }

    }

    const fetchCustomerServiceRequestDocs = async () => {
        //fetch all the docs service request

        try {
            const response = await SupportApiService.fetchCustomerServiceRequestDocs(id);
            console.log(response?.data);
            setCustomerServiceRequestDocs(response?.data);
        } catch (error) {
            console.log("error occurred while api calling ");
        }
    }

    const getCustomerServiceRequestDetails = async () => {
        try {
            const response = await SupportApiService.getCustomerServiceRequest(id);
            setCustomerServiceRequest(response?.data);
            getAllServiceStatusByServiceTypeId(response?.data?.serviceTypeId, response?.data?.status);
        } catch (error) {
            console.log("error occurred while api calling ");
        }
    }

    const downloadCustomerServiceRequestValueDOC = async(item:any) => {
      //hare we need to call the api and download the doc

      const requestDto = {
          id: item.id,
          companyId: item.companyId,
          fileName: item.fileName,
          createdBy: userId
        };
    
        try {
          const response: any = await SupportApiService.downloadCustomerServiceRequestValueDOC(
            requestDto
          );
          // Assuming response.data is the blob data
          const blob = new Blob([response.data]);
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = item.fileName;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
  }

    function formatStatus(status?: string): string {
        let formattedStatus = "";
        if (status !== undefined) {
          formattedStatus = status
            ?.split(/[_\\]/)
            .map(
              (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(" ");
        }
        return formattedStatus;
      }

     const handleEditServiceRequest = () => {
        const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";
        history.push(`${linkPrefix}/edit-customer-service-request/${id}`);
     }
    
     const fetchUnreadMessageCount = async () => {
      try{
        const response = await SupportApiService.fetchUnreadMessageCount(id);
        setUnreadMessageCount(response.data);
        
      } catch(error) {
        console.log("error is occurred while api calling")
      }

     }

    //use effect
    useEffect(() => {
        fetchCustomerServiceRequestHistory();
        getCustomerServiceRequestDetails();
        fetchCustomerServiceRequestDocs();
        fetchUnreadMessageCount();
    },[]);

    const routeBack = () => {
        history.goBack();
      };

     //handle edit button click
  const handleEditButtonClick = () => {
    if (isOpenEditSection) {
      setOpenEditSection(false);
    } else {
      setMessageBoxOpen(false);
      setOpenEditSection(true);
    }
  };

  //handle message box click
  const handleMessageBoxClick = () => {
    if (isMessageBoxOpen) {
      setMessageBoxOpen(false);
    } else {
      setOpenEditSection(false);
      setMessageBoxOpen(true);
      setUnreadMessageCount(0)
    }
  };

  const handleAdminMessageClick = () => {
    setShowMessageBox((prev) => !prev);

    // Reset unread groups count when the message box is toggled
    setUnreadGroupsCount(
      unreadGroupsCount > 0 && unreadMessageCount > 0
        ? unreadGroupsCount - 1
        : unreadGroupsCount
    );
  };

  const fetchData = async () => {
    try {
        fetchCustomerServiceRequestHistory();
        getCustomerServiceRequestDetails();
        fetchCustomerServiceRequestDocs();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  //data grid columns for docs
  const columnsForDoc: GridColDef[] = [
    {
      field: "createdDate",
      headerName: "Submitted On",
      flex: 1,
      valueGetter: (value: any, row: any) => {
        const dateTimeString = row.createdDate ? row.createdDate : null;
        if (dateTimeString) {
          //call the method which formate the time
          return CommonService.formatDateTime(dateTimeString);
        }
      },
      headerClassName: "table-header",
    },
    {
      field: "fileName",
      headerName: "File Name",
      flex: 1,
      valueGetter: (value: any, row: any) => {
        const fullFileName = row.fileName || "";
        const fileNameAfterFirstUnderscore = fullFileName.split('_').slice(1).join('_');
        return fileNameAfterFirstUnderscore || fullFileName;
      },
      headerClassName: "table-header",
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "userName",
      headerName: "Submitted By",
      flex: 1,
      valueGetter: (value: any, row: any) =>
        `${row.userName}`,
      headerClassName: "table-header",
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      sortable: false,
      width: 200,
      headerClassName: "table-header",
      renderCell: (params: any) => (
        <div>
          <Tooltip title="Download document" arrow>
            <IconButton
              onClick={(event) => downloadCustomerServiceRequestDOC(params.row)}
            >
              {/* <CloudDownloadOutlinedIcon /> */}
              <img className="cursor-pointer" 
                          src={DownLoadIcon}
                          alt="EditIcon"
                        />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  // Define the localeText object based on the isLoading flag
  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };

  const hasPercentageFeeTypeField = (): boolean => {
    return customerServiceRequest?.dynamicFields?.some(
      (field) => field.value === 'PERCENTAGE'
    ) || false;
  };



  const handleStatusChange = async(newStatus:any) => {
    
    const status = newStatus?.replace(/ /g, "_").toUpperCase();
    
    const currentStatus = {
      customerServiceRequestId: id,
      serviceName: customerServiceRequest?.serviceName,
      reason: "",
      status: status,
      spendHourOnRequest: "",
      companyId: customerServiceRequest?.companyId,
      userId: customerServiceRequest?.userId,
      dynamicFields: customerServiceRequest.dynamicFields || [],
      fee: sellRequestFee,
      feeType: sellRequestFeeType
    };

    serviceRequestUpdateStatus.current = currentStatus;

    if(serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.ACCEPTED && customerServiceRequest?.serviceName == 'Sell Request') {
      setSellRequestAccepted(true);
      setIsCancelled(false);
      setIsRejected(false);
      handleShow();
    } else if (serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.CANCELLED) {
      setIsCancelled(true);
      setIsRejected(false);
      setSellRequestAccepted(false);
      handleShow();
    } else if (serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.REJECTED) {
      setIsRejected(true);
      setIsCancelled(false);
      setSellRequestAccepted(false);
      handleShow();
    } else if (serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.CLOSED) {
      setIsCancelled(false);
      setIsRejected(false);
      setSellRequestAccepted(false);
      handleShow();
    } else {
      //if status is not ACCEPTED and REJECTED then call method don't open any popup
      changeCustomerServiceRequestStatus();
    }

  }

  const handleEditFeeDetails = () => {

    setSellRequestAccepted(true);
    setSellRequestFeeUpdated(true);

    //now set the Fee and Fee type for showing in popup
    customerServiceRequest?.dynamicFields?.forEach((field) => {
      if (field.fieldName === 'Commission Fee') {
        setSellRequestFee(field.value);
      }
      if (field.fieldName === 'Fee Type') {
        setSellRequestFeeType(field.value);
      }
    });


  handleShow();


  }

  const updateSellRequestFeeDetails = async () => {

    let isValid = true;

    //check entered value is valid or not
    if((Number(sellRequestFee)) < 0 || sellRequestFee?.trim().length < 1) {
      setFeeError((sellRequestFee?.trim().length < 1)? "Please enter commission fee" : "Commission Fee should be greater then or equal to zero")
      isValid = false;
    }
    if(sellRequestFeeType == '') {
      setFeeTypeError("Please select fee type")
      isValid = false;
    }


    if(!isValid) {
      return ;
    }

    setIsLoading(true);

    const updateSellRequestDetails = {
      customerServiceRequestId: id,
      fee: sellRequestFee,
      feeType: sellRequestFeeType
    };

    //everything is good then call the api for updating the fee details

    try{
      const response = await SupportApiService.updateSellRequestFeeDetails(updateSellRequestDetails);
      CommonService.Toast.fire({
        title: "Fee Details updated successfully",
        icon: "success",
      });

      getCustomerServiceRequestDetails();

    } catch(error) {
      console.log("error occurred while api calling");
      
    } finally {
      setIsLoading(false);
      handleClose();
    }


  }

  const changeCustomerServiceRequestStatus = async() => {
    if (serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.CANCELLED && reason.trim().length <= 0 && customerServiceRequest.serviceName != 'Bulk Inventory Upload') {
      setError("Please enter cancellation reason");
      return;
    }

    if (serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.REJECTED && reason.trim().length <= 0) {
      setError("Please enter rejection reason");
      return;
    }

    if (serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.REJECTED && reason.trim().length > 1000) {
      setError("Rejection note can not be more than 1000 characters");
      return;
    }

    if (serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.CLOSED && spendHour.trim().length <= 0) {
      setError("Please enter spend time in hours");
      return;
    }

    if ((serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.ACCEPTED && customerServiceRequest?.serviceName == 'Sell Request') && ((Number(sellRequestFee)) < 0 || sellRequestFeeType == '') ) {
      
      if((Number(sellRequestFee)) < 0 || sellRequestFee?.trim().length < 1) {
        setFeeError((sellRequestFee?.trim().length < 1)? "Please enter commission fee" : "Commission Fee should be greater then or equal to zero")
      }
      if(sellRequestFeeType == '') {
        setFeeTypeError("Please select fee type")
      }

      return ;
    }


    setIsLoading(true);
    
      //now call the api and update the status
    try {
      //before calling the api we need to set the reason if status is cancelled and set spend hour if the status Closed
      if(serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.CANCELLED) {
        serviceRequestUpdateStatus.current.reason = reason;
      }
      if(serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.REJECTED) {
        serviceRequestUpdateStatus.current.reason = reason;
      }
      if(serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.CLOSED) {
        serviceRequestUpdateStatus.current.spendHourOnRequest = spendHour;
      }
      if ((serviceRequestUpdateStatus.current.status === Constant.CustomerServiceRequestStatus.ACCEPTED && customerServiceRequest?.serviceName == 'Sell Request')) {
        serviceRequestUpdateStatus.current.fee = sellRequestFee;
        serviceRequestUpdateStatus.current.feeType = sellRequestFeeType;
      }
      const response = await SupportApiService.updateCustomerServiceRequestStatus(serviceRequestUpdateStatus.current);
      getCustomerServiceRequestDetails();
      fetchCustomerServiceRequestHistory();
      handleClose();
      CommonService.Toast.fire({
        title: "Status updated successfully",
        icon: "success",
      });
      setIsLoading(false);
    } catch (error) {
      
      console.log("error occurred while api calling " + error?.response?.data?.errorMessage);

      if(customerServiceRequest?.serviceName == 'Sell Request' || customerServiceRequest?.serviceName == 'Ownership Transfer') {
        CommonService.Toast.fire({
          title: `${error?.response?.data?.errorMessage}`,
          icon: "error",
        });
      }
      
      setIsLoading(false);
    }
  
  }

  const [activeRowIndex, setActiveRowIndex] = useState(null);

  const handleRowClick = (index) => {
      // If clicking the already active row, close it
      setActiveRowIndex(activeRowIndex === index ? null : index);
  };

    return(
        <>
        <div className="usersList position-relative pt-0">
          <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center p-3">
          <div className="d-flex flex-wrap justify-content-between gap-4">
            {CommonService.isAdmin() && (
              <div className="d-flex align-items-center gap-1">
              <><div className="back__button me-1" onClick={routeBack}>
                            <IconButton
                                aria-label="ArrowBackIcon"
                                sx={{ backgroundColor: "#FFF"}}
                            >
                                <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
                            </IconButton>
                        </div>
                        <div className="header--title me-3">
                                <h1 className="title mb-0">
                                    Customer Service Request Details
                                    <i className="bi bi-heart-fill"></i>
                                </h1>
                            </div></>
                            </div>
            )}

  
            <div className="header--title d-flex flex-wrap me-auto mb-xxl-0">
              <div className="time__details me-4 me-xl-4 mb-0">
                <Typography
                  variant="h6"
                  sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
                >
                  Request Number
                </Typography>
                <Typography
                  variant="h3"
                  sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
                >
                  #{customerServiceRequest?.requestNumber}
                </Typography>
              </div>
              {customerServiceRequest.serviceName === Constant.CustomerService.BULK_INVENTORY_UPLOAD ? ('') : (
                  <div className="time__details me-4 me-xl-4 mb-0">
                    <Typography
                      variant="h6"
                      sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
                    >
                      Service Type
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
                    >
                      {customerServiceRequest?.serviceName}
                    </Typography>
                  </div>
                )}

                  
                      {CommonService.isAdmin() && (
                        <div className="company__details me-4 me-xl-4 mb-0">
                          <Typography
                            variant="h6"
                            sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
                          >
                            CompanyName
                          </Typography>
                          <Typography
                            variant="h3"
                            sx={{
                              color: "#333333",
                              fontSize: { xl: "18px", xs: "16px" },
                              fontWeight: 700,
                            }}
                          >
                            {customerServiceRequest?.companyName}
                          </Typography>
                        </div>
                      )}
                      {/* Always show username */}
                      <div className="company__details me-4 me-xl-4 mb-0">
                        <Typography
                          variant="h6"
                          sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
                        >
                          User
                        </Typography>
                        <Typography
                          variant="h3"
                          sx={{
                            color: "#333333",
                            fontSize: { xl: "18px", xs: "16px" },
                            fontWeight: 700,
                          }}
                        >
                          {customerServiceRequest?.userName}
                        </Typography>
                      </div>
                   
  
                  <div className="person__details me-4 me-xl-4 mb-0">
                    <Typography
                      variant="h6"
                      sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
                    >
                      Requested On
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
                    >
                      {CommonService.formatDateTime(customerServiceRequest?.createdAt)}
                    </Typography>
                  </div>

               {customerServiceRequest?.status == 'CLOSED' && customerServiceRequest?.timeSpentOnRequest && (<div className="time__details me-4 me-xl-4 mb-0">
                <Typography
                  variant="h6"
                  sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
                >
                  Spent Time
                </Typography>
                <Typography
                  variant="h3"
                  sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
                >
                  {customerServiceRequest?.timeSpentOnRequest} {customerServiceRequest?.timeSpentOnRequest > 1 ?  'Hrs' : 'Hr'} 
                </Typography>
              </div>)}
                
              
            </div>
            </div>
            <div className="header--menu ms-auto mb-xxl-0">
              <div className="header--menu-items d-flex align-items-center flex-wrap">
                <IconButton
                  aria-label="message"
                  size="small"
                  onClick={
                    handleMessageBoxClick
                  }
                  sx={{
                    borderRadius: "50%",
                    backgroundColor: isMessageBoxOpen ? "#97AEB8" : "#FFF",
                    color: isMessageBoxOpen ? "#FFF" : "#1C1B1F",
                    marginInline: "8px",
                    padding: "8px",
                  }}
                >
                  <MarkunreadOutlinedIcon />
                  {unreadMessageCount > 0  && (
                    <div className="msg-count-wrapper">{unreadMessageCount}</div>
                  )}
                  {showMessageBox && <ArrowDropUpIcon className="arrow-icon" />}
                </IconButton>
                {showMessageBox && (
                  <div className="admin-message-box">
                    <div
                      className="admin-message-box-left-inner"
                      onClick={handleAdminMessageClick}
                    ></div>
                    <div className="admin-message-box-inner positio-relative">
                      <AdminMessageBox
                        selectedGroup={""}
                        requestId={id}
                        requestType={"CUSTOMER_SERVICE_REQUEST"}
                        unreadGroupsCount={unreadGroupsCount}
                        setUnreadGroupsCount={setUnreadGroupsCount}
                        isSeller={false}
                      />
                    </div>
                  </div>
                )}
              

{/* 
{(customerServiceRequest?.status == 'PENDING_REVIEW' ||  customerServiceRequest?.status == 'UNDER_REVIEW') && (
                <StatusDropdown
                  currentStatus={formatStatus(customerServiceRequest?.status)}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus)}
                  disableCondition={false}
                  allowedLabels={CommonService.isAdmin() ? ["Under Review","Accepted","Rejected"] : ["Cancelled"]}
                />)} */}

{/* {(customerServiceRequest?.status === Constant.CustomerServiceRequestStatus.PENDING_REVIEW ||
  customerServiceRequest?.status === Constant.CustomerServiceRequestStatus.UNDER_REVIEW) && (
  <StatusDropdown
    currentStatus={formatStatus(customerServiceRequest?.status)}
    onStatusChange={(newStatus) => handleStatusChange(newStatus)}
    disableCondition={false}
    allowedLabels={CustomerServiceStatus[customerServiceRequest?.status]?.allowedLabels(
      CommonService.isAdmin()
    )}
  />
)}  */}


{/* {(customerServiceRequest?.status === Constant.CustomerServiceRequestStatus.PENDING_REVIEW ||
  customerServiceRequest?.status === Constant.CustomerServiceRequestStatus.UNDER_REVIEW) && (
                <StatusDropdown
                  currentStatus={formatStatus(customerServiceRequest?.status)}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus)}
                  disableCondition={false}
                  allowedLabels={CommonService.isAdmin() ? [Constant.CustomerServiceRequestStatus.UNDER_REVIEW, Constant.CustomerServiceRequestStatus.ACCEPTED, Constant.CustomerServiceRequestStatus.REJECTED] : [Constant.CustomerServiceRequestStatus.CANCELLED]}
                />)}  */}

              {/* {customerServiceRequest?.status == 'ACCEPTED'  && (
                <StatusDropdown
                  currentStatus={formatStatus(customerServiceRequest?.status)}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus)}
                  disableCondition={false}
                  allowedLabels={CommonService.isAdmin() ? (customerServiceRequest?.serviceName == 'Ownership Transfer' ? ["In Progress", "Ownership Transferred","Rejected"]: ["In Progress","Uploaded","Rejected"]) : ["Cancelled"]}
                />)}

{customerServiceRequest?.status == 'IN_PROGRESS'  && customerServiceRequest?.serviceName != 'Ownership Transfer' && (
                <StatusDropdown
                  currentStatus={formatStatus(customerServiceRequest?.status)}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus)}
                  disableCondition={false}
                  allowedLabels={CommonService.isAdmin() ? ["Uploaded","Rejected","Closed"] : ["Cancelled"]}
                />)}

{customerServiceRequest?.status == 'IN_PROGRESS'  && customerServiceRequest?.serviceName == 'Ownership Transfer' && (
                <StatusDropdown
                  currentStatus={formatStatus(customerServiceRequest?.status)}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus)}
                  disableCondition={false}
                  allowedLabels={CommonService.isAdmin() ? ["Rejected","Ownership Transferred"] : ["Cancelled"]}
                />)}

{customerServiceRequest?.status == 'UPLOADED'  && customerServiceRequest?.serviceName == 'Bulk Inventory Upload' && (
                <StatusDropdown
                  currentStatus={formatStatus(customerServiceRequest?.status)}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus)}
                  disableCondition={CommonService.isUser()}
                  allowedLabels={["Closed"]}
                />)}

{customerServiceRequest?.status == 'OWNERSHIP_TRANSFERRED' && customerServiceRequest?.serviceName == 'Ownership Transfer' && (
                <StatusDropdown
                  currentStatus={formatStatus(customerServiceRequest?.status)}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus)}
                  disableCondition={CommonService.isUser()}
                  allowedLabels={["Rejected","Closed"]}
                />)}

              

                {/* if the status is cancelled or rejected or closed then its a last stage 
{(customerServiceRequest?.status == 'CANCELLED' || customerServiceRequest?.status == 'REJECTED' || customerServiceRequest?.status == 'CLOSED') && (
                <StatusDropdown
                  currentStatus={formatStatus(customerServiceRequest?.status)}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus)}
                  disableCondition={true}
                  allowedLabels={[""]}
                />)}
                
                <StatusDropdown
                  currentStatus={formatStatus(customerServiceRequest?.status)}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus)}
                  disableCondition={statusList.length == 0}
                  allowedLabels={CommonService.transformStatusList(statusList)}
                />
                
   {(customerServiceRequest?.status === "CANCELLED"  || customerServiceRequest?.status == 'REJECTED') && customerServiceRequest?.cancellationReason && (
                <Tooltip
                  title={`Reason: ${customerServiceRequest?.cancellationReason}`}
                  arrow
                >
                  <StickyNote2Icon
                    className="note-icon cursor-pointer"
                    style={{ color: "gray" }}
                  />
                </Tooltip>
              )}
                {/* {(customerServiceRequest?.status == 'PENDING_REVIEW' && customerServiceRequest?.status == 'OPEN') && (
                <StatusDropdown
                  currentStatus={formatStatus(customerServiceRequest?.status)}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus)}
                  disableCondition={false}
                  allowedLabels={["Open", "Close"]}
                />)}

                {customerServiceRequest?.status == 'CLOSE' && (
                <StatusDropdown
                  currentStatus={formatStatus(customerServiceRequest?.status)}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus)}
                  disableCondition={false}
                  allowedLabels={["Reopen"]}
                />)} */}
                <div className="d-inline-flex align-items-center">
                {(customerServiceRequest?.status === "UPLOADING" && customerServiceRequest?.serviceName === "Bulk Inventory Upload") && (
                  <div className="upload-spinner-wrapper">
                  <Spinner/>
                  </div>
                )}
                <StatusDropdown
                  currentStatus={formatStatus(customerServiceRequest?.status)}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus)}
                  disableCondition={statusList.length == 0}
                  allowedLabels={CommonService.transformStatusList(statusList)}
                />
              {(customerServiceRequest?.status === "CANCELLED"  || customerServiceRequest?.status == 'REJECTED') && customerServiceRequest?.cancellationReason && (
                <Tooltip
                  title={`Reason: ${customerServiceRequest?.cancellationReason}`}
                  arrow
                >
                  <StickyNote2Icon
                    className="note-icon cursor-pointer"
                    style={{ color: "gray" }}
                  />
                </Tooltip>
              )}

                </div>
              {!(customerServiceRequest?.roleName == "SUPER_ADMIN" && CommonService.isUser()) && ['PENDING_REVIEW','UNDER_REVIEW'].includes(customerServiceRequest?.status) && (<Tooltip title="Edit" arrow>
                    <IconButton
                        aria-label="message"
                        size="small"
                        disabled= {customerServiceRequest?.roleName == "SUPER_ADMIN" && CommonService.isUser()}
                        onClick={handleEditServiceRequest}
                        sx={{ borderRadius: "50%", backgroundColor: "#FFF", color: "#1C1B1F", marginInline: "8px", padding: "8px" }}
                    >
                         <img className="cursor-pointer" 
                          src={EditIcon}
                          alt="EditIcon"
                        />
                    </IconButton>
                    </Tooltip>)}
                    {!(customerServiceRequest?.roleName == "SUPER_ADMIN" && CommonService.isUser()) && ['PENDING_REVIEW','UNDER_REVIEW'].includes(customerServiceRequest?.status) && ( <Tooltip title="Delete" arrow>
                    <IconButton
                        aria-label="message"
                        size="small"
                        disabled= {customerServiceRequest?.roleName == "SUPER_ADMIN" && CommonService.isUser()}
                        sx={{ borderRadius: "50%", backgroundColor: "#FFF", color: "#1C1B1F", marginInline: "8px", padding: "8px" }}
                        onClick={deleteServiceRequest}
                    >
                        <img src={TrashIcon} />
                    </IconButton>
                    </Tooltip>
                    )}
                
              </div>
            </div>
          </div>
          <div className="viewSampleRequest--wrapper view_request--wrapper view_sample-wrapper viewOrderRequest--wrapper viewServiceRequest--wrapper viewSupport-req">
            <div
              className={`${
                isOpenEditSection
                  ? "margin-right-400" // When only `isOpenEditSection` is true
                  : isMessageBoxOpen
                  ? "margin-right-400" // Only `isMessageBoxOpen` is true
                  : "no-margin w-100" // When none of the conditions are met
              }`}
            >
              <div
                className="view-sample-request m-0"
                style={{ zIndex: "10", position: "relative" }}
              >
                
                <div className="row m-0 mb-3">
                  <div className="col-12 px-0">
                    {customerServiceRequest.serviceName === Constant.CustomerService.BULK_INVENTORY_UPLOAD ? (<>
                      <div className="inventory-file-list-table p-0">
                        <div className="service-detail-right bluk-upload-full">
                          <div className="row m-0">
                            <div className="col-12">
                              <div className="row">
                                {/* itterate customerServiceRequest.dynamicFields */}
                                <div className="col-12 col-sm-6 col-lg-3 mb-3">
                                  <div className="content-wrapper">
                                    <p className="content-title">
                                        Service Type
                                    </p>
                                      <div className="title-bottom-line"></div>
                                      <div className="d-flex align-items-start gap-3">
                                        <p className="content-text">{customerServiceRequest?.serviceName}</p>
                                      </div>
                                  </div>
                                </div>
                                {customerServiceRequest?.dynamicFields?.map((field, index) => (
                                  <div className="col-12 col-sm-6 col-lg-3 mb-3">
                                    <div className="content-wrapper">
                                      <p className="content-title">
                                        {field?.fieldName}
                                      </p>
                                      <div className="title-bottom-line"></div>
                                      <div className="d-flex align-items-start gap-3">
                                        {field?.fieldName === "Upload Inventory CSV/Excel File" ? (
                                          <>
                                            <p className="content-text">{field?.value.split("_").slice(1).join("_")}</p>
                                            {['PENDING_REVIEW','UNDER_REVIEW'].includes(customerServiceRequest?.status)  && (
                                              <>
                                                {/* Tooltip button to open the file upload modal */}
                                                <Tooltip title="Upload" arrow>
                                                  <button className="upload-icon-btn" onClick={handleFileShow}>
                                                    <FileUploadOutlinedIcon sx={{ ml: 1 }} />
                                                  </button>
                                                </Tooltip>

                                                {/* File Upload Modal */}
                                                <Modal
                                                  size="lg"
                                                  show={showFileUploadModal}
                                                  onHide={handleFileClose}
                                                  className="modal__wd modal__inviteUser modal__wd--submitSellReq"
                                                >
                                                  <Modal.Header className="flex-column align-items-start">
                                                    <Modal.Title className="mb-1"> Bulk Inventory Upload File</Modal.Title>
                                                    {!isInvited && (
                                                      <p className="modal-text text-start mb-0">
                                                        To add a file, drag and drop in the area below or select the
                                                        "Select file" button.
                                                      </p>
                                                    )}
                                                    {isInvited && (
                                                      <p className="modal-text text-start">
                                                        Your submission has been submitted successfully, You will receive
                                                        a notification upon its completion.
                                                      </p>
                                                    )}
                                                    <Button
                                                      variant="close"
                                                      className="d-inline-flex justify-content-center align-items-center"
                                                      onClick={handleFileClose}
                                                    >
                                                      <CloseIcon />
                                                    </Button>
                                                  </Modal.Header>

                                                  {!isInvited && (
                                                    <Modal.Body className="border-0">
                                                      {(!validated && !isLoading) && (
                                                        <Form className="form__inner row d-flex pb-0" onSubmit={uploadFilesAndDocs}>
                                                          <Form.Group>
                                                            {/* Drag and Drop or Browse File */}
                                                            <div className="file__upload--container">
                                                              <div
                                                                className={`text-center mb-3 ${validated && !file
                                                                  ? "file__upload--dropzone-danger"
                                                                  : "file__upload--dropzone"
                                                                  }`}
                                                                id="drop_zone"
                                                                onDrop={onDropHandler}
                                                                onDragOver={onDragOver}
                                                              >
                                                                <div>
                                                                  <img src={CloudArrowUp} alt="Cloud Upload Icon" />
                                                                  <br />
                                                                  <span className="fw-bold">Drag & Drop to Upload File</span>
                                                                </div>
                                                                <div className="my-2 fw-bold">OR</div>

                                                                <label
                                                                  htmlFor="file_picker"
                                                                  className="primary-btn fp-label fw-bold"
                                                                >
                                                                  Browse file
                                                                </label>

                                                                <Form.Control
                                                                  required
                                                                  type="file"
                                                                  id="file_picker"
                                                                  onChange={handleFileChange}
                                                                  accept=".csv, .xls, .xlsx"
                                                                  style={{ display: "none" }}
                                                                />
                                                              </div>
                                                            </div>
                                                          </Form.Group>

                                                          {/* Error message if no file is selected */}
                                                          {validated && !file && (
                                                            <p className="error-message">
                                                              Please select a valid CSV or Excel file.
                                                            </p>
                                                          )}

                                                          {/* Display the selected file name */}
                                                          {file?.name && (
                                                            <>
                                                            {/* <div className="d-flex align-items-center gap-3 flex-wrap"> */}
                                                              <div className="file__upload--info d-inline-flex align-items-center mt-1" style={{ color: "#737376" }}>
                                                                <div className="me-2">
                                                                  <DescriptionOutlinedIcon />
                                                                </div>
                                                                <div
                                                                  className="file-name me-2"
                                                                  key={file?.name}
                                                                  // style={{ fontWeight: "bold" }}
                                                                >
                                                                  {file?.name}
                                                                </div>
                                                                <div
                                                                  className="file-delete"
                                                                  onClick={() => setFile(null)}
                                                                >
                                                                  <HighlightOffRoundedIcon fontSize="small" />
                                                                </div>
                                                              </div>
                                                              {/* </div> */}
                                                            </>
                                                          )}
                                                        </Form>
                                                      )}
                                                      {(validated && !isLoading) && (<List className="warning-list">
                                                        {validationsList?.map((validation, index) => (
                                                          <React.Fragment key={index}>
                                                            <ListItem alignItems="flex-start" sx={{ display: 'flex', paddingInline: '0px' }}>
                                                              <ListItemAvatar sx={{ minWidth: 'unset', marginTop: 0, marginRight: '2px' }}>
                                                                <WarningRoundedIcon sx={{ color: '#b10e1c' }} />
                                                              </ListItemAvatar>
                                                              <ListItemText
                                                                sx={{ margin: 0, display: 'flex', justifyContent: 'space-between' }}
                                                                primary={
                                                                  <React.Fragment>
                                                                    <Typography
                                                                      component="span"
                                                                      variant="body2"
                                                                      sx={{ color: '#b10e1c', fontWeight: 600, wordBreak: 'break-all' }}
                                                                    >
                                                                      Row # {validation?.rowNumber} -
                                                                    </Typography>
                                                                    <Typography
                                                                      component="span"
                                                                      variant="body2"
                                                                      sx={{ color: '#b10e1c', fontWeight: 400, marginLeft: '4px' }}
                                                                    >
                                                                      {validation?.errorMessages}
                                                                    </Typography>
                                                                  </React.Fragment>
                                                                }
                                                                secondary={
                                                                  <React.Fragment>
                                                                    <Typography
                                                                      component="span"
                                                                      variant="body2"
                                                                      sx={{ color: '#496673', fontWeight: 400, cursor: 'pointer' }}
                                                                    >
                                                                    </Typography>
                                                                  </React.Fragment>
                                                                }
                                                              />
                                                            </ListItem>
                                                            {index !== companiesList?.length - 1 && (
                                                              <Divider variant="inset" component="li" sx={{ marginLeft: 0 }} />
                                                            )}
                                                          </React.Fragment>
                                                        ))}
                                                      </List>)}
                                                      {isLoading && (
                                                        <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', miheight: '300px' }}>
                                                          <BootstrapSpinner animation="border" variant="secondary" className="mt-3" />
                                                          <Typography variant="subtitle1" sx={{ fontSize: '20px', fontWeight: 600, color: '#737376', marginTop: '12px' }}>
                                                            Validating file, please wait...
                                                          </Typography>
                                                        </Container>
                                                      )}
                                                    </Modal.Body>
                                                  )}

                                                  {!isInvited && (
                                                    <Modal.Footer className="justify-content-center align-items-center">
                                                      {/* Cancel Button */}
                                                      {!validated && (<button
                                                          className="secondary-btn px-5 mx-1"
                                                          onClick={handleFileClose}
                                                          disabled={isLoading}
                                                        >
                                                          Cancel
                                                        </button>)}
                                                      {/* Upload Button */}
                                                      {(!validated && !isLoading) && (<button
                                                          className="primary-btn px-5 mx-1"
                                                          onClick={uploadFilesAndDocs}
                                                          disabled={isLoading}
                                                        >
                                                          {isLoading && <BootstrapSpinner size="sm"/>}
                                                          Upload
                                                        </button>)}

                                                      {validated && (<button
                                                        className="secondary-btn px-5 mx-1"
                                                        onClick={() => setValidated(false)}
                                                      >
                                                        Back
                                                      </button>)}
                                                    </Modal.Footer>
                                                  )}
                                                  {isInvited && (
                                                    <>
                                                      <Modal.Footer className="justify-content-center bg-transparent">
                                                        <div className="btn-group row d-flex justify-content-center w-100">
                                                          <div className="col-12">
                                                            <button className="secondary-btn w-100" onClick={handleFileClose}>
                                                              Close
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </Modal.Footer>
                                                    </>
                                                  )}
                                                </Modal>
                                              </>)}
                                            
                                            <Tooltip title="Download" arrow>
                                              <IconButton className="download-icon-btn"
                                                onClick={(event) => {
                                                  const companyId = customerServiceRequest?.companyId;
                                                  const dynamicFields = customerServiceRequest?.dynamicFields || [];
                                                  const dynamicField = dynamicFields.find(field => field.fieldName === "Upload Inventory CSV/Excel File");
                                                  const id = dynamicField?.id;
                                                  const fileName = dynamicField?.value;
                                            
                                                  downloadCustomerServiceRequestValueDOC({
                                                    companyId,
                                                    id,
                                                    fileName,
                                                  })}}>
                                                <img className="cursor-pointer" 
                                                            src={DownLoadIcon}
                                                            alt="EditIcon"
                                                          />
                                              </IconButton>
                                            </Tooltip>
                                          </>
                                        ) : field?.value === "true" ? (
                                          <p className="content-text">Yes</p>
                                        ) : field?.value === "false" ? (
                                          <p className="content-text">No</p>
                                        ) : (
                                          <p className="content-text">{field?.value}</p>
                                        )}

                                        {/*<div className="content-text">
                                            <ListItem key={index}>
                                              <Typography
                                                variant="body2"
                                                sx={{ color: '#333333', fontSize: '16px', textWrap: 'nowrap' }}
                                              >
                                                {field?.value === 'true' ? (
                                                  <>
                                                    <CheckIcon fontSize="small" sx={{ color: '#25CD25' }} /> Yes
                                                  </>
                                                ) : field?.value === 'false' ? (
                                                  <>
                                                    <CloseIcon fontSize="small" sx={{ color: '#FF3B30' }} /> No
                                                  </>
                                                ) : (
                                                  field?.value
                                                )}
                                              </Typography>
                                            </ListItem>
                                          </div>*/}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                    ) : (
                      <div className="inventory-file-list-table ">
                        <div className="row m-0">
                          <div className="col-xl-8">
                            <div className="table-responsive service-detail-table-wrapper">

                              <Table className="service-detail-table">
                                <thead>
                                  <tr>
                                    <th className="text-start">Inventory</th>
                                    <th className="text-nowrap ">Quantity</th>
                                    <th className="text-nowrap ">Barrel No.</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {customerServiceRequest?.inventoryDetails?.map((item, index) => (
                                    <React.Fragment key={index}>
                                      <tr style={{
                                        borderColor:
                                          activeRowIndex === index
                                            ? "transparent"
                                            : "#E4E4DD",
                                      }}>
                                        <td className="text-start">{`${item?.productName} (${item?.lotNo})`}</td>
                                        <td className="text-nowrap">{item.quantity}</td>
                                        <td className="text-nowrap">
                                          <span> {item?.barrelNumbers[0]?.barrelNumber} </span>
                                          {item?.barrelNumbers?.length - 1 > 0 &&
                                            (<button
                                              onClick={() => handleRowClick(index)}
                                              className="barrel-count-btn"
                                              style={{
                                                borderColor:
                                                  activeRowIndex === index
                                                    ? "#3A809E"
                                                    : "#8F9195",
                                                color:
                                                  activeRowIndex === index
                                                    ? "#3A809E"
                                                    : "#737376"
                                              }}
                                            >
                                              {item?.barrelNumbers?.length - 1 || 0}
                                              <span>
                                                <img src={ExpandMore} alt="CompanyIcn" width={15} height={8} />
                                              </span>

                                            </button>)}
                                        </td>
                                      </tr>
                                      {activeRowIndex === index && (
                                        <tr className="total-barrel-wrapper">
                                          <td colSpan={3}>
                                            <Accordion activeKey={activeRowIndex !== null ? "0" : null}>
                                              <Accordion.Item eventKey="0">
                                                <Accordion.Body>
                                                  <div className="total-barrel-list-wrapper">
                                                    <div className="barrel-list d-flex align-items-center flex-wrap gap-2">
                                                      {item?.barrelNumbers?.map((barrel, barrelIndex) => (
                                                        <div
                                                          key={barrelIndex}
                                                          className="barrel-list-item"
                                                        >
                                                          {barrel?.barrelNumber}
                                                        </div>
                                                      ))}
                                                    </div>
                                                  </div>
                                                </Accordion.Body>
                                              </Accordion.Item>
                                            </Accordion>
                                          </td>
                                        </tr>
                                      )}
                                    </React.Fragment>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                          <div className="col-xl-4">
                            <div className="service-detail-right">
                              <div className="row row-cols-3">
                                {/* iterate customerServiceRequest.dynamicFields */}
                              {customerServiceRequest?.dynamicFields
                              ?.filter((field) => field?.fieldName !== 'Description')
                              ?.map((field, index) => (
                                <div className="col" key={index}>
                                  <div className="content-wrapper">
                                    <p className="content-title">{field?.fieldName == 'Commission Fee' ? `${field?.fieldName}/Barrel` : field?.fieldName == 'Price' ? `${field?.fieldName}/Barrel` : field?.fieldName}

                                    {field?.fieldName == 'Commission Fee' && CommonService.isAdmin() && customerServiceRequest?.status == 'ACCEPTED' && (<Tooltip title="Edit commission fee" arrow>
                                        <IconButton
                                            aria-label="message"
                                            size="small"
                                            onClick={handleEditFeeDetails}
                                            sx={{ padding:0,color: "#1C1B1F"}}
                                        >
                                            <img className="cursor-pointer" 
                                              src={EditIcon}
                                              alt="EditIcon"
                                              height={15}
                                              style={{marginLeft:5}}
                                            />
                                        </IconButton>
                                        </Tooltip>)}

                                    </p>
                                    <div className="title-bottom-line"></div>

                                    {field?.fieldName === 'Commission Fee' ? (
                                  hasPercentageFeeTypeField() ? (
                                      <p className="content-text">{field?.value}%</p> // Display placeholder when PERCENTAGE is detected
                                    ) : (
                                      <p className="content-text">
                                        <CurrencyFormatter amount={field?.value} locale="en-US" currency="USD" />
                                      </p>
                                    )
                                  ): field?.fieldName === 'Price' ? (<p className="content-text">
                                    <CurrencyFormatter amount={field?.value} locale="en-US" currency="USD" />
                                  </p>
                                  ) : field?.value === "true" ? (
                                    <p className="content-text">Yes</p>
                                  ) : field?.value === "false" ? (
                                    <p className="content-text">No</p>
                                  ) : (
                                    <p className="content-text">
                                      {field?.fieldName === 'Fee Type' ? CommonService.formatRoleName(field?.value) : field?.value}
                                    </p>
                                  )}
                                  </div>
                                </div>
                              ))}

                              </div>

                               {/* Show the Description separately if it exists */}
                              {customerServiceRequest?.dynamicFields?.some(field => field?.fieldName === 'Description') && (
                                <div className="col-12">
                                  <div className="content-wrapper mt-2">
                                    <p className="content-title">Description</p>
                                    <div className="title-bottom-line"></div><p className="content-text">
                                    <CKContent
                                      content={
                                        customerServiceRequest.dynamicFields.find(field => field?.fieldName === 'Description')?.value
                                      }
                                      className="custom-class"
                                    />
                                    </p>
                                  </div>
                                </div>
                              )}

                              {/* <div> */}
                              <div className="col-12">
                                <div className="content-wrapper mt-2">
                                  <p className="content-title">
                                    Comment
                                  </p>
                                  <div className="title-bottom-line"></div>
                                  <p className="content-text">{customerServiceRequest?.comment}</p>
                                </div>
                              </div>
                              {/* </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  
                </div>
                <div className="row m-0 p-0">
                    <div className="col-xl-6 mb-3 mb-xl-0 ps-0 pe-xl-2 pe-0">
                      <div className="orderDetail-footer-content-wrapper">
                        <div className="title-bar d-flex justify-content-between align-items-center mb-3">
                          <h3 className="mb-0">List of Documents</h3>
                          <UploadDocs
                            fetchData={fetchData}
                            csvId={id}
                            uploadCSV={false}
                            requestType={"CUSTOMER_SERVICE_REQUEST"}
                          />
                        </div>
                        <div
                          className="inventory-doc-table"
                          // style={{ height: 350, width: "100%" }}
                        >
                          {/* {isLoading ? (
                            <>
                              <Skeleton
                                animation="wave"
                                variant="rounded"
                                // height={550}
                                style={{ height: "100%" }}
                              />
                            </>
                          ) : ( */}
                            <>
                              <DataGrid
                                localeText={localeText}
                                rows={customerServiceRequestDocs}
                                columns={columnsForDoc}
                                className="data-grid upload-doc-datagrid-table"
                                // hideFooterPagination
                                hideFooter
                                loading={isLoading}
                                disableColumnResize
                              />
                            </>
                          {/* )} */}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 mb-3 mb-xl-0 pe-0 ps-xl-2 ps-0">
                      <div className="recentActivity h-100">
                        <div className="title-bar d-flex justify-content-between align-items-center mb-3">
                          <h3 className="mb-0">Recent Activity</h3>
                        </div>
                        <div className="history-stepper-wrapper">
                          <HistoryStepper historyData={customerServiceRequestHistory} />
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
            
          </div>
          {(isOpenEditSection || isMessageBoxOpen) && (
              <div className={`msgContainerBox my-3 my-xl-0 ${customerServiceRequest.serviceName === Constant.CustomerService.BULK_INVENTORY_UPLOAD && "msgContainerBox-upload" }`}>
                {isOpenEditSection && (
                  <EditRequest
                    requestType={"SAMPLE_REQUEST"}
                    id={id}
                    refreshData={fetchData}
                    onEditClick={handleEditButtonClick}
                  />
                )}
                {isMessageBoxOpen && (
                  <ContainerMessageBox
                    requestType={"CUSTOMER_SERVICE_REQUEST"}
                    sellerCompanyId={null} 
                    isSeller={false}
                  />
                )}
              </div>
            )}
        </div>
      </div> 
      <Modal
        show={show}
        onHide={handleClose}
        className="modal__wd modal__wd--submitSellReq"
      >
        <Modal.Header className="flex-column align-items-start">
          <Modal.Title>Update Customer Service Request Status</Modal.Title>
          {isCancelled && (
            <p className="modal-text">
              Are you sure you want to cancel? {(customerServiceRequest?.status != 'PENDING_REVIEW' && customerServiceRequest?.status != 'UNDER_REVIEW') ? "A cancellation charge may apply." : ""}
            </p>
          )}
          {isRejected && (
            <p className="modal-text">
              Are you sure you want to reject? {(customerServiceRequest?.status != 'PENDING_REVIEW' && customerServiceRequest?.status != 'UNDER_REVIEW') ? "" : ""}
            </p>
          )}
          {isSellRequestAccepted && (
            <p className="modal-text">
              Please enter commission fee and fee type {(customerServiceRequest?.status != 'PENDING_REVIEW' && customerServiceRequest?.status != 'UNDER_REVIEW') ? "" : ""}
            </p>
          )}
          {!isCancelled && !isRejected && !isSellRequestAccepted && (
            <p className="modal-text">
              Close customer service request
            </p>
          )}
        </Modal.Header>
        <Modal.Body>
          {isCancelled && (
            <div className="form__inner">
            <textarea
                className={`form-control ${error ? "is_invalid" : ""}`}
                aria-label="With textarea"
                rows={8}
                style={{ resize: "none" }}
                value={reason}
                onChange={handleReasonChange}
                placeholder="Enter cancellation reason.."
              />
             
              {error && <div className="error-message">{error}</div>}
            </div>
          )}
          

          {isRejected && (
            <div className="form__inner">
            <textarea
                className={`form-control ${error ? "is_invalid" : ""}`}
                aria-label="With textarea"
                rows={8}
                style={{ resize: "none" }}
                value={reason}
                onChange={handleReasonChange}
                placeholder="Enter rejection reason.."
              />
             
              {error && <div className="error-message">{error}</div>}
            </div>
          )}

      {isSellRequestAccepted && (
            <><div className="form__inner">
            <label style={{ color: "#333333", fontWeight: "700", marginBottom: "6px" }}>
              Fee Type<span className="star-required text-danger">*</span>
            </label>
            <select
              id="fee-type-dropdown"
              value={sellRequestFeeType}
              style={{ cursor: "pointer" }}
              onChange={handleSellRequestFeeTypeChange}
              className={`form-control no-spinner ${feeTypeError ? "is_invalid" : ""}`}
            >
              <option value="">Select Fee Type</option>
              <option value="FLAT">Flat</option>
              <option value="PERCENTAGE">Percentage</option>
            </select>
            {feeTypeError && <div className="error-message">{feeTypeError}</div>}
          </div>

          <div className="form__inner mt-2">
          <label style={{ color: "#333333", fontWeight: "700", marginBottom: "6px" }}>
            Commission Fee ($) /Barrel<span className="star-required text-danger">*</span>
          </label>
          <Form.Control
            className={`form-control no-spinner ${feeError ? "is_invalid" : ""}`}
            type="number"
            value={sellRequestFee}
            onChange={handleSellRequestFeeChange}
            placeholder="Enter commission fee amount"
          />
          {feeError && <div className="error-message">{feeError}</div>}
          </div></>
        )}

          {!isCancelled && !isRejected && !isSellRequestAccepted && (
            <div className="form__inner">

              <label style={{color:" #333333",fontWeight: "700",marginBottom: "6px",}}>Time Spent (hours)<span className="star-required text-danger">*</span></label>
               <Form.Control
                className={`form-control no-spinner ${error ? "is_invalid" : ""}`}
                type="number"
                value={spendHour}
                onChange={handleSpendHourChange}
                placeholder="Enter hours"
              />
              {error && <div className="error-message">{error}</div>}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="btn-group row d-flex justify-content-between w-100">
            <div className="col-6 ps-0">
              <button
                className="secondary-btn w-100"
                onClick={handleClose}
                disabled={isLoading}
              >
                Cancel
              </button>
            </div>

            <div className="col-6 pe-0">
              <button
                className="primary-btn w-100"
                onClick={isSellRequestFeeUpdated ? updateSellRequestFeeDetails : changeCustomerServiceRequestStatus}
                disabled={isLoading}

              >
                {isLoading && <Spinner />} {isSellRequestFeeUpdated ? 'Update' : isCancelled ? "Cancel Request" : isRejected ? "Reject Request" : (isSellRequestAccepted ? "Accept" : "Close Request")}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      </>
  );
};

export default ViewSupportRequest;