import React, { useRef, useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import "../Dynamic/BarrelSelectionPanel.scss";
import SearchIcon from "@mui/icons-material/Search";
import DoneIcon from "@mui/icons-material/Done";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import { Tab, Nav } from "react-bootstrap";
import { OrderApiService } from "../../Service/OrderApiService";
import { CommonService } from "../../Service/CommonService";
import Customspinner from "../Common/CustomSpinner";
import Constant from "../../Constant";
import WarningIcon from '@mui/icons-material/Warning';

interface BarrelSelectionPanelProps {
  requestType: any;
  requestId: any;
  inventoryId: any;
  orderItemId: any;
  inventoryName: any;
  requiredQuantity: any;
  companyName: any;
  currentStatus: any;
  handleCloseBarrelSelection: () => void;
}

interface Barrel {
  barrelId: string;
  barrelNumber: string;
  barrelStatus: string;
  inventoryName: string;
  sellerCompanyId: string;
  lotNo: string;
}

const BarrelSelectionPanel: React.FC<BarrelSelectionPanelProps> = ({
  requestType,
  requestId,
  inventoryId,
  orderItemId,
  inventoryName,
  requiredQuantity,
  companyName,
  currentStatus,
  handleCloseBarrelSelection,
}) => {
  // State variables
  const [barrelDetails, setBarrelDetails] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedQuantity, setSelectedQuantity] = useState<number>(0);
  const [selectedLotNo, setSelectedLotNo] = useState<string | null>(null);
  const [defaultActiveTab, setDefaultActiveTab] = useState("");
  const [prevBarrelDetails, setPrevBarrelDetails] = useState<any[]>([]);
  const [isLoading,setIsLoading] = useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);
  const [invalidQuantity,setInvalidQuantity] = useState(false);
  useEffect(() => {
    if(requestType === Constant.RequestType.ORDER_REQUEST)
      fetchOrderBarrelDetails();
    else if(requestType === Constant.RequestType.SAMPLE_REQUEST) 
      fetchSampleOrderBarrelDetails();
    else
      fetchBarrelDetails();
  }, []);

  //Fetch sample order barrel details
  const fetchSampleOrderBarrelDetails = async () => {
    setIsLoading(true);
    try {
      const response = await OrderApiService.getAllSampleOrderBarrels(requestId, inventoryId);
      setBarrelDetails(response?.data);
      setPrevBarrelDetails(response?.data);

      setDefaultActiveTab(response?.data[0]?.lotNo)

      // Set initial selected quantity based on RESERVED barrels
      const reservedCount = response?.data
        ?.flatMap((lot: any) => lot.barrelDetails)
        .filter((barrel: any) => barrel.barrelStatus === "RESERVED").length;

      setSelectedQuantity(reservedCount);
      
    } catch (error) {
      console.error("Error fetching barrel details:", error);
    } finally {
      setIsLoading(false);
    }
  }


  // Fetch barrel details
  const fetchBarrelDetails = async () => {
    setIsLoading(true);
    try {
      const response = await OrderApiService.getAllBarrelsForOffer(requestId, inventoryId);
      setBarrelDetails(response?.data);
      setPrevBarrelDetails(response?.data);

      setDefaultActiveTab(response?.data[0]?.lotNo)

      // Set initial selected quantity based on RESERVED barrels
      const reservedCount = response?.data
        ?.flatMap((lot: any) => lot.barrelDetails)
        .filter((barrel: any) => barrel.barrelStatus === "RESERVED").length;

      setSelectedQuantity(reservedCount);
      
    } catch (error) {
      console.error("Error fetching barrel details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch barrel details
  const fetchOrderBarrelDetails = async () => {
    setIsLoading(true);
    try {
      const response = await OrderApiService.getOrderBarrels(requestId, inventoryId);
      setBarrelDetails(response?.data);
      setPrevBarrelDetails(response?.data);

      setDefaultActiveTab(response?.data[0]?.lotNo)

      // Set initial selected quantity based on RESERVED barrels
      const reservedCount = response?.data
        ?.flatMap((lot: any) => lot.barrelDetails)
        .filter((barrel: any) => barrel.barrelStatus === "RESERVED").length;

      setSelectedQuantity(reservedCount);
      
    } catch (error) {
      console.error("Error fetching barrel details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Group barrels by lotNo
  const groupedBarrels = barrelDetails.reduce((acc: any, lot: any) => {
    acc[lot.lotNo] = lot.barrelDetails;
    return acc;
  }, {});

  const lotNos = Object.keys(groupedBarrels);

  // Filter barrels based on search query
  const filteredBarrels = (lotNo: string) => {
    return groupedBarrels[lotNo]?.filter((barrel: Barrel) =>
      barrel.barrelNumber.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const getTotalReservedBarrelsCount = () => {
    return Object.values(groupedBarrels ?? {})
        .filter(Array.isArray)
        .flat()
        .filter((barrel: Barrel): barrel is Barrel => 
            barrel != null && barrel.barrelStatus === "RESERVED"
        )
        .length;
};

  const totalReservedCount = getTotalReservedBarrelsCount();

  /**
   * Handles individual barrel selection/deselection
   * Updates barrel status between RESERVED and LISTED
   */
  const handleBarrelSelect = (lotNo: string, barrelId: string) => {
    setBarrelDetails((prevBarrels) => {
      let newSelectedQuantity = selectedQuantity; // Track the updated quantity
  
      const updatedBarrels = prevBarrels.map((lot: any) => {
        if (lot.lotNo === lotNo) {
          return {
            ...lot,
            barrelDetails: lot.barrelDetails.map((b: Barrel) => {
              if (b.barrelId === barrelId) {
                const newStatus = b.barrelStatus === "RESERVED" ? "LISTED" : "RESERVED";
  
                // Adjust the selected quantity based on the status change
                if (newStatus === "RESERVED" && b.barrelStatus !== "RESERVED") {
                  newSelectedQuantity += 1; // Increment if barrel is being selected
                } else if (newStatus === "LISTED" && b.barrelStatus === "RESERVED") {
                  newSelectedQuantity -= 1; // Decrement if barrel is being deselected
                }
  
                return {
                  ...b,
                  barrelStatus: newStatus,
                };
              }
              return b;
            }),
          };
        }
        return lot;
      });
      setSelectedQuantity(newSelectedQuantity);
      
    
      return updatedBarrels;
    });
  };
  

  const handleSelectAll = (lotNo: string, selectAll: boolean) => {
    setBarrelDetails((prevBarrels) => {
      const updatedBarrels = prevBarrels.map((lot: any) => {
        if (lot.lotNo === lotNo) {
          return {
            ...lot,
            barrelDetails: lot.barrelDetails.map((barrel: Barrel) => {
              if (
                barrel.barrelNumber.toLowerCase().includes(searchQuery.toLowerCase())
              ) {
                // Apply selection only to filtered barrels
                return {
                  ...barrel,
                  barrelStatus: selectAll ? "RESERVED" : "LISTED",
                };
              }
              return barrel; // Leave unfiltered barrels unchanged
            }),
          };
        }
        return lot;
      });
  
      // After updating the barrel details, calculate the selected quantity
      calculateAndSetSelectedQuantity(updatedBarrels);
      return updatedBarrels;
    });
  };

  const handleSelectAllLots = (selectAll: boolean) => {
    setBarrelDetails((prevBarrels) => {
      const updatedBarrels = prevBarrels.map((lot: any) => ({
        ...lot,
        barrelDetails: lot.barrelDetails.map((barrel: Barrel) => ({
          ...barrel,
          barrelStatus: selectAll ? "RESERVED" : "LISTED",
        })),
      }));
  
      // After setting the new barrel details, calculate the selected quantity
      calculateAndSetSelectedQuantity(updatedBarrels);
      return updatedBarrels;
    });
  };

  
  
  

  // Handle search functionality
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

 


  // Handle done button click
  const handleDone = async () => {
    const selectedQuantity = barrelDetails
      .flatMap((lot: any) => lot.barrelDetails)
      .filter((barrel: Barrel) => barrel.barrelStatus === "RESERVED").length;

    if (requestType != Constant.RequestType.SAMPLE_REQUEST && selectedQuantity > requiredQuantity) {
      CommonService.Toast.fire({
        icon: "error",
        title: `Selected quantity exceeds the required quantity.`,
      });
      setInvalidQuantity(true);
    } else {
      try {
          // now in this barrelDetail variable have my current data  and prevBarrelDetails has my prev data which has come from backend now we need to compare both and create the response list of barrelDetails I don't want the response on group by of lotId
         // Flatten the arrays
      const flatPrevDetails = prevBarrelDetails.flatMap((lot: any) => lot.barrelDetails);
      const flatBarrelDetails = barrelDetails.flatMap((lot: any) => lot.barrelDetails);
      // Create a map for quick lookup of previous barrel statuses
      const prevStatusMap = new Map(
        flatPrevDetails.map((barrel: any) => [barrel.barrelId, barrel.barrelStatus])
      );
      // Compare and generate the response list
      const updatedBarrelDetails = flatBarrelDetails.map((barrel: any) => {
        const prevStatus = prevStatusMap.get(barrel.barrelId);
        const isStatusUpdated = prevStatus !== barrel.barrelStatus;
        return {
          ...barrel,
          isStatusUpdated,
        };
      });
      // Filter only the barrels with updated statuses
      const updatedBarrelResponseList = updatedBarrelDetails.filter((barrel: any) => barrel.isStatusUpdated);
      console.log("Response List:", updatedBarrelResponseList);
      //if updatedBarrelResponseList is not empty then call the api and update the barrel details
      if(updatedBarrelResponseList.length > 0) {
        let response = null;
        if(requestType === Constant.RequestType.OFFER_REQUEST)
          response = await OrderApiService.updateReservedBarrelDetails(requestId,updatedBarrelResponseList);
        else if(requestType === Constant.RequestType.ORDER_REQUEST)
          response = await OrderApiService.updateOrderReservedBarrelDetails(requestId, orderItemId, updatedBarrelResponseList);
        else if(requestType === Constant.RequestType.SAMPLE_REQUEST) 
          response = await OrderApiService.updateSampleOrderReservedBarrelDetails(requestId, updatedBarrelResponseList);
      }
       CommonService.Toast.fire({
              title: "Barrel Details Updated Successfully",
              icon: "success",
            });
      // Close barrel selection modal
      handleCloseBarrelSelection();
      
      } catch (error) {
        console.error("Error updating barrel reservations:", error);
      }
    }
  };

  const calculateAndSetSelectedQuantity = (updatedBarrels: any) => {
    // Calculate the selected quantity based on the updated barrelDetails
    const selectedQuantity = updatedBarrels.reduce((acc, lot) => {
      return acc + lot.barrelDetails.filter((barrel: any) => barrel.barrelStatus === 'RESERVED').length;
    }, 0);
  
    setSelectedQuantity(selectedQuantity); // Set the selected quantity state
  };

   // handleQuantityChange method with debounce logic
   const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const quantity = parseInt(e.target.value);
    setSelectedQuantity(quantity);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout); // Clear any existing timeout
    }

    const timeoutId = setTimeout(() => {
      if (!isNaN(quantity) && quantity >= 0) {
        // Step 1: Calculate the total available barrels
        let totalAvailableBarrels = 0;
        barrelDetails.forEach((lot: any) => {
          totalAvailableBarrels += lot.barrelDetails.filter(
            (barrel: any) => barrel
          ).length;
        });

        // Step 2: Check if there are enough barrels available
        if (quantity > totalAvailableBarrels) {
          CommonService.Toast.fire({
            title: "You don't have enough available barrels.",
            icon: "error",
          });
          return; // Early return if not enough barrels
        }

        // Step 3: Select the required number of barrels and deselect others
        let remainingToSelect = quantity;
        setBarrelDetails((prevBarrels) => {
          const updatedBarrels = prevBarrels.map((lot: any) => {
            return {
              ...lot,
              barrelDetails: lot.barrelDetails.map((barrel: any) => {
                if (remainingToSelect > 0) {
                  // Select this barrel
                  remainingToSelect--;
                  return {
                    ...barrel,
                    barrelStatus: "RESERVED", // Change status to 'RESERVED'
                  };
                } else {
                  // Deselect this barrel if it's selected
                  return {
                    ...barrel,
                    barrelStatus: "LISTED", // Reset to 'LISTED' if not selected
                  };
                }
              }),
            };
          });

          // Step 4: Update selected quantity based on requested quantity
          setSelectedQuantity(quantity);

          return updatedBarrels;
        });
      }
    }, 500); // 500ms debounce delay

    setDebounceTimeout(timeoutId); // Save the timeout ID for cleanup
  };

  
   // First, let's filter out lots that have no reserved barrels
   const lotsWithReservedBarrels = lotNos?.filter(lotId => {
    const barrels = filteredBarrels(lotId);
    return barrels?.some(barrel => barrel.barrelStatus === "RESERVED");
  });


  const barrelCount = (lotNo:any) => {
    // Filter and count the barrels for the given lotNo
   return barrelDetails
        ?.flatMap((lot) => lot.barrelDetails)
        .filter((barrel) => barrel.lotNo === lotNo).length || 0;
    
  }

  const reservedBarrelCount = (lotNo:any) => {
    // Filter and count the barrels for the given lotNo
   return barrelDetails
        ?.flatMap((lot) => lot.barrelDetails)
        .filter((barrel) => barrel.lotNo === lotNo && barrel.barrelStatus === "RESERVED").length || 0;
  }

  return (
    <>
      <div className="barrel-selection-offcanvas-wrapper">
        <div
          className="barrel-selection-left"
          onClick={handleCloseBarrelSelection}
        ></div>
        <div className="barrel-selection-inner">
         <div className="header" style={{paddingBottom: invalidQuantity ? "25px": "15px"}}>
            <div className="header-inner d-flex justify-content-between align-items-start">
              <div>
                <p className="main-heading mb-1">{inventoryName}</p>
                <p className="sub-heading">By: {companyName}</p>
              </div>
              <button
                onClick={handleCloseBarrelSelection}
                className="close-btn"
              >
                <CloseIcon />
              </button>
            </div>
            <div className="sub-header-inner">
              <div className="row align-items-center flex-wrap m-0 g-2">
                <div className="col ps-0">
                  <div className="search-input-wrapper position-relative" style={{minWidth:220}}>
                    <SearchIcon
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "15px",
                        transform: "translateY(-50%)",
                      }}
                    />
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={handleSearch}
                      placeholder="Search barrel numbers"
                      className="form-control flex-grow"
                    />
                  </div>
                </div>
                {['IN_TRANSIT','OWNERSHIP_TRANSFERRED','CLOSED'].includes(currentStatus) && !isLoading && (
                  <div className="d-flex justify-content-end col-xl-6 pe-0">
                    <span className="selected-barrels-count--wrapper">
                      Total<span> {totalReservedCount} </span>{totalReservedCount > 1 ? "Barrels" : "Barrel"}
                    </span>
                </div>)}

              {!['IN_TRANSIT','OWNERSHIP_TRANSFERRED','CLOSED'].includes(currentStatus) && !isLoading  && (
                <div className="col pe-0">
                  <div className="d-flex align-items-center gap-4 ms-auto justify-content-end">
                   
                    <div className="header-field-wrapper">
                    <input
          type="checkbox"
          className="cursor-pointer"
          id="select-all-lots" 
          onChange={(e) => handleSelectAllLots(e.target.checked)}
          checked={barrelDetails.every((lot: any) =>
            lot.barrelDetails.every((barrel: Barrel) => barrel.barrelStatus === "RESERVED")
          )}
        />
      <label className="header-field-label text-nowrap ms-2 cursor-pointer" htmlFor="select-all-lots" >
        
        Select All
      </label>
    </div>
    <div className="header-field-wrapper d-flex align-items-center gap-2">
                      <label className="header-field-label text-nowrap">
                        Selected Quantity:
                      </label>
                      <div className="d-flex align-items-center gap-2 position-relative">
                        <div style={{maxWidth:80}}>
                        <input
                          type="number"
                          value={selectedQuantity}
                          onChange={handleQuantityChange}
                          className="form-control"
                          style={{ border: invalidQuantity ? "2px solid red" : "1px solid #ccc" }}
                          min="0"
                        />
                        </div>
                        {requestType != Constant.RequestType.SAMPLE_REQUEST &&  (<span style={{
                            fontSize: 16,
                            fontWeight: 600,
                          }}>/ {requiredQuantity}</span>)}
                        {invalidQuantity && <span className="barrel-error-msg position-absolute top-100 end-0 text-nowrap"><WarningIcon /> Please select a number of items equal to the specified quantity.</span> }
                        
                      </div>
                    </div>
                  </div>
                </div>)}

              </div>
            </div>
          </div>


          {isLoading && <div style={{ height: "240px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <Customspinner />
                          </div>}

        
         {!isLoading && !['IN_TRANSIT','OWNERSHIP_TRANSFERRED','CLOSED'].includes(currentStatus) && (<Tab.Container
            id="underlined-tabs"
            activeKey={defaultActiveTab}

            onSelect={(lotNo) => {
            setDefaultActiveTab(lotNo)
            setSelectedLotNo(lotNo);
            }}
          >
            <Nav
              variant="underline"
              className="nav-tabs-underlined"
              style={{
                display: "flex",
                overflowX: "auto",
                scrollBehavior: "smooth",
                flexGrow: 1,
              }}
            >
              {lotNos.map((lotNo) => (
                <Nav.Link eventKey={lotNo} key={lotNo}>
                 Lot #{lotNo} ({reservedBarrelCount(lotNo)}/{barrelCount(lotNo)})
                </Nav.Link>
              ))}
            </Nav>
            <Tab.Content>
              {lotNos.map((lotNo) => (
                <Tab.Pane eventKey={lotNo} key={lotNo}>
              
                  <div className="barrel-selection-body">
                 {filteredBarrels(lotNo).length > 0 && (<div className="body-field-wrapper">
  <input
  id={`select-all-${lotNo}`}
  type="checkbox"
  className="cursor-pointer"
  onChange={(e) => handleSelectAll(lotNo, e.target.checked)}
  checked={
    filteredBarrels(lotNo)?.every(
      (barrel: Barrel) => barrel.barrelStatus === "RESERVED"
    ) || false
  }
/>
      <label className="body-field-label ms-2 cursor-pointer"   htmlFor={`select-all-${lotNo}`}>
        Select All
      </label>
    </div>)}
                    <div className="barrel-list-wrapper">
                      <div className="barrel-list-inner">
                      {filteredBarrels(lotNo)?.map((barrel) => (
                     <div
                            key={barrel.barrelId} // inventoryId
                            className={`barrel-item-wrapper ${
                              barrel.barrelStatus === "RESERVED" ? "selected" : ""
                            }`}
                            onClick={() => handleBarrelSelect(lotNo, barrel.barrelId)}
                          >
                            {barrel.barrelStatus === "RESERVED" ? (
                              <DoneIcon/>
                            ) : (
                              <AddSharpIcon />
                            )}
                            <span className="ms-1">{barrel.barrelNumber} 
                              {/* <span>R-{barrel.row}</span>
                              <span>D-{barrel.depth}</span>
                              <span>H-{barrel.height}</span> */}
                              <span className='lot-id-box ms-1'>R-{barrel.row} | D-{barrel.depth} | H-{barrel.height}</span>
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="barrel-selection-footer text-center">
                    <button className="done-btn" onClick={handleDone}>
                      <DoneIcon /> Done
                    </button>
                  </div>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Tab.Container>)}

          {!isLoading && ['IN_TRANSIT','OWNERSHIP_TRANSFERRED','CLOSED'].includes(currentStatus) && (
          <>
    <div className="barrel-selected-body">
      {lotsWithReservedBarrels?.map((lotId) => (
        <div key={lotId} className="selected-barrel-list-wrapper">
          <p className="selected-barrel-list-heading">
            Lot #{lotId} ({filteredBarrels(lotId)?.filter((barrel) => barrel.barrelStatus === "RESERVED")?.length})
          </p>
          <div className="selected-barrel-list-inner">
            {filteredBarrels(lotId)?.map((barrel) => {
              if (barrel.barrelStatus !== "RESERVED") return null;
              
              return (
                <div
                  key={barrel.barrelId}//inventoryId
                  className="selected-barrel-list-item-wrapper"
                >
                  {barrel.barrelNumber}
                  <span className="lot-id-box ms-1">
                    R-{barrel.row} | D-{barrel.depth} | H-{barrel.height}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
        <div className="barrel-selection-footer text-center">
            <button 
              onClick={handleCloseBarrelSelection}
              className="done-btn"
            >
              <DoneIcon/> Done
            </button>
          </div>
        </>
          )}
        </div>
      </div>
    </>
  );
};

export default BarrelSelectionPanel;