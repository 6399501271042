import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import { CommonService } from "../../Service/CommonService";
import "../Dynamic/UserList.scss";
import React from "react";

import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
} from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import CurrencyFormatter from "../Static/CurrencyFormatter";
import { OrderApiService } from "../../Service/OrderApiService";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import Constant from "../../Constant";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import StatusDropdown from "../Common/StatusDropdown";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, Skeleton, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Spinner from "../Common/Spinner";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import TrashIcon from "../../assets/images/icons/Trash.svg";



const OfferRequest = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [tableData, setTableData] = useState<any[]>([]);
  const [reviewUserCount, setReviewCount] = useState(0);
  const [search, setSearch] = useState("");
  let approved: any[] = [];
  const approvedUserRef = useRef(approved);
  const history = useHistory();
  const disableAction = useRef(false);
  const isNeedReviewClicked = useRef(false);
  const selectedRows = useRef(approved);
  const rejectionNote = useRef("");
  const statusDetailsRef = useRef({ id: "", status: "", rejectionNote: "" ,companyId: "", cancellationReason: "", trackNumber: "", quantity: ""});
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [rejectionNoteValue, setNote] = useState<string>("");
  const [error, setError] = useState<string>("");
  const { id } = useParams<{ id: string }>();
  const params = id === undefined ? CommonService.getCompanyId() : id;
   const [files, setFiles] = useState<File[]>([]);
   const[currentStatus, setCurrentStatus] = useState('');
  const toTitleCase = (str: string) => {
    return str
      .replace(/_/g, " ") // Replace underscores with spaces
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const controllerRef = useRef<AbortController | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [filter, setFilter] = useState<any>({
    inventoryName: null,
    requestNumber: null,
    companyName: null,
    firstName: null,
    requestedOn: null,
    quantity: null,
    pricePerBarrel: null,
    totalAmount: null,
    status: null,
    modifiedOn: null,
  });
  // Define the localeText object based on the isLoading flag
  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };

    const [trackNumberValue, setTrackNumber] = useState<string>("");
    const [isRejected, setIsRejected] = useState(false);
    const [isInTransit, setIsInTransit] = useState(false);

  const fetchAllActiveOfferRequests = async (page:any,size:any, filter: any) => {
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      setIsLoading(true);
      setTableData([]);
      // Cancel the previous request if any
      if (controllerRef.current) {
        controllerRef.current.abort();
      }

      // Store the current controller to cancel the request if needed
      controllerRef.current = controller;

      const response = await OrderApiService.getActiveOfferRequests(page,size,false, filter, signal);
      approved = Object.values(response.data.content); // Convert object to array
      approvedUserRef.current = approved;
      setTotalCount(response.data.totalElements);
      // Access updated value of approvedUser using approvedUserRef.current
      setTableData(approvedUserRef.current);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleRowClick = (params) => {
    const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";
    history.push(`${linkPrefix}/view-offer-request/${params.row.id}`);
  }

  const openOfferRequests = async (page,size, filter: any) => {
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      setIsLoading(true);
      setTableData([]);
      // Cancel the previous request if any
      if (controllerRef.current) {
        controllerRef.current.abort();
      }

      // Store the current controller to cancel the request if needed
      controllerRef.current = controller;

      const response = await OrderApiService.getCompanyWiseOfferRequests(
        params,
        false,CommonService.getDashboardPrefrence(),page, size, filter, signal
      ); // Applies the status filter only if isFetchOpenRequests is true;

      setTableData(Object.values(response.data.content)); // Convert object to array
      setTotalCount(response.data.totalElements);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {

    if (currentPath === "/master/my-warehouse/offer-request/" + id || currentPath === "/user/my-warehouse/offer-request")
      openOfferRequests(page,pageSize, filter);
    else 
      fetchAllActiveOfferRequests(page,pageSize, filter);

  }, []);


  //add tootip
  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  //this code is for dropdown
  const [rowStatusMap, setRowStatusMap] = React.useState<{
    [key: string]: string;
  }>({});

  const [isCancelled, setIsCancelled] = useState(false);
  const [cancellationReason, setCancellationReason] = useState('');


  const handleCancellationReasonChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCancellationReason(event.target.value);
    setError("");

  };

   const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
    
          const newFiles = Array.from(event.target.files);
          setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    
          // Clear the input value to allow selecting the same file again
          event.target.value = "";
        }
      };

      const handleDeleteFile = (indexToDelete: number) => {
        setFiles((prevFiles) =>
          prevFiles.filter((_, index) => index !== indexToDelete)
        );
      };
    
     

  const handleStatusChange = async (newStatus: string, rowId: string, currentStatus:string, quantity:any) => {
    // const statusMap: { [key: string]: string } = {
    //   "1": "Accepted",
    //   "2": "Pending Review",
    //   "3": "Rejected",
    //   "4": "Shipped",
    //   "5": "Cancelled",
    //   "6": "Delivered",
    // };

    // const newStatus = statusMap[eventKey];

    const orderStatus = newStatus?.replace(/ /g, "_");

    //create the object to send
    const sd = {
      id: rowId,
      status: orderStatus.toUpperCase(),
      rejectionNote: rejectionNote.current,
      companyId: CommonService.getCompanyId(),
      cancellationReason: '',
      trackNumber : '',
      quantity: quantity,

    };
    
    setCurrentStatus(currentStatus);
    statusDetailsRef.current = sd;
    if (statusDetailsRef.current.status === Constant.OrderStatus.REJECTED) {
      setIsRejected(true);
      handleShow();
    } else if(statusDetailsRef.current.status === Constant.OrderStatus.CANCELLED) {
      setIsCancelled(true);
      handleShow();
    } else if (statusDetailsRef.current.status === Constant.OrderStatus.IN_TRANSIT) {
      setIsInTransit(true);
      handleShow();
    } else {
      changeOfferStatus();
    }
  };

  const handleClose = () => {
    setShow(false);
    setError("");
    setNote("");
    setIsCancelled(false);
    setCancellationReason('');
    setIsRejected(false);
    setIsInTransit(false);
    setCurrentStatus('');
    setTrackNumber('');
    setFiles([]);
  };

   const handleTrackingNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setTrackNumber(event.target.value);
      setError("");
    };

    const uploadOfferRequestDocs = async () => {
      
          const formData = new FormData();
          let response;
          let offerRequestDoc = {};
      
          if (files && Array.isArray(files)) {
            files.forEach(function (file: any) {
              formData.append("file", file);
            });
          }
      
          const fileNames = files.map((file) => file.name).join(", ");
      
          offerRequestDoc = {
            offerRequestId: id,
            description: "uploaded " + fileNames + " document",
            userId: CommonService.getUserId(),
            title: fileNames,
            companyId: CommonService.getCompanyId(),
          };
      
          formData.append(
            "param",
            new Blob([JSON.stringify(offerRequestDoc)], {
              type: "application/json",
            })
          );
      
          response = await OrderApiService.uploadOfferRequestDocs(formData);
    
        };


        const handleButtonClick = () => {
          if (fileInputRef.current) {
            fileInputRef.current.click();
          }
        };

  const changeOfferStatus = async () => {
    if (statusDetailsRef.current.status === Constant.OrderStatus.REJECTED && rejectionNoteValue.trim().length == 0) {
      setError("Please enter rejection note");
      return;
    } else if(statusDetailsRef.current.status === Constant.OrderStatus.CANCELLED && cancellationReason.trim().length == 0) {
      setError("Please enter cancellation reason");
      return;
    } else if(statusDetailsRef.current.status === Constant.OrderStatus.IN_TRANSIT && trackNumberValue.trim().length == 0) {
      setError("Please enter track number");
      return;
    }

    try {
      setIsLoading(true);
      statusDetailsRef.current.rejectionNote = rejectionNote.current;
      statusDetailsRef.current.cancellationReason = cancellationReason;
      statusDetailsRef.current.trackNumber = trackNumberValue;
      await OrderApiService.changeOfferStatus(statusDetailsRef.current);
      const rowIndex = tableData.findIndex(
        (row) => row.id === statusDetailsRef.current.id
      );

      if (rowIndex !== -1) {
        // Create a new array with the updated status for the found object
        const updatedTableData = [...tableData];
        updatedTableData[rowIndex] = {
          ...updatedTableData[rowIndex],
          status: statusDetailsRef.current.status,
          rejectionNote: statusDetailsRef.current.rejectionNote,
        };

        // Update the tableData state with the new array
        setTableData(updatedTableData);
        

      }
      if (statusDetailsRef.current.status === Constant.OrderStatus.IN_TRANSIT
        && (files.length > 0)) {
       uploadOfferRequestDocs();
     }
      setIsLoading(false);
      handleClose();
      CommonService.Toast.fire({
        title: "Offer status updated successfully",
        icon: "success",
      });
    } catch (error) {
      setIsLoading(false);


      if(statusDetailsRef.current.status == Constant.OrderStatus.ACCEPTED) {
        CommonService.Toast.fire({
          title: "Out of Stock, please change quantity",
          icon: "error",
        });
      }

      if(statusDetailsRef.current.status == Constant.OrderStatus.OWNERSHIP_TRANSFERRED) {
        CommonService.Toast.fire({
          title: "Please select the barrels to match the quantity",
          icon: "error",
        });
      }
     
    }
  };

  //formate the status
  function formatStatus(status: string): string {
    const formattedStatus = status
      .split(/[_\\]/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    return formattedStatus;
  }

  const columns: GridColDef[] = [
    {
      field: "requestNumber",
      headerName: "Request Number",
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative" style={{width:"120px"}}>
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    //value={filter.requestNumber}
                    defaultValue={filter.requestNumber}
                    onChange={(event) => handleFilterChange(event, "requestNumber")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
      minWidth: 170,
      flex:1,
      renderCell: (params: any) => <CellWithTooltip value={params.value || '-'}/>
    },
    {
      field: "inventoryName",
      headerName: "Inventory",
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative" style={{width:"120px"}}>
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    //value={filter.inventoryName}
                    defaultValue={filter.inventoryName}
                    onChange={(event) => handleFilterChange(event, "inventoryName")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
      minWidth: 170,
      flex:1,
      renderCell: (params: any) => <CellWithTooltip value={params.value}/>
    },
    ...(CommonService.isAdmin()
      ? [
      {
      field: "companyName",
      headerName: "Company Name",
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative" style={{width:"120px"}}>
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    //value={filter.companyName}
                    defaultValue={filter.companyName}
                    onChange={(event) => handleFilterChange(event, "companyName")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
      minWidth: 170,
      flex:1,
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
    }]
    : []),
    {
      field: "modifiedDate",
      headerName: "Modified On",
      headerClassName: "table-header border-0 my-2 h-100",
      minWidth: 170,
      flex:1,
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
      valueGetter: (value: any, row: any) => {
        const dateTimeString = row.modifiedDate ? row.modifiedDate : null;

        if (dateTimeString) {
          //call the method which formate the time
          return CommonService.formatDateTime(dateTimeString) ;
        }
      },
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative" style={{width:"120px"}}>
            <input
                type="date"
                className="form-control border mt-1"
                style={{ boxShadow: "none" }}
                id="birthdate"
                name="modifiedDate"
                placeholder="MM/DD/YYYY"
                //value={filter.modifiedOn}
                defaultValue={filter.modifiedOn}
                onChange={(event) => handleFilterChange(event, "modifiedOn")}
                onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
            />
            </div>
        </div>
      ),
    },
    {
      field: "createdDate",
      headerName: "Requested On",
      headerClassName: "table-header border-0 my-2 h-100",
      minWidth: 170,
      flex:1,
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
      valueGetter: (value: any, row: any) => {
        const dateTimeString = row.createdDate ? row.createdDate : null;

        if (dateTimeString) {
          //call the method which formate the time
          return CommonService.formatDateTime(dateTimeString);
        }
      },
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative" style={{width:"120px"}}>
            <input
                type="date"
                className="form-control border mt-1"
                style={{ boxShadow: "none" }}
                id="birthdate"
                name="birthdate"
                placeholder="MM/DD/YYYY"
                //value={filter.requestedOn}
                defaultValue={filter.requestedOn}
                onChange={(event) => handleFilterChange(event, "requestedOn")}
                onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
            />
            </div>
        </div>
      ),
    },
    ...(CommonService.isAdmin()
      ? [{
        field: "firstName",
        headerName: "Requested By",
        flex: 1,
        minWidth: 170,
        valueGetter: (value: any, row: any) =>
          `${row.firstName || ""} ${row.lastName || ""}`,
        headerClassName: "table-header border-0 my-2 h-100",
        renderHeader: (params) => (
          <div>
              <Typography
                  variant="body2"
                  sx={{ color: "#6F6F6F", fontSize: "14px" }}
              >
                  {params.colDef.headerName}
              </Typography>
              <div className="position-relative" style={{width:"120px"}}>
                  <input
                      type="text"
                      className="form-control border mt-1"
                      style={{ textIndent: "24px", boxShadow: "none" }}
                      placeholder="Search"
                      //value={filter.firstName}
                      defaultValue={filter.firstName}
                      onChange={(event) => handleFilterChange(event, "firstName")}
                      onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                  />
                  <SearchIcon
                      sx={{
                          color: "#6F6F6F",
                          position: "absolute",
                          top: "50%",
                          left: "8px",
                          transform: "translateY(-50%)",
                      }}
                  />
              </div>
          </div>
        ),
        renderCell: (params: any) => <CellWithTooltip value={params.value} />,
    }]
    : []),

    ...(!CommonService.isAdmin() && CommonService.getDashboardPrefrence() == "Buyer"
      ? [
    {
      field: "firstName",
      headerName: "Requested By",
      minWidth: 170,
      flex:1,
      valueGetter: (value: any, row: any) =>
        `${row.firstName || ""} ${row.lastName || ""}`,
      headerClassName: "table-header border-0 my-2 h-100",
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative" style={{width:"120px"}}>
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    //value={filter.firstName}
                    defaultValue={filter.firstName}
                    onChange={(event) => handleFilterChange(event, "firstName")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
    } ]
    : []),
    {
      field: "quantity",
      headerName: "Quantity",
      headerClassName: "table-header border-0 my-2 h-100",
      minWidth: 150,
      flex:1,
      renderCell: (params: any) => <CellWithTooltip value={params.value} />,
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative" style={{width:"120px"}}>
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    //value={filter.quantity}
                    defaultValue={filter.quantity}
                    onChange={(event) => handleFilterChange(event, "quantity")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
    },
    {
      field: "pricePerBarrel",
      headerName: "Offered Price",
      headerClassName: "table-header border-0 my-2 h-100",
      minWidth: 150,
      flex:1,
      renderCell: (params: any) => (
        <CurrencyFormatter
          amount={params.value}
          locale="en-US"
          currency="USD"
        />
      ),
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative" style={{width:"120px"}}>
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    //value={filter.pricePerBarrel}
                    defaultValue={filter.pricePerBarrel}
                    onChange={(event) => handleFilterChange(event, "pricePerBarrel")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
    },
    {
      field: "totalAmount",
      headerName: "Total Price",
      headerClassName: "table-header border-0 my-2 h-100",
      minWidth: 150,
      flex:1,
      renderCell: (params: any) => (
        <CurrencyFormatter
          amount={params.value}
          locale="en-US"
          currency="USD"
        />
      ),
      renderHeader: (params) => (
        <div>
            <Typography
                variant="body2"
                sx={{ color: "#6F6F6F", fontSize: "14px" }}
            >
                {params.colDef.headerName}
            </Typography>
            <div className="position-relative" style={{width:"120px"}}>
                <input
                    type="text"
                    className="form-control border mt-1"
                    style={{ textIndent: "24px", boxShadow: "none" }}
                    placeholder="Search"
                    //value={filter.totalAmount}
                    defaultValue={filter.totalAmount}
                    onChange={(event) => handleFilterChange(event, "totalAmount")}
                    onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                />
                <SearchIcon
                    sx={{
                        color: "#6F6F6F",
                        position: "absolute",
                        top: "50%",
                        left: "8px",
                        transform: "translateY(-50%)",
                    }}
                />
            </div>
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "table-header border-0 my-2 h-100",
      cellClassName: "status-badge",
      minWidth: 220,
      flex:1,
      renderCell: (params: any) => (
        <div className="cell-with-dropdown mb-2" onClick={(e) => e.stopPropagation()}>

          

          {(params.row?.status === "PENDING_REVIEW" || params.row?.status === "UNDER_REVIEW") && (
            <StatusDropdown
              currentStatus={params.row.status}
              onStatusChange={(newStatus) =>
                handleStatusChange(
                  newStatus,
                  params.row.id,
                  params.row?.status,
                  params.row?.quantity,
                )
              }
              disableCondition={CommonService.isAdmin() && params.row?.status === "UNDER_REVIEW" && params.row?.hasValidStatus || (CommonService.isUser() && params.row?.companyId != CommonService.getCompanyId())}
              allowedLabels={CommonService.isUser() ? ["Cancelled"] : (params.row?.hasValidStatus ? ["Under Review"] : params.row?.hasItemRejected ? ["Under Review", "Rejected"] : ["Under Review","Accepted", "Rejected"])}
            />
          )}


{/* {params.row?.status === "ACCEPTED" && (
                <StatusDropdown
                  currentStatus={params.row?.status}
                  onStatusChange={(newStatus) => {
                    handleStatusChange(newStatus,params.row.id,params.row?.status,params.row?.quantity,);
                  }}
                  disableCondition={CommonService.isUser() && params.row?.companyId != CommonService.getCompanyId()}
                  allowedLabels={CommonService.isAdmin() ? ["In Progress","Rejected"] : ["Cancelled"]}
                />
              )} */}

          {/* Accepted to Payment Received - Both User and Admin */}
          {["ACCEPTED","IN_PROGRESS", "CONTRACT_SENT", "INVOICE_SENT", "CONTRACT_EXECUTED","PAYMENT_RECEIVED"].includes( params.row?.status) && (
            <StatusDropdown
              currentStatus={formatStatus( params.row?.status)}
              onStatusChange={(newStatus) => handleStatusChange(newStatus, params.row.id, params.row?.status, params.row?.quantity,)}
              disableCondition={CommonService.isUser() && params.row?.companyId != CommonService.getCompanyId()}
              allowedLabels={CommonService.isAdmin() ? ["In Progress","Contract Sent", "Contract Executed", "Invoice Sent", "Payment Received","Ownership Transferred","Rejected"] : ["Cancelled"]} 
            />
          )}

{(params.row?.status === "OWNERSHIP_TRANSFERRED") && (
                <StatusDropdown
                  currentStatus={formatStatus(params.row?.status)}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus, params.row.id, params.row?.status, params.row?.quantity,)}
                  disableCondition={CommonService.isUser()}
                  allowedLabels={["In Transit","Rejected"]}
                />
              )}
    
    {(params.row?.status === "IN_TRANSIT") && (
                <StatusDropdown
                  currentStatus={formatStatus(params.row?.status)}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus, params.row.id, params.row?.status, params.row?.quantity,)}
                  disableCondition={CommonService.isUser()}
                  allowedLabels={["Rejected", "Closed"]}
                />
              )}

   {(params.row?.status === "CANCELLED" ||
                params.row?.status === "REJECTED" || params.row?.status === "CLOSED") && (
                <StatusDropdown
                  currentStatus={formatStatus(params.row?.status)}
                  onStatusChange={(newStatus) => {
                    handleStatusChange(newStatus,params.row.id, params.row?.status, params.row?.quantity,);
                  }}
                  disableCondition={true}
                  allowedLabels={[]}
                />
              )}

          {params.row.status === "REJECTED" && (
            <Tooltip
              title={`Rejection Note: ${params.row?.rejectionNote}`}
              arrow
            >
              <StickyNote2Icon
                className="note-icon cursor-pointer"
                style={{ color: "gray" }}
              />
            </Tooltip>
          )}
          {params.row.status === "CANCELLED" && (
            <Tooltip
              title={`Cancellation Reason: ${params.row?.cancellationReason}`}
              arrow
            >
              <StickyNote2Icon
                className="note-icon cursor-pointer"
                style={{ color: "gray" }}
              />
            </Tooltip>
          )}
        </div>
      ),
      renderHeader: (params) => (
        <>
            <div>
                <Typography
                    variant="body2"
                    sx={{ color: "#6F6F6F", fontSize: "14px" }}
                >
                    {params.colDef.headerName}
                </Typography>
                <div className="position-relative" style={{width:"120px"}}>
                    <Form.Select
                        style={{ cursor: "pointer",borderBottomColor:"#D1D1D1",marginTop:"4px" }}
                        value={filter.status}
                        className="form-control mb-1"
                        onChange={(event) => handleFilterChange(event, "status")}
                        onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                    >
                        <option value="">Select Status</option>
                        <option value="PENDING_REVIEW">Pending Review</option>
                        <option value="UNDER_REVIEW">Under Review</option>
                        <option value="ACCEPTED">Accepted</option>
                        <option value="REJECTED">Rejected</option>
                        <option value="CONTRACT_SENT">Contract Sent</option>
                        <option value="INVOICE_SENT">Invoice Sent</option>
                        <option value="PAYMENT_RECEIVED">Payment Received</option>
                        <option value="CONTRACT_EXECUTED">Contract Executed</option>
                        <option value="OWNERSHIP_TRANSFERRED">Ownership Transferred</option>
                        <option value="CANCELLED">Cancelled</option>
                        <option value="CLOSED">Closed</option>
                    </Form.Select>
                </div>
            </div>
        </>
      ),
    },
  ];
//.filter(Boolean) as GridColDef[]
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);

  const handleSelectionModelChange = (newSelection: GridRowId[]) => {
    setSelectionModel(newSelection);
    const newSelectedRows = newSelection.map((id: GridRowId) => {
      return tableData.find((row: any) => row.id === id); // Assuming each row object has an 'id' property
    });
    selectedRows.current = newSelectedRows;
    let flag: any = false;
    flag = selectedRows.current.some((user) => user.status === "INVITED");
    disableAction.current = flag;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value);
    setError("");
    rejectionNote.current = event.target.value;
  };

  // // Filter rows based on search query
  // const filteredRows = tableData.filter((row) =>
  //   CommonService.isAdmin()
  //     ? row.companyName?.toLowerCase().includes(search?.toLowerCase()) ||
  //       row.firstName?.toLowerCase().includes(search?.toLowerCase()) ||
  //       row.lastName?.toLowerCase().includes(search?.toLowerCase()) ||
  //       row.inventoryName?.toLowerCase().includes(search?.toLowerCase()) ||
  //       (row.firstName + " " + row.lastName)
  //         .toLowerCase()
  //         .includes(search?.toLowerCase())
  //     : row.inventoryName?.toLowerCase().includes(search?.toLowerCase())
  // );

  // Map filtered rows with unique IDs
  const tableDataWithIds = tableData.map((rowData, index) => ({
    ...rowData,
  }));

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, field: keyof any) => {
    const value = event.target.value;

    // Prepare a temporary filter object with the updated field value
    const tempFilter = {
      ...filter, // Spread existing filter state
      [field]: field === "status" && value === "" ? null : value, // Update field only if the condition is met
    };
  
    // Update the filter state
    setFilter(tempFilter);

    // Load the service requests with updated filters
    if (currentPath === "/master/my-warehouse/offer-request/" + id || currentPath === "/user/my-warehouse/offer-request")
      openOfferRequests(page,pageSize, tempFilter);
    else 
      fetchAllActiveOfferRequests(page,pageSize, tempFilter);
  };

  const handleBack = () => {
    if (currentPath.startsWith("/master/offer-request")) {
      history.push(`/master/dashboard`);
    } else if (currentPath.startsWith("/master/my-warehouse/offer-request/"))
      history.push(`/master/company-dashboard/` + id);

    // history.goBack();
  };

  return (
    <>
      <div className="usersList pt-0" style={{paddingBottom:CommonService.isAdmin() ? 53 : ""}}>
        <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center p-3">
        {CommonService.isAdmin() && (<IconButton
                  onClick={handleBack}
                  aria-label="ArrowBackIcon"
                  sx={{
                    backgroundColor: "#FFF",
                    "&:hover": { backgroundColor: "#FFF" },
                  }}
                >
                  <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
                </IconButton>)}
          {CommonService.isAdmin() && (<div className="header--title me-2">
            <h1 className="title mb-0 ms-2">
              Offer Requests
            </h1>
          </div>)}
          <div className="header--menu ms-auto mb-2">
            <div className="header--menu-items row d-flex justify-content-end">
              <div className="col-auto mb-2">
                {/* <SearchBar search={search} setSearch={setSearch} /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="user-table-container">
          <div className="userTable">
          <DataGrid
            rows={tableDataWithIds}
            columns={columns}
            className="data-grid"
            initialState={{
              pagination: {
                paginationModel: { page: page, pageSize: pageSize },
              },
            }}
            pagination
            paginationModel={{
              page,
              pageSize,
            }}
            rowCount={totalCount}
            paginationMode="server"
            onPaginationModelChange={(newPaginationModel) => {
              setPage(newPaginationModel.page);
              setPageSize(newPaginationModel.pageSize);

              if (currentPath === "/master/my-warehouse/offer-request/" + id || currentPath === "/user/my-warehouse/offer-request")
                openOfferRequests(newPaginationModel.page, newPaginationModel.pageSize, filter);
              else 
                fetchAllActiveOfferRequests(newPaginationModel.page, newPaginationModel.pageSize, filter);

            }}
            pageSizeOptions={[10, 20, 50, 100]}
            localeText={localeText}
            // checkboxSelection
            disableRowSelectionOnClick={true}
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={handleSelectionModelChange}
            onRowClick={handleRowClick}
            loading={isLoading}
            disableColumnResize
            // getRowClassName={getRowClassName}
          />
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        className="modal__wd modal__wd--submitSellReq"
      >
        <Modal.Header className="flex-column align-items-start">
          <Modal.Title>Update Offer Request Status</Modal.Title>
          <p className="modal-text">
          {isCancelled && ((["PENDING_REVIEW", "UNDER_REVIEW"].includes(currentStatus)) ? "Are you sure you want to cancel?" : "Are you sure you want ot cancel? A cancellation charge may apply.")}
          {isRejected && ("Are you sure, you want to change status to Rejected ?")}
          {isInTransit && ("Please enter Tracking Number")}
          </p>
        </Modal.Header>
        <Modal.Body>
          <div className="form__inner">
          {isCancelled && (
            <textarea
            className={`form-control ${error ? "is_invalid" : ""}`}
            aria-label="With textarea"
            value={cancellationReason}
            onChange={handleCancellationReasonChange}
            rows={8}
            style={{ resize: "none" }}
            placeholder="Enter Cancellation Reason..."
          />
          )}
          {isRejected &&(<textarea
              className={`form-control ${error ? "is_invalid" : ""}`}
              aria-label="With textarea"
              value={rejectionNoteValue}
              onChange={handleInputChange}
              rows={8}
              style={{ resize: "none" }}
              placeholder="Enter rejection note ..."
            />)}
            {isInTransit && ( <><label className="modal-body__title mb-1">Track Number</label><Form.Control
                          className={`form-control ${error ? "is_invalid" : ""}`}
                          type="text"
                          value={trackNumberValue}
                          onChange={handleTrackingNumberChange}
                          placeholder="Enter Tracking Number..." />
                          </>
                          )}
            {error && <div className="error-message">{error}</div>}

            {isInTransit && (<div className="d-flex align-items-center h-100 mt-2">
              <div className="create-ticket-attachement-wrapper d-flex flex-wrap align-items-center gap-1">
                <IconButton
                  type="button"
                  aria-label="submit"
                  sx={{
                    transform: "rotate(45deg)",
                    padding: "8px",
                  }}
                  onClick={handleButtonClick}
                >
                  <AttachFileIcon />
                </IconButton>
                <span className="cursor-pointer" onClick={handleButtonClick}>
                  Upload Document
                </span>
                <div className="d-flex align-items-center gap-2 flex-wrap">
                  {files?.map((file, index) => (
                    <div
                      className="file__upload--info d-flex align-items-center mb-1 me-2"
                      key={index}
                    >
                      <div className="file-name me-3">{file.name}</div>
                      <div className="file-delete">
                        <img
                          src={TrashIcon}
                          alt="TrashIcon"
                          onClick={() => handleDeleteFile(index)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div style={{ position: "relative" }}>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    multiple
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="btn-group row d-flex justify-content-between w-100">
            <div className="col-6 ps-0">
              <button className="secondary-btn w-100" onClick={handleClose} disabled={isLoading}>
              {isInTransit ? "No" : "Cancel"}
              </button>
            </div>

            <div className="col-6 pe-0">
              <button className="primary-btn w-100" onClick={changeOfferStatus} disabled={isLoading}>
            {isLoading && <Spinner/>} {isCancelled && "Cancel Request"} {isRejected && "Reject Request"} {isInTransit && "Yes"}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OfferRequest;
