import React, { useEffect, useRef, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useParams } from "react-router";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import { CommonService } from "../../Service/CommonService";
import "../Dynamic/InventoryCSV.scss";
import SearchIcon from "@mui/icons-material/Search";
import { InventoryApiService } from "../../Service/InventoryApiService";
import UploadCSV from "../popups/UploadCSV";
import { CookieService } from "../../Service/CookieService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, Skeleton, Tooltip, Typography } from "@mui/material";
import SearchBar from "../Static/SearchBar";
import { useHistory, useLocation } from "react-router";
import ViewDocs from "../popups/ViewDocs";
import CurrencyFormatter from "../Static/CurrencyFormatter";
import { Button as BootstrapButton, Form } from "react-bootstrap";
import InfoIcon from "@mui/icons-material/Info";
import { Link } from "react-router-dom";

const InventoryListing: React.FC = () => {
    const location = useLocation();
    const currentPath = location.pathname;
    const [isLoading, setIsLoading] = useState(false);
    // Define the localeText object based on the isLoading flag
    const localeText = {
        noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
    };
    const history = useHistory();
    const [search, setSearch] = useState("");
    const userRole = CookieService.getCookie("userRole");
    const { id } = useParams<{ id: string }>();
    const params =
        id === undefined && CommonService.isUser()
            ? CommonService.getCompanyId()
            : id;
    const [tableData, setTableData] = useState<any[]>([]);
    const [inventoryCsvWithDocs, setInventoryCsvWithDocs] = useState<any>(null);
    const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);
    const [service, setService] = useState<string>("");
    const [totalCount, setTotalCount] = useState(0);
    const controllerRef = useRef<AbortController | null>(null);
    const [activeButton, setActiveButton] = useState<string>("barrels");
    const activeButtonRef = useRef(activeButton);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [filter, setFilter] = useState<any>({
        age: null,
        lotNo: null,
        batchId: null,
        title: null,
        ownerName: null,
        producer: null,
        dsp: null,
        fillDate: null,
        isAvailableForSell: null,
        lotQuantity: null,
        initialQuantity: null,
        price: null,
        facility: null,
        rackhouse: null,
    });

    useEffect(() => {
        fetchInventoryListingData(
            paginationModel.page,
            paginationModel.pageSize,
            filter
        );
    }, []);

    const fetchInventoryListingData = async (page: any, size: any, filter: any) => {
        const controller = new AbortController();
        const signal = controller.signal;

        try {
            setIsLoading(true);
            setTableData([]);

            const response: any = await InventoryApiService.getActiveInventoryListings(params, page, size, filter, signal);
            
            setTableData(Object.values(response.data.content));
            setTotalCount(response.data.totalElements);
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false);
        }
    };

    const handleFilterChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
        field: keyof any
    ) => {
        const value = event.target.value;

        const tempFilter = {
            ...filter,
            [field]: value,
        };

        setFilter(tempFilter);
        fetchInventoryListingData(
            paginationModel.page,
            paginationModel.pageSize,
            tempFilter
        );
    };

    //add tooltip
    const CellWithTooltip = ({ value }: { value: string | number }) => {
        return (
            <Tooltip arrow title={value?.toString()}>
                <span>{value?.toString()}</span>
            </Tooltip>
        );
    };

    const columns: GridColDef[] = [
        {
            field: "productName",
            headerName: "Inventory",
            flex: 1,
            minWidth: 280,
            headerClassName: "table-header border-0 my-2 h-100",
            renderHeader: (params) => (
                <div>
                    <Typography
                        variant="body2"
                        sx={{ color: "#6F6F6F", fontSize: "14px" }}
                    >
                        {params.colDef.headerName}
                    </Typography>
                    <div className="position-relative" style={{ width: "200px" }}>
                        <input
                            type="text"
                            className="form-control border mt-1"
                            style={{ textIndent: "24px", boxShadow: "none" }}
                            placeholder="Search"
                            defaultValue={filter.productName}
                            onChange={(event) => handleFilterChange(event, "productName")}
                            onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                        />
                        <SearchIcon
                            sx={{
                                color: "#6F6F6F",
                                position: "absolute",
                                top: "50%",
                                left: "8px",
                                transform: "translateY(-50%)",
                            }}
                        />
                    </div>
                </div>
            ),
            renderCell: (params: any) => <CellWithTooltip value={params.value} />
        },
        {
            field: "price",
            headerName: "Price/Barrel",
            flex: 1,
            minWidth: 140,
            headerClassName: "table-header border-0 my-2 h-100",
            valueGetter: (value: any, row: any) => row.price ? `$${parseFloat(row.price).toFixed(2)}` : "$0.00",
            renderCell: (params: any) => <CellWithTooltip value={params.value} />
        },
        {
            field: "fee",
            headerName: "Commission Fee/Barrel",
            headerClassName: "table-header border-0 my-2 h-100",
            flex: 1,
            minWidth: 192,
            valueGetter: (value: any, row: any) => row.fee ? `$${parseFloat(row.fee).toFixed(2)}` : "$0.00",
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
            field: "availableBarrels",
            headerName: "Available Barrels",
            flex: 1,
            minWidth: 140,
            headerClassName: "table-header border-0 my-2 h-100",
            valueGetter: (value: any, row: any) => {
                const count = row.availableBarrels ?? 0;
                return `${count} ${count === 1 || count === 0 ? "Barrel" : "Barrels"}`;
            },
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
            field: "reservedBarrels",
            headerName: "Reserved Barrels",
            flex: 1,
            minWidth: 140,
            headerClassName: "table-header border-0 my-2 h-100",
            valueGetter: (value: any, row: any) => {
                const count = row.reservedBarrels ?? 0;
                return `${count} ${count === 1 || count === 0 ? "Barrel" : "Barrels"}`;
            },
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
            field: "soldBarrels",
            headerName: "Sold Barrels",
            flex: 1,
            minWidth: 140,
            headerClassName: "table-header border-0 my-2 h-100",
            valueGetter: (value: any, row: any) => {
                const count = row.soldBarrels ?? 0;
                return `${count} ${count === 1 || count === 0 ? "Barrel" : "Barrels"}`;
            },
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
            field: "fillDate",
            headerName: "Age",
            flex: 1,
            minWidth: 157,
            headerClassName: "table-header border-0 my-2 h-100",
            valueGetter: (value: any, row: any) => {
                const currentDate = new Date();
                const fillDate = new Date(row.fillDate); // Use the fillDate field

                // Calculate the difference in years and months
                let years = currentDate.getFullYear() - fillDate.getFullYear();
                let months = currentDate.getMonth() - fillDate.getMonth();

                // Adjust for negative months (if current month is earlier than the fill month)
                if (months < 0) {
                    years--;
                    months += 12;
                }

                // Format the result as 'X years Y months'
                return `${years} years ${months} months`;
            },
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
            field: "facility",
            headerName: "Facility",
            flex: 1,
            minWidth: 165,
            headerClassName: "table-header border-0 my-2 h-100",
            renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
            field: "lotNo",
            headerName: "Lot IDs",
            flex: 1,
            minWidth: 250,
            headerClassName: "table-header border-0 my-2 h-100",
            renderHeader: (params) => (
                <div>
                    <Typography
                        variant="body2"
                        sx={{ color: "#6F6F6F", fontSize: "14px" }}
                    >
                        {params.colDef.headerName}
                    </Typography>
                    <div className="position-relative" style={{ width: "180px" }}>
                        <input
                            type="text"
                            className="form-control border mt-1"
                            style={{ textIndent: "24px", boxShadow: "none" }}
                            placeholder="Search"
                            defaultValue={filter.lotNo}
                            onChange={(event) => handleFilterChange(event, "lotNo")}
                            onClick={(event) => event.stopPropagation()} // Prevent event from bubbling up
                        />
                        <SearchIcon
                            sx={{
                                color: "#6F6F6F",
                                position: "absolute",
                                top: "50%",
                                left: "8px",
                                transform: "translateY(-50%)",
                            }}
                        />
                    </div>
                </div>
            ),
            // renderCell: (params: any) => <CellWithTooltip value={params.value} />,
            renderCell: (params: any) => {
                const lotIds = params.value ? params.value.split(", ") : []; // Split into array
            
                return (
                    <div className="lot-id-list-wrapper d-flex flex-nowrap gap-2 h-100 align-items-center overflow-auto">
                        {lotIds.map((lot, index) => (
                            <div key={index} className="lot-id-item px-2 py-1 border rounded">
                                <CellWithTooltip value={lot} />
                            </div>
                        ))}
                    </div>
                );
            }                    
        },
    ].filter(Boolean) as GridColDef[];


    const handleRowClick = (params) => {
        const linkPrefix = userRole === "SUPER_ADMIN" ? "/master" : "/user";
        history.push(`${linkPrefix}/view-inventory-listing-details/${params.row.id}`);
    }

    // Map filtered rows with unique IDs
    const tableDataWithIds = tableData.map((rowData, index) => ({
        ...rowData,
    }));

    const handleBack = () => {
        if (currentPath.startsWith("/master/inventory-listing")) {
          history.push(`/master/dashboard`);
        }
        // history.goBack();
      };

    return (
        <div className="usersList pt-0" style={{paddingBottom:CommonService.isAdmin() ? 53 : ""}}>
            <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center p-3">
        {CommonService.isAdmin() && (<IconButton
                  onClick={handleBack}
                  aria-label="ArrowBackIcon"
                  sx={{
                    backgroundColor: "#FFF",
                    "&:hover": { backgroundColor: "#FFF" },
                  }}
                >
                  <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
                </IconButton>)}
          {CommonService.isAdmin() && (<div className="header--title me-2">
            <h1 className="title mb-0 ms-2">
              All Inventories
            </h1>
          </div>)}
          <div className="header--menu ms-auto mb-2">
            <div className="header--menu-items row d-flex justify-content-end">
              <div className="col-auto mb-2">
                {/* <SearchBar search={search} setSearch={setSearch} /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="user-table-container">
            <div className="userTable inventory-listing-table-wrapper">
                <DataGrid
                    rows={tableDataWithIds}
                    columns={columns}
                    className="data-grid data-grid__myinventories"
                    pagination
                    paginationMode="server"
                    paginationModel={paginationModel}
                    onPaginationModelChange={(newPaginationModel) => {
                        setPaginationModel(newPaginationModel);
                        fetchInventoryListingData(
                            newPaginationModel.page,
                            newPaginationModel.pageSize,
                            filter
                        );
                    }}
                    pageSizeOptions={[10, 20, 50, 100]}
                    rowCount={totalCount}
                    onRowClick={handleRowClick}
                    disableRowSelectionOnClick={true}
                    loading={isLoading}
                />
            </div>
        </div>
        </div>
    );
};

export default InventoryListing;